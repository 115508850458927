function formData(state = {}, action) {
  switch (action.type) {
    case "UPDATE_DATA": {
      return { ...state, ...action.payload };
    }
    case "SELECT_PROPPERTY": {
      return { ...state, propertySelected: action.payload };
    }

    case "SET_OPEN_ADDRESS": {
      return { ...state, isOpenAddress: action.payload };
    }

    default:
      return state;
  }
}

export default formData;
