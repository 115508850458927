import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import FileViewer from "react-file-viewer";
import { FilterDramaSharp, Loop } from "@material-ui/icons";
import { getDataUrlFromFile } from "browser-image-compression";
import { ModalFilesWrapper } from "./style";
import { Formik, Form } from "formik";
import TextInput from "../sharedComponents/TextInput/textInput";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import { Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "auto",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "auto",
    height: "90vh",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
    borderRadius: "20px",
  },
}));

const ModalFilesName = (props) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(props.open);
  const [openSnack, setOpenSnack] = useState(false);
  const [filesToShow, setFilesToShow] = useState([]);
  const [modalStyle] = React.useState(getModalStyle);
  const [numberOfFiles, setNumberOfFiles] = useState(0);
  const [namedFiles, setNamedFiles] = useState([]);
  const [snackMessage, setSnackMessage] = useState("");
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  useEffect(() => {
    const getUrlFile = async () => {
      if (props.files.length > 0) {
        let filesShow = [];
        let namedFile = [];
        for (let index = 0; index < props.files.length; index++) {
          const urlFile = await processFile(props.files[index]);
          filesShow.push({
            name: props.files[index].name,
            urlFile: urlFile,
            type: props.files[index].type.split("/")[1],
          });
          namedFile.push({ name: props.files[index].name, newName: "None" });
        }

        setFilesToShow(filesShow);
        setNumberOfFiles(filesShow.length);
        setNamedFiles(namedFile);
        setOpenModal(true);
      }
    };
    getUrlFile();
  }, [props.files]);

  const handleCloseModal = () => {
    setOpenModal(false);
    props.handleCloseModal(false);
  };
  const saveData = (values) => {
    let namedFile = namedFiles;
    const pos = Number(Object.keys(values)[0].split("-")[1]);
    namedFile.splice(Number(Object.keys(values)[0].split("-")[1]), 1, {
      name: namedFile[pos].name,
      newName: values[`field-${pos}`],
    });
    setNamedFiles(namedFile);
    setSnackMessage(
      `el archivo en la posicion ${pos + 1} fue cambiado a ${
        values[`field-${pos}`]
      }`
    );
    setOpenSnack(true);
  };
  const saveDataFinal = () => {
    const dataDone = namedFiles.filter((fileName) => {
      if (fileName.newName === "None") {
        return fileName;
      }
    });
    if (typeof dataDone === "undefined" || dataDone.length === 0) {
      props.handleNameDone(namedFiles);
      handleCloseModal();
    } else {
      setSnackMessage(`cambiar el nombre de cada archivo es obligatorio`);
      setOpenSnack(true);
    }
  };

  async function processFile(file) {
    try {
      let contentBuffer = await readFileAsync(file);
      return contentBuffer;
    } catch (err) {
      console.log(err);
    }
  }

  async function readFileAsync(file) {
    return await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <p id="simple-modal-description">
        Por favor dale un nombre a cada archivo
      </p>
      <ModalFilesWrapper>
        {filesToShow.length >= 1 &&
          filesToShow.map((file, index) => (
            <>
              <div className="file-name-container">
                <img src={file.urlFile} className="photo" />
                <Formik
                  enableReinitialize
                  initialValues={{
                    ["field-" + index]: file.name,
                  }}
                  onSubmit={(values) => saveData(values)}
                >
                  {(propsForm) => (
                    <>
                      <Form id="formFilter">
                        <div className="div-container">
                          <TextInput
                            data-id="txt-modal-field-name"
                            name={"field-" + index}
                            errors={propsForm.errors}
                            touched={propsForm.touched}
                            id={"field-" + index}
                            placeholder="Nombre del archivo"
                            type="text"
                            className="input-filter"
                            values={propsForm.values}
                          />
                          <div className="buttons">
                            <IconButton
                              data-id="btn-modal-field-name-save"
                              aria-label="check"
                              onClick={() => saveData(propsForm.values)}
                            >
                              <CheckIcon color={"primary"} />
                            </IconButton>
                            <IconButton
                              data-id="btn-modal-field-name-edit"
                              aria-label="edit"
                            >
                              <EditIcon color={"primary"} />
                            </IconButton>
                          </div>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </>
          ))}
        <Button
          data-id="btn-modal-field-name-save-names"
          variant="contained"
          color="primary"
          onClick={() => saveDataFinal(true)}
        >
          Guardar Nombres
        </Button>
      </ModalFilesWrapper>
    </div>
  );

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableScrollLock={true}
      >
        {body}
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton
            data-id="btn-snackbar-modal-close"
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};

export default ModalFilesName;
