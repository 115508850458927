import { store } from "react-notifications-component";

const errorConfig = {
  insert: "top",
  container: "top-right",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
};

const dismissConfig = {
  duration: 4000,
  onScreen: true,
  pauseOnHover: true,
};

export const errorTypes = {
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  NO_IMAGE_ERROR: "NO_IMAGE_ERROR",
  INVALID_CLIENT_STATUS_ERROR: "INVALID_CLIENT_STATUS_ERROR",
  NO_CONJUNTO_ID_ERROR: "NO_CONJUNTO_ID_ERROR",
};

// const unexpectedError = {
const unexpectedError = {
  ...errorConfig,
  dismiss: dismissConfig,
  title: "Error inesperado!",
  message: "Por favor contactar a soporte.",
  type: "danger",
};

// const notificationImg = {
const noImageError = {
  ...errorConfig,
  dismiss: dismissConfig,
  title: "No hay imagen!",
  message: " Debe adjuntar al menos una imagen.",
  type: "danger",
};

// const notificationEstado = {
const invalidClientStatusError = {
  ...errorConfig,
  dismiss: dismissConfig,
  title: "Estado de cliente no válido",
  message: "Debe terminar de llenar los campos requeridos",
  type: "danger",
};

// const notificationConjunto = {
const noConjuntoIdError = {
  ...errorConfig,
  dismiss: dismissConfig,
  title: "Sin idConjunto",
  message: "Este inmueble no posee idconjunto y quedará en revisar dirección.",
  type: "info",
};

const errorMessages = {
  [errorTypes.UNEXPECTED_ERROR]: unexpectedError,
  [errorTypes.NO_IMAGE_ERROR]: noImageError,
  [errorTypes.INVALID_CLIENT_STATUS_ERROR]: invalidClientStatusError,
  [errorTypes.NO_CONJUNTO_ID_ERROR]: noConjuntoIdError,
};

export const displayError = (errorType, container = "top-left") =>
  store.addNotification({ ...errorMessages[errorType], container });

export const displayAddressError = (address, telephone) =>
  store.addNotification({
    ...errorConfig,
    dismiss: { ...dismissConfig, duration: 6000 },
    title: "Error enviando el inmueble:",
    message: `Dirección: ${address} || Teléfono: ${telephone}`,
    type: "danger",
  });
