import styled from "styled-components";
import {
  archiaBoldFamily,
  archiaRegular,
  bogotaBlue100,
  bogotaBlue200,
  muliBoldFamily,
  nightBlue500,
  scarpaGray100,
  scarpaGray500,
} from "../../../StylesConstants";
import TableHead from "@material-ui/core/TableHead";
import { withStyles, createTheme } from '@material-ui/core/styles';

export const TableWrapper = styled.div`
  .table-title {
    font-family: ${muliBoldFamily};
    font-size: 20px;
    line-height: 28px;
    color: ${nightBlue500};
    margin-bottom: 16px;
  }
`;

export const StyledTable = createTheme({
  overrides: {
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: scarpaGray100,
        fontFamily: archiaBoldFamily,
        fontSize: "13px",
        color: scarpaGray500,
      },
      body: {
        fontFamily: archiaRegular,
        fontSize: "13px",
        color: scarpaGray500,
        padding: "0 1rem",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: `1px solid ${scarpaGray100}`,
      },
    },
    MuiTableRow: {
      hover: {
        "&:hover": {
          backgroundColor: `${bogotaBlue100} !important`,
        },
      },
    },
  },
});

export const StyledTableHead = withStyles({
  root: {
    backgroundColor: bogotaBlue200,
  },
})(TableHead);
