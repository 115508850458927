import React, { useState } from "react";
import { InputWrapper } from "./style";
import { ErrorMessage } from "formik";
import NumberFormat from "react-number-format";

const CurrencyInput = ({
  name,
  errors,
  id,
  label,
  className,
  touched,
  ...props
}) => {
  const [state, setState] = useState({ focus: false, label: false });
  const [valueCurrency, setValueCurrency] = useState(
    typeof props.values !== "undefined" &&
      props.values !== null &&
      Object.keys(props.values).length !== 0
      ? id === "precio_bajo"
        ? props.values.precio_bajo
        : id === "precio_alto"
        ? props.values.precio_alto
        : id === "ask_price_top"
        ? props.values.ask_price_top
        : ""
      : ""
  );

  return (
    <InputWrapper>
      <div className={`form-group ${className}`}>
        <label
          htmlFor={name}
          className={`form-control  ${
            errors[name] && touched[name] ? " is-invalid" : ""
          } ${props.disabled ? " disabled" : ""} ${
            state.label ? "" : " no-label"
          }`}
        >
          {state.label ? label : ""} {props.required && state.label ? "*" : ""}
          <div className="input-field">
            <NumberFormat
              id={`currency-${id}`}
              name={name}
              className="input-number-format"
              thousandSeparator={true}
              type={props.type ? props.type : "text"}
              prefix={"$"}
              maxLength={13}
              placeholder="$000,000.000"
              value={valueCurrency}
              onValueChange={(vals) => setValueCurrency(vals.floatValue)}
              {...props}
            />
          </div>
        </label>
        <ErrorMessage
          name={name}
          component="div"
          className="invalid-feedback"
        />
      </div>
    </InputWrapper>
  );
};

export default CurrencyInput;
