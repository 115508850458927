import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { ModalZonesWrapper } from "./style";
import IconButton from "@material-ui/core/IconButton";
import { Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SelectedTable from "./SelectedTable/selectedTable";
import CreateZone from "./createZone";
import { fetchDataAxios } from "../../utils/fetchRequest";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "auto",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "90vw",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
    borderRadius: "20px",
  },
}));

const apikey = process.env.REACT_APP_FORMS_API_KEY;
const rootUrl =
  process.env.REACT_APP_FORM_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH;
const endpointGetUrl =
  process.env.REACT_APP_ENDPOINT_FORM_API_GET_VALIDAR_ZONA_MEDIANA;
const endpointPostUrl =
  process.env.REACT_APP_ENDPOINT_FORM_API_POST_VALIDAR_ZONA_MEDIANA;
const urlGet = rootUrl + endpointGetUrl;
const urlPost = rootUrl + endpointPostUrl;

const ModalZones = (props) => {
  const classes = useStyles();

  const [openSnack, setOpenSnack] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [snackMessage, setSnackMessage] = useState("");
  const [dataZones, setDataZones] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rowEdit, setRowEdit] = useState({});

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  useEffect(() => {
    const getDataZonas = async () => {
      const responseZonas = fetchDataAxios(urlGet, {
        method: "GET",
        headers: {
          "x-api-key": apikey,
          "Content-Type": "application/json",
        },
      });
      const dataResponse = await responseZonas;
      let dataToShow = [];
      const dataZones = dataResponse.data;
      for (const key in dataZones) {
        if (dataZones.hasOwnProperty(key)) {
          const element = dataZones[key];
          const validarColumna = JSON.parse(element.validar_columna);
          const dataElement = {
            id: element.zona_mediana_id,
            rangoMax: validarColumna.precio_alto,
            rangoMin: validarColumna.precio_bajo,
            antiguedad: validarColumna.ano_antiguedad,
            ask_price_top: validarColumna.ask_price_top,
            activo: element.activo,
            estado: element.validar_zona_mediana_estado_id,
          };
          dataToShow.push(dataElement);
        }
      }
      setDataZones(dataToShow);
    };

    getDataZonas();
  }, []);

  const postDataZonas = async (data) => {
    const responseZonas = fetchDataAxios(urlGet, {
      method: "POST",
      headers: {
        "x-api-key": apikey,
        "Content-Type": "application/json",
      },
      data: data,
    });
    const responseDataZonas = await responseZonas;
    return responseDataZonas;
  };

  const handleIsVisible = () => {
    setIsVisible(!isVisible);
    setIsEdit(false);
  };
  const handleEdit = (event, row) => {
    setIsVisible(!isVisible);
    const rowToEdit = {
      id: row.id,
      precio_alto: row.rangoMax,
      precio_bajo: row.rangoMin,
      ask_price_top: row.ask_price_top,
      ano_antiguedad: row.antiguedad,
      activo: row.activo,
      validar_zona_mediana_estado_id: row.estado,
    };
    setRowEdit(rowToEdit);
    setIsEdit(true);
  };

  async function processFile(file) {
    try {
      let contentBuffer = await readFileAsync(file);
      return contentBuffer;
    } catch (err) {
      console.log(err);
    }
  }

  async function readFileAsync(file) {
    return await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ModalZonesWrapper>
        <CreateZone
          isVisible={isVisible}
          handleIsVisible={handleIsVisible}
          rowEdit={rowEdit}
          isEdit={isEdit}
        />
        <SelectedTable rows={dataZones} handleEdit={handleEdit} />
      </ModalZonesWrapper>
    </div>
  );

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableScrollLock={true}
      >
        {body}
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton
            data-id="btn-close-modal-zone"
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};

export default ModalZones;
