const resetSelect = (state = [], { type, payload }) => {
  switch (type) {
    case 'RESET_SELECT': {
      return [...state, ...payload];
    }
    case 'ENABLE_SELECTS': {
      return [];
    }
    default:
      return state;
  }
};

export default resetSelect;
