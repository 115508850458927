import React, { useRef, useEffect, useState, Fragment } from "react";
import {
  FiltersWrapper,
  FiltersContainer,
  SectionFilters,
  ActionFilters,
} from "./styleFilters";
import TextInput from "../sharedComponents/TextInput/textInput";
import SelectComponent from "../sharedComponents/Select/select";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import { Formik, Form, Field } from "formik";
import Button from "../sharedComponents/Button/button";
import { ROLE } from "../const/constants";
import {
  options_status_admin,
  options_status_callcenter,
  options_status_coordinador,
} from "./constants";
import axios from "axios";

const Filters = (props) => {
  const [role, setRole] = useState([]);
  const [filterCiudad, setFilterCiudad] = useState([]);

  const formRef = useRef(null);

  useEffect(() => {
    setRole(props.role);
  }, [props.role]);

  const getCities = async () => {
    const urlCities = process.env.REACT_APP_ENDPOINT_FORM_API_GET_CITIES;
    const apikey = process.env.REACT_APP_FORMS_API_KEY_V2;

    await axios({
      url: urlCities,
      method: "get",
      headers: {
        "x-api-key": apikey,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let cities = response.data.data.city.data;
      let dataCiudad = [];
      cities.map((city) => {
        dataCiudad.push({ label: city.label, value: city.name });
      });
      setFilterCiudad(dataCiudad);
    });
  };
  useEffect(() => {
    getCities();
  }, []);

  const getFiltersValue = (
    addressParam,
    phoneParam,
    dateParam,
    statusParam,
    sourceParam,
    nidParam,
    cityParam
  ) => {
    const selectBody = {
      address: addressParam ?? "",
      phone: phoneParam ?? "",
      date: dateParam,
      status: statusParam,
      source_id: sourceParam,
      user: "",
      nid: nidParam,
      city: cityParam ?? "",
    };

    return selectBody
  };

  const handleSearch = (values) => {
    const form = values;
    const fieldsVal = getFiltersValue(
      form.address,
      form.phone,
      form.date && form.date !== ""
        ? moment(new Date(form.date)).format("YYYY-MM-DD")
        : "",
      form.status,
      form.source_id,
      form.nid,
      form.ciudad
    );
    props.handleSearch(fieldsVal);
  };

  const handleClean = (resetForm) => {
    formRef.current.reset();
    resetForm({});
    const fieldsVal = getFiltersValue("", "", "", "", "", "");
    props.handleSearch(fieldsVal);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ciudad: "",
        address: "",
        phone: "",
        date: "",
        source_id: "",
        status: "",
        nid: "",
      }}
      onSubmit={(values) => handleSearch(values)}
    >
      {(props) => (
        <FiltersContainer>
          <Form ref={formRef} id="formFilter">
            <FiltersWrapper
              className={`${role === ROLE.ROTACION ? "role-rotation" : ""}`}
            >
              <SectionFilters>
                <div
                  className={`${
                    role === ROLE.ROTACION ? "input-filter-right" : ""
                  }`}
                >
                  <TextInput
                    name="nid"
                    errors={props.errors}
                    touched={props.touched}
                    id="txt-nid"
                    label="ID"
                    className={`${
                      role === ROLE.ROTACION ? "" : "input-filter"
                    }`}
                  />
                </div>
                <div className="form-group">
                  <Field
                    default={{ value: "", label: "" }}
                    options={filterCiudad}
                    component={SelectComponent}
                    setFieldValue={props.setFieldValue}
                    setFieldTouched={props.setFieldTouched}
                    name="ciudad"
                    id="txt-ciudad"
                    handleChange={props.setFieldValue}
                    label="Ciudad"
                  />
                </div>
                {role !== ROLE.ROTACION && (
                  <Fragment>
                    <TextInput
                      name="address"
                      errors={props.errors}
                      touched={props.touched}
                      id="text-address"
                      label="Dirección"
                      className="input-filter"
                    />
                    <TextInput
                      name="phone"
                      errors={props.errors}
                      touched={props.touched}
                      id="txt-phone"
                      label="Teléfono"
                      className="input-filter"
                    />
                    <div className="form-group">
                      <Field
                        default={{ value: "", label: "" }}
                        options={
                          role === ROLE.ADMIN
                            ? options_status_admin
                            : role === ROLE.COORDINADOR
                            ? options_status_coordinador
                            : options_status_callcenter
                        }
                        component={SelectComponent}
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        name="status"
                        id="txt-status"
                        handleChange={props.setFieldValue}
                        label="Estado"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        default={{ value: "", label: "" }}
                        options={
                          role === ROLE.COORDINADOR
                            ? [
                                { label: "Ventana", value: "1" },
                                { label: "Web", value: "3" },
                                { label: "Web scraping", value: "4" },
                                { label: "Estudio del inmueble", value: "7" },
                                { label: "CRM", value: "20" },
                                { label: "Ventana - scraping", value: "28" },
                                { label: "Lead Forms", value: "47" },
                              ]
                            : [
                                { label: "Ventana", value: "1" },
                                { label: "Web", value: "3" },
                                { label: "Web Scraping", value: "4" },
                                { label: "Ventana - scraping", value: "28" },
                                { label: "Lead Forms", value: "47" },
                              ]
                        }
                        component={SelectComponent}
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        name="source_id"
                        id="input-source_id"
                        handleChange={props.setFieldValue}
                        label="Fuente"
                      />
                    </div>
                  </Fragment>
                )}
              </SectionFilters>
              <ActionFilters>
                <div className="btn-container">
                  <Button
                    id="btn-Buscar"
                    typeClass="primary"
                    type="submit"
                    className="d-inline-block"
                    label="Buscar"
                  >
                    <SearchIcon />
                  </Button>
                  <Button
                    id="btn-Limpiar"
                    typeClass="secondary"
                    type="reset"
                    className="d-inline-block ml-2"
                    onClick={() => handleClean(props.resetForm)}
                    label="Limpiar"
                  ></Button>
                </div>
              </ActionFilters>
            </FiltersWrapper>
          </Form>
        </FiltersContainer>
      )}
    </Formik>
  );
};

export default Filters;
