import React, { useState, useEffect } from "react";
import FormsContainer from "./components/Forms/forms-container";
import { connect } from "react-redux";
import { Layout } from "./style";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ListApartmentsContainer from "./components/ListApartments/listApartmentsContainer";
import LogIn from "./components/LogIn/login";
import AuthenticatedRoute from "./components/Auth/authenticatedRoute";

const App = (props) => {
  const { authenticated } = props;

  return (
    <div>
      <Router>
        <Layout>
          <Route exact path="/">
            <LogIn />
          </Route>

          <AuthenticatedRoute
            path="/dashboard"
            component={ListApartmentsContainer}
            appProps={{ isAuthenticated: authenticated }}
          />
          <AuthenticatedRoute
            path="/forms"
            component={FormsContainer}
            appProps={{ isAuthenticated: authenticated }}
          />
          <AuthenticatedRoute
            path="/forms-coordinador-lote"
            component={FormsContainer}
            appProps={{ isAuthenticated: authenticated }}
          />
        </Layout>
      </Router>
    </div>
  );
};
const mapStateToProps = (state) => ({
  authenticated: state.formData.authenticated,
  role: state.formData.role,
});

export default connect(mapStateToProps)(App);
