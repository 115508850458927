import styled from "styled-components";
import { purple } from "../../StylesConstants";

export const ModalFilesWrapper = styled.section`
  display: grid;
  .file-name-container {
    display: grid;
    grid-template: 1fr / 80px 500px;
    align-items: center;
    .div-container {
      display: grid;
      grid-template: 1fr/ 400px 100px;
    }
  }
  .photo {
    width: 50px;
    height: 96px;
    margin-bottom: 3px;
    border-radius: 10px;
  }
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    outline: none;
  }
  .MuiIcon-colorPrimary {
    color: ${purple};
  }
  .MuiSvgIcon-colorPrimary {
    color: ${purple};
  }
`;
