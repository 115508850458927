import styled from 'styled-components';

const ButtonWrapper = styled.div`
  .btn-group {
    background: #f9fafc;
    border-radius: 16px;
    height: 48px;
    width: 100%;
    text-align: left;
    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #2d2d2d;
    vertical-align: middle;
    align-items: center;

    &.collapsed {
      .btn-container {
        .icon-arrow {
          width: 0.6em;
          height: 0.6em;
          margin-left: 8px;
          border-right: 0.2em solid black;
          border-top: 0.2em solid black;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(135deg);
        }
      }
    }

    .btn-container {
      display: grid;
      grid-template-columns: 30px 1fr 30px;
      grid-template-rows: 1fr;
      width: 100%;
      align-items: center;

      .icon-circle {
        color: #ffb200;
      }

      .panel-heading {
        position: relative;
      }

      .icon-arrow {
        width: 0.6em;
        height: 0.6em;
        margin-left: 8px;
        border-right: 0.2em solid black;
        border-top: 0.2em solid black;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(315deg);
      }

      &:focus {
        outline: none;
      }
    }
  }
`;

export default ButtonWrapper;
