export const options_status_admin = [
  { label: "Ok conjunto", value: "ok_conjunto" },
  { label: "Incompleto", value: "incompleto" },
  { label: "No contesta", value: "no_contesta" },
  { label: "Posible Duplicado", value: "posible_duplicado" },
  { label: "Duplicado", value: "duplicado" },
  { label: "Revisar dirección", value: "revisar_direccion" },
  { label: "No Gestionado", value: "no_gestionado" },
  { label: "No interesado", value: "no_interesado" },
  { label: "No contesta", value: "no_contesta_sales" },
  { label: "Volver a llamar", value: "volver_a_llamar" },
  { label: "Interesado", value: "interesado" },
  { label: "No contactable", value: "no_contactable" },
  { label: "Vendido", value: "vendido_sales" },
  { label: "Cita agendada", value: "cita_agendada" },
  { label: "Visita efectuada", value: "visita_efectuada" },
  { label: "En negociación", value: "en_negociacion" },
  { label: "Revisión comité", value: "revision_comite" },
  { label: "Oferta rechazada", value: "oferta_rechazada" },
  { label: "Acuerdo Firma", value: "acuerdo_firma" },
  { label: "Cierre", value: "cierre" },
];

export const options_status_callcenter = [
  { label: "Ok conjunto", value: "ok_conjunto" },
  { label: "Incompleto", value: "incompleto" },
  { label: "No contesta", value: "no_contesta" },
  { label: "Posible Duplicado", value: "posible_duplicado" },
  { label: "Incompleto desde Web", value: "web_incompleto" },
  { label: "Duplicado", value: "duplicado" },
  { label: "Revisar dirección", value: "revisar_direccion" },
  { label: "Contacto MKT", value: "contacto_mkt"},
];

export const options_status_coordinador = [
  { label: "Todos", value: ""},
  { label: "Sin apartamentos", value: "sin_aptos"},
  { label: "Pendiente por validar dirección", value: "pendiente_validar_direccion"},
  { label: "No contesta Coordinador", value: "no_contesta_coordinador"},
  { label: "Contacto MKT", value: "contacto_mkt"},
];
