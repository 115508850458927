import styled from "styled-components";
import { size } from "../../../StylesConstants";

export const StatusLabelWrapper = styled.section`
  .status {
    display: grid;
    grid-template: 1fr / 25px 130px;
    background-color: rgba(${props => props.back});
    color: ${props => props.color};
    width: 80%;
    border-radius: 50px;
    font-size: 9px;
    label {
      align-self: center;
      justify-self: start;
      margin-left: 10%;
      margin-top: 5%;
    }
  }
  .circle {
    align-self: center;
    justify-self: end;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: ${props => props.color};
  }

  @media screen and (min-width: ${size.tablet}) {
    .status {
      display: grid;
      grid-template: 1fr / 25px 130px;
      background-color: rgba(${props => props.back});
      color: ${props => props.color};
      width: 80%;
      border-radius: 50px;
      font-size: 12px;
      label {
        align-self: center;
        justify-self: start;
        margin-left: 10%;
        margin-top: 5%;
      }
    }
    .circle {
      align-self: center;
      justify-self: end;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background-color: ${props => props.color};
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    .status {
      display: grid;
      grid-template: 1fr / 25px 130px;
      background-color: rgba(${props => props.back});
      color: ${props => props.color};
      width: 80%;
      border-radius: 50px;
      font-size: 12px;
      label {
        align-self: center;
        justify-self: start;
        margin-left: 10%;
        margin-top: 5%;
      }
    }
    .circle {
      align-self: center;
      justify-self: end;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background-color: ${props => props.color};
    }
  }
`;
