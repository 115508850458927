import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { ErrorMessage } from "formik";
import { SelectWrapper } from "./style";
import { isMobile } from "react-device-detect";

import { enableSelects } from "../../../redux/actions/resetSelect";
import {
  error,
  white,
  purple,
  grey,
  light_purple,
  black,
} from "../../../StylesConstants";

const SelectComponent = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  form,
  label,
  className,
  setFieldValue,
  setFieldTouched,
  resetSelect,
  ...props
}) => {
  const [selected, setSelected] = useState(props.default);
  const getOption = (value) => {
    let option = { label: "", value: "" };

    for (let index = 0; index < props.options.length; index++) {
      const element = props.options[index];
      if (element.value === value) {
        option = { label: element.label, value: element.value };
        break;
      }
    }
    return option;
  };

  const selectStyles = {
    control: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor:
        errors[name] && touched[name] && selected.value === ""
          ? "rgba(255, 0, 0, 0.1)"
          : isDisabled
          ? "rgba(170, 167, 252, 0.2)"
          : { white },
      boxSizing: "border-box",
      borderRadius: "100px",
      padding: "5px 15px 5px 20px",
      height: "56px",
      border: styles.isFocused
        ? 0
        : isDisabled
        ? `2px solid  ${light_purple}`
        : errors[name] && touched[name] && selected.value === ""
        ? `2px solid  ${error}`
        : `2px solid  ${grey}`,
      // This line disable the blue border
      boxShadow: styles.isFocused ? 0 : 0,
      "&:hover": {
        border: `2px solid ${
          errors[name] && touched[name] && selected.value === "" ? error : grey
        }`,
      },
    }),
    singleValue: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled
        ? light_purple
        : errors[name] && touched[name] && selected.value === ""
        ? error
        : black,
    }),
    menu: (styles) => ({
      ...styles,
      border: `2px solid  ${grey}`,
      boxSizing: "border-box",
      borderRadius: "32px",
      padding: "15px 32px",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: "16px",
        lineHeight: "19px",
        border: isFocused ? `2px solid  ${purple}` : "none",
        borderRadius: "100px",
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? null
          : isFocused
          ? purple
          : null,
        color: isDisabled
          ? grey
          : isSelected
          ? purple
          : isFocused
          ? white
          : null,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? purple : ""),
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
  };

  return (
    <SelectWrapper>
      {!isMobile && (
        <div className="react-select-container">
          {label && (
            <label htmlFor={name} className="input-label">
              {label} {props.required ? "*" : ""}
            </label>
          )}
          <Select
            id={`input-${name}`}
            defaultValue={props.default}
            options={props.options}
            styles={selectStyles}
            value={resetSelect.includes(name) ? props.default : selected}
            isDisabled={props.disabled}
            onChange={(changeEvent) => {
              if (resetSelect.length > 0) props.enableSelects();
              form.setFieldValue(name, changeEvent.value);
              form.setFieldTouched(name, true);
              setSelected({
                label: changeEvent.label,
                value: changeEvent.value,
              });
              if (props.handleChange) {
                props.handleChange(changeEvent.value);
              }
            }}
            onBlur={onBlur}
          />
          <select
            className="select-input-hidden"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            style={{ display: "block" }}
            id={`${name}-input-hidden`}
          >
            <option value="">Seleccione</option>
            {props.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors[name] && touched[name] && selected.value === "" && (
            <div className="input-feedback" data-id="input-feedback-error">
              {errors[name]}
            </div>
          )}
          <ErrorMessage
            name="error-select-helper"
            component="div"
            className="invalid-feedback"
          />
        </div>
      )}
      {isMobile && (
        <div className="react-select-container">
          {label && (
            <label htmlFor={name} className="input-label">
              {label} {props.required ? "*" : ""}
            </label>
          )}
          <select
            className={`select-mobile ${
              errors[name] && touched[name] ? "error-select" : ""
            }`}
            name={name}
            value={value}
            disabled={props.disabled}
            onChange={(changeEvent) => {
              form.setFieldValue(name, changeEvent.target.value);
              form.setFieldTouched(name, true);
              setSelected({
                label: changeEvent.label,
                value: changeEvent.target.value,
              });
              if (props.handleChange) {
                props.handleChange(changeEvent.value);
              }
              if (props.handleChange) {
                props.handleChange(changeEvent.target.value);
              }
            }}
            onBlur={onBlur}
            style={{ display: "block" }}
            data-id={`${name}-select-mobile`}
          >
            <option value="">
              {props.default.value === "" ? props.default.label : "Seleccione"}
            </option>
            {props.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors[name] && touched[name] && selected.value === "" && (
            <div className="input-feedback" data-id="input-feedback-mobile">
              {errors[name]}
            </div>
          )}
          <ErrorMessage
            name="error-select-helper"
            component="div"
            className="invalid-feedback"
          />
        </div>
      )}
    </SelectWrapper>
  );
};

const mapStateToProps = (state) => ({
  resetSelect: state.resetSelect,
});

const mapDispatchToProps = (dispatch) => ({
  enableSelects: () => dispatch(enableSelects()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectComponent);
