export function removeEmptyFields(object, fieldsToRemove) {
  for (const field of fieldsToRemove) {
    const fieldIsStringEmpty =
      typeof object[field] === "string" && object[field].trim() === "";

    if (object.hasOwnProperty(field) && fieldIsStringEmpty) {
      delete object[field];
    }
  }

  return object;
}
