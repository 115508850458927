import styled from "styled-components";
import {
  size,
  primaryGreen,
  nightBlue200,
  light_grey,
  plainlightGray,
  archiaBoldFamily,
} from "../../../StylesConstants";
import Tabs from "@material-ui/core/Tabs";
import { Field } from "formik";

export const AddressWrapper = styled.div`
  .text-input-address {
    display: grid;
    grid-template-columns: 1fr 8px 1fr 8px 1fr;
    grid-gap: 5px;
  }

  .line-address {
    align-self: center;
  }

  .input-label {
    margin: 0 15px;
  }

  .btn-address {
    margin-top: 16px;
  }

  @media screen and (min-width: ${size.tablet}) {
    .text-input-address {
      grid-gap: 12px;
    }

    .input-label {
      margin: 0 15px;
    }
  }
`;

export const AntTabs = styled(Tabs)`
  color: #a7a7b1;
  width: 574px;
  background-color: #f2f2f3;
  border-radius: 50px;
  height: 64px;
  margin: 8px auto;
  padding: 8px;

  @media screen and (max-width: 414px) {
    height: auto;
    border-radius: 16px;
    width: auto;
  }

  & .MuiTabs-indicator {
    background-color: transparent;
  }

  & .MuiTab-wrapper {
    display: flex;
    flex-direction: initial;
  }
  .tabs {
    min-height: 48px;
    border-radius: 35px;
    height: 30px;
    width: 300px;
    border: 2px solid transparent;
    transition: 500ms;
    margin-bottom: 16px;

    &:first-child {
      margin-right: 20px;
    }

    @media screen and (max-width: 414px) {
      &:first-child {
        margin-right: 0;
      }
    }

    & svg {
      margin-right: 12px;
    }
    &.active {
      font-family: ${archiaBoldFamily};
      color: ${primaryGreen};
      border: 2px solid ${primaryGreen};
      box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
        0px 4px 8px rgba(48, 49, 51, 0.1);
    }
  }

  .hwLFXG .MuiTabs-indicator {
    display: none;
  }
  .MuiTabs-flexContainer {
    justify-content: center;
  }
  @media screen and (max-width: 414px) {
    .MuiTabs-flexContainer {
      flex-direction: column;
      align-items: center;
    }
  }
`;
