import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

const Viewer = (props) => (
  <ModalGateway>
    {props.viewerIsOpen ? (
      <Modal onClose={props.closeLightbox}>
        <Carousel
          currentIndex={props.currentImage}
          views={props.imgs.map((x) => ({
            ...x,
            srcset: x.srcSet,
            caption: x.title,
          }))}
        />
      </Modal>
    ) : null}
  </ModalGateway>
);

export default Viewer;
