import React, { useEffect, useState } from "react";
// Enhacer
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// tools Enhacer
import { setOpenAddress } from "../../../redux/actions/infoProperties";
// utils
import { coddir, getTipoVia } from "../../../utils/addressInput";
import { ROLE } from "../../const/constants";
// Components
import Button from "../Button/button";
import TextInput from "../TextInput/textInput";
import SelectComponent from "../Select/select";
import FormikEffect from "../FormikEffect/formikEffect";
import Tab from "@material-ui/core/Tab";
import ExploreSharpIcon from "@material-ui/icons/ExploreSharp";
import LocationOnSharpIcon from "@material-ui/icons/LocationOnSharp";
// Styles
import { AddressWrapper, AntTabs } from "./style";

const AddressInput = ({ maxLengthInput, ...propsAddress }) => {
  const tipoVia = [
    {
      label: "Calle",
      value: "Calle",
    },
    {
      label: "Carrera",
      value: "Carrera",
    },
    {
      label: "Avenida",
      value: "Avenida",
    },
    {
      label: "Diagonal",
      value: "Diagonal",
    },
    {
      label: "Transversal",
      value: "Transversal",
    },
    {
      label: "Circular",
      value: "Circular",
    },
  ];
  const { role } = useSelector((state) => state.formData);
  const { isOpenAddress } = useSelector((state) => state.infoProperties);
  const dispatch = useDispatch();

  const [showData, setShowData] = useState(false);
  // toggle between open addres and coddir addres
  const [optionOpenAddress, setOptionOpenAddress] = useState(0);
  const [address, setAddress] = useState({
    openAddress: "",
    numero1: "",
    numero2: "",
    numero3: "",
  });

  useEffect(() => {
    if (propsAddress?.field?.default?.length > 0) {
      if (isOpenAddress)
        setAddress({ openAddress: propsAddress.field.default });
      else {
        let formatedAddres = coddir(propsAddress.field.default)?.split("+");
        if (formatedAddres?.length >= 4) {
          setAddress({
            tipoVia: getTipoVia(formatedAddres[0]),
            numero1: formatedAddres[1],
            numero2: formatedAddres[2],
            numero3: formatedAddres[3],
          });
        } else {
          setAddress({
            tipoVia: "Seleccione",
          });
        }
      }
    } else {
      setAddress({
        tipoVia: "Seleccione",
      });
    }
  }, [isOpenAddress]);

  const handleSearch = (values) => {
    const ciudad = propsAddress.fields.ciudad;
    const addressInput = isOpenAddress
      ? `${values.openAddress}`
      : `${values.tipoVia} ${values.numero1} # ${values.numero2} - ${values.numero3}`;
    propsAddress.setFieldValue(propsAddress.name, addressInput);
    propsAddress.setFieldValue("open_address_input", isOpenAddress ? 1 : 0);
    if (propsAddress.getApiConjuntosData) {
      propsAddress.getApiConjuntosData(
        addressInput,
        propsAddress.setFieldValue,
        ciudad
      );
    }
  };

  useEffect(() => {
    let newAddress = isOpenAddress
      ? address.openAddress
      : `${address.tipoVia} ${address.numero1} # ${address.numero2} - ${address.numero3}`;
    propsAddress.setFieldValue(propsAddress.name, newAddress);
  }, [address]);

  const genericHandleUpdate = (keyname, values) => {
    setAddress({
      ...address,
      [keyname]: values,
    });
  };

  const handleUpdateTipoVia = (values) =>
    genericHandleUpdate("tipoVia", values);
  const handleUpdateNum1 = (values) => genericHandleUpdate("numero1", values);
  const handleUpdateNum2 = (values) => genericHandleUpdate("numero2", values);
  const handleUpdateNum3 = (values) => genericHandleUpdate("numero3", values);
  // set open address input
  const handleUpdateOpenAddress = (values) =>
    genericHandleUpdate("openAddress", values);

  useEffect(() => {
    setShowData(propsAddress.apiConjuntoData.showData);
  }, [propsAddress.apiConjuntoData]);

  const validateChange = (validateForm) => {
    validateForm().then((errors) => {
      if (propsAddress.validAddresss) {
        if (Object.keys(errors).length > 0) {
          propsAddress.validAddresss(false);
        } else {
          propsAddress.validAddresss(true);
        }
      }
    });
  };

  const handleChangeMenu = (event, newValue) => {
    setOptionOpenAddress(newValue);
    dispatch(setOpenAddress(Boolean(newValue)));
  };

  var addressRequired = false;
  if (propsAddress.estado === "completo") {
    addressRequired = true;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={address}
        validationSchema={
          isOpenAddress
            ? addressRequired ?
                Yup.object().shape({
                  openAddress: Yup.string()
                    .max(
                      maxLengthInput ?? 50,
                      `La dirección debe de tener un máximo de ${maxLengthInput ??
                        50}`
                    )
                    .required(addressRequired, "¡Campo requerido!"),
                })
                :
                Yup.object().shape({
                  openAddress: Yup.string()
                    .max(
                      maxLengthInput ?? 50,
                      `La dirección debe de tener un máximo de ${maxLengthInput ??
                        50}`
                    ),
                  })
            : addressRequired ?
                Yup.object().shape({
                  numero1: Yup.string()
                    .required("¡Campo requerido!")
                    .matches(
                      /(^\d{1,10})(([a-zA-Z0-9\s]+$)?)$/,
                      "Formato incorrecto"
                    ),
                  numero2: Yup.string()
                    .required("¡Campo requerido!")
                    .matches(
                      /(^\d{1,10})(([a-zA-Z0-9\s]+$)?)$/,
                      "Formato incorrecto"
                    ),
                  numero3: Yup.string()
                    .required("¡Campo requerido!")
                    .matches(
                      /(^\d{1,10})(([a-zA-Z0-9\s]+$)?)$/,
                      "Formato incorrecto"
                    ),
                  tipoVia: Yup.string().required("¡Campo requerido!"),
                })
            :
              Yup.object().shape({
                numero1: Yup.string()
                  .matches(
                    /(^\d{1,10})(([a-zA-Z0-9\s]+$)?)$/,
                    "Formato incorrecto"
                  ),
                numero2: Yup.string()
                  .matches(
                    /(^\d{1,10})(([a-zA-Z0-9\s]+$)?)$/,
                    "Formato incorrecto"
                  ),
                numero3: Yup.string()
                  .matches(
                    /(^\d{1,10})(([a-zA-Z0-9\s]+$)?)$/,
                    "Formato incorrecto"
                  ),
                tipoVia: Yup.string(),
              })
        }
        onSubmit={(values) => handleSearch(values)}
      >
        {(props) => (
          <>
            <FormikEffect
              onChange={() => validateChange(props.validateForm, props.values)}
            />
            <Form id="formFilter">
              <AddressWrapper>
                <label className="input-label">
                  {propsAddress.label} {props.required ? "*" : ""}
                </label>
                {(role === ROLE.COORDINADOR ||
                  role === ROLE.CALLCENTER ||
                  role === ROLE.ROTACION ||
                  role === ROLE.ADMIN ||
                  role === ROLE.VENTANERO) && (
                  <AntTabs
                    value={optionOpenAddress}
                    onChange={handleChangeMenu}
                    aria-label="icon label tabs"
                    data-id="address-input-tabs"
                  >
                    <Tab
                      value={0}
                      icon={<LocationOnSharpIcon />}
                      label="Dirección cerrada"
                      className={`tabs ${!isOpenAddress ? "active" : ""}`}
                      data-id="tab-regular-address"
                    />
                    <Tab
                      value={1}
                      icon={<ExploreSharpIcon />}
                      label="Dirección abierta"
                      className={`tabs ${isOpenAddress ? "active" : ""}`}
                      data-id="tab-open-address"
                    />
                  </AntTabs>
                )}
                {isOpenAddress ? (
                  <>
                    <TextInput
                      name="openAddress"
                      errors={props.errors}
                      touched={props.touched}
                      disabled={showData}
                      onKeyUp={handleUpdateOpenAddress}
                      type="text"
                      placeholder="Ej: kilometro 5 vía Jamundi - Cali vereda la aguita"
                      data-id="open-address-text"
                    />
                  </>
                ) : (
                  <>
                    <div className="form-group" key="available">
                      {props.initialValues.tipoVia && (
                        <Field
                          default={{
                            value: props.initialValues.tipoVia,
                            label: props.initialValues.tipoVia,
                          }}
                          options={tipoVia}
                          component={SelectComponent}
                          setFieldValue={props.setFieldValue}
                          setFieldTouched={props.setFieldTouched}
                          handleChange={handleUpdateTipoVia}
                          disabled={showData}
                          name="tipoVia"
                          data-id="regular-address-input-tipoVia"
                        />
                      )}
                    </div>
                    <div className="text-input-address">
                      <TextInput
                        name="numero1"
                        errors={props.errors}
                        touched={props.touched}
                        type="text"
                        disabled={showData}
                        onKeyUp={handleUpdateNum1}
                        placeholder=""
                        data-id="txt-regular-address-number-1"
                      />
                      <div className="line-address">
                        <span> # </span>
                      </div>
                      <TextInput
                        name="numero2"
                        errors={props.errors}
                        touched={props.touched}
                        disabled={showData}
                        onKeyUp={handleUpdateNum2}
                        type="text"
                        placeholder=""
                        data-id="txt-regular-address-number-2"
                      />
                      <div className="line-address">
                        <span> - </span>
                      </div>
                      <TextInput
                        name="numero3"
                        errors={props.errors}
                        touched={props.touched}
                        disabled={showData}
                        onKeyUp={handleUpdateNum3}
                        type="text"
                        placeholder=""
                        data-id="txt-regular-address-number-3"
                      />
                    </div>
                  </>
                )}

                {!propsAddress.hideButtons && (
                  <div className="btn-container pull-right">
                    {!showData && (
                      <Button
                        typeClass="primary"
                        type="submit"
                        className="d-inline-block btn-address"
                        label="Validar dirección"
                        id="validate-address"
                      />
                    )}
                    {showData && (
                      <Button
                        typeClass="primary"
                        type="button"
                        className="d-inline-block btn-address"
                        label="Editar dirección"
                        onClick={() => propsAddress.fixAddress()}
                        id="edit-address"
                      />
                    )}
                  </div>
                )}
              </AddressWrapper>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default AddressInput;
