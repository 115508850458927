import React, { useEffect, useState } from "react";
// Enhacer
import { connect } from "react-redux";
import { Snackbar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { store } from "react-notifications-component";
import { withRouter } from "react-router";
import axios from "axios";
import {
  setImagesAction,
  clearImagesAction,
} from "../../redux/actions/ventanerosImages.actions";
import {
  errorTypes,
  displayError,
  displayAddressError,
} from "./forms-container.utils";
import ReactNotification from "react-notifications-component";
import BlockUi from "react-block-ui";
// Enhacer tools
import { removeNotFoundAction } from "../../redux/actions/coordinadorLot.actions";
// utils
import { ROLE } from "../const/constants";
import { STATUS } from "../const/constantsStatus";
// components
import FormStreetVentanero from "./FormStreetVentanero/FormStreetVentanero";
import FormStreet from "./FormStreet";
import ModalResponse from "./modals";
import HeaderSmall from "../HeaderSmall/header";
// style
import "react-notifications-component/dist/theme.css";
import { FormsContainerWrapper } from "./style";
import { removeEmptyFields } from "../../utils/removeEmptyFields";

const FormsContainer = ({
  images,
  setImages,
  clearImages,
  notFound,
  removeNotFound,
  ...props
}) => {
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);

  const [isStatusCompletado, setIsStatusCompletado] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [numFormulario, setNumFormulario] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [stateOldId, setStateOldId] = useState(null);
  const [formCache, setFormCache] = useState({ reqFailed: false, body: {} });
  const [openSnack, setOpenSnack] = useState(false);
  const snackMessage = "El registro fue eliminado por ser un duplicado.";
  const [openModalMap, setOpenModalMap] = useState(false);

  const setImagesOnUpdate = (images) => {
    if (images) {
      const jsonImg = images;
      const arrayImg = [];
      const urlBucket = process.env.REACT_APP_IMG_BUCKET;

      jsonImg.map((img) => {
        var imageUrl = "";
        var protocol = img.url.substring(0, 5);
        if (protocol === "http:" || protocol === "https") {
          imageUrl = img.url;
        } else {
          imageUrl = `${urlBucket}${img.url}`;
        }

        arrayImg.push({
          img: imageUrl,
          type: "update",
          id: img.id,
          description: img.description,
        });
        return img;
      });
      setImages(arrayImg);
    }
  };

  const getUserBody = () => {
    return { user: props.user, role: props.role };
  };

  const getNid = () => props?.location?.state?.apartment?.nid ?? "";

  const getStateApartament = (props) => {
    if (
      props.role === ROLE.CALLCENTER &&
      typeof props.location?.state?.apartment !== "undefined"
    ) {
      return props.location.state.apartment.estado;
    }
    return 0;
  };

  const getStateOld = (props) => {
    if (typeof props.location.state.apartment !== "undefined") {
      if (typeof props.location.state.apartment.estadoold_id != "undefined") {
        return props.location.state.apartment.estadoold_id;
      } else {
        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    const orderData = (data) =>
      data.sort((a, b) => (a.orden > b.orden ? 1 : -1));

    const fetchData = async () => {
      const apikey = process.env.REACT_APP_FORMS_API_KEY;
      const rootUrl =
        process.env.REACT_APP_FORM_API_URL_V2 +
        process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH;
      const endpointUrl = process.env.REACT_APP_ENDPOINT_FORM_API_GET_FORM;
      const url = rootUrl + endpointUrl;
      setIsLoading(true);
      let stateApartament = getStateApartament(props);
      let stateOld = getStateOld(props);
      let numForm = props.location.state.num_formulario
        ? props.location.state.num_formulario
        : 0;
      setNumFormulario(numForm);
      await axios({
        url: url,
        method: "post",
        headers: {
          "x-api-key": apikey,
          "Content-Type": "application/json",
        },
        data: `{"role": "${props.role}", "state": "${stateApartament}", "state_old_id": ${stateOld}, "num_formulario": ${numForm}}`,
      })
        .then((response) => {
          var tabs = response.data.data.tabs;
          var data = props.location.state;

          setIsUpdate(data.update);
          setStateOldId(stateOld);

          if (props.role !== ROLE.VENTANERO && data.update) {
            setImagesOnUpdate(data.apartment.images);
          }

          orderData(tabs);

          tabs.forEach((tab) => {
            var groups = tab.groups;
            orderData(groups);
            groups.forEach((group) => {
              var currentFields = group.fields;
              orderData(currentFields);
              let fieldsList = [];
              currentFields.forEach((result) => {
                if (data.update) {
                  const valueName = data.apartment[result.name];
                  var defaultData = valueName;
                  if (props.role !== ROLE.CALLCENTER) {
                    defaultData =
                      result.type === "radioGroup"
                        ? valueName === 1
                          ? true
                          : valueName === null
                          ? ""
                          : false
                        : valueName;
                  }
                  fieldsList.push({
                    ...result,
                    estado_tag: data.apartment["estado_tag"],
                    default: defaultData,
                  });
                } else {
                  fieldsList.push({
                    ...result,
                    default:
                      result.default === null
                        ? result.type === "radio"
                          ? false
                          : ""
                        : result.default,
                  });
                }
              });
              group.fields = fieldsList;
            });
          });
          setFields(tabs);

          if (!data.update) {
            getGeolocalization(tabs);
          }
        })
        .catch((error) => {
          displayError(errorTypes.UNEXPECTED_ERROR);
        })
        .finally(function() {
          setIsLoading(false);
        });
    };

    const onErrorGeo = (err) => {
      console.error(`ERROR(${err.code}): ${err.message}`);
    };

    const setPosition = (position, tabsFields) => {
      let tabs = tabsFields.slice();
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];
        for (let j = 0; j < tab.groups.length; j++) {
          const group = tab.groups[j];
          group.fields.map((field) => {
            if (field) {
              switch (field.name) {
                case "latitud":
                  field.default = position.coords.latitude;
                  break;
                case "longitud":
                  field.default = position.coords.longitude;
                  break;
                default:
                  break;
              }
              return field;
            }
          });
        }
      }

      setFields(tabs);
    };

    const getGeolocalization = (tabs) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => setPosition(position, tabs),
          onErrorGeo,
          {
            maximumAge: 300000,
            timeout: 5000,
            enableHighAccuracy: true,
          }
        );
      }
    };

    fetchData();

    return () => {
      setIsLoading(false);
    };
  }, [props.role]);

  const handleSubmit = (values, stopRedirect) => {
    if (values.validateButton) {
      sendDataApiDuplicate(values);
    } else {
      let imgAllowed = true;
      if (images.length === 0 && props.role === ROLE.VENTANERO) {
        displayError(errorTypes.NO_IMAGE_ERROR);
        imgAllowed = false;
      }
      if (imgAllowed) {
        sendDataAPI(values, stopRedirect);
      }
      setValidated(true);
    }
  };

  const getImagesForm = () => {
    const imagesFormatArray = images.reduce((acc, image) => {
      if (image.type !== "new") return acc;
      const newExt = image.extension.slice(6);
      const indexFormat = image.img.lastIndexOf(",") + 1;
      const newImg64 = image.img.slice(indexFormat);
      return [
        ...acc,
        {
          file: newImg64,
          extension: newExt,
          description: image.description,
        },
      ];
    }, []);
    return { images: imagesFormatArray };
  };

  /**
   * Verify status completo & suficiente
   * @param {*} values come from forms
   */
  const getState = (values) => {
    let isComplete = true;
    let isEnough = true;
    let remodeladoFlag = false;
    let garageFlag = false;

    if (props.role === ROLE.CALLCENTER || props.role === ROLE.ADMIN) {
      if (values["remodelado"] && values["remodelado"] === "true") {
        remodeladoFlag = true;
      }

      const data = props.location.state;
      if (data.apartment.estado === "web_incompleto") {
        values["web_incompleto"] = true;
      }

      if (values["garajes"] && Number(values["garajes"]) > 0) {
        garageFlag = true;
      }
      if (!values["num_piso"]) {
        isComplete = false;
        isEnough = false;
      }

      for (let i = 0; i < fields.length; i++) {
        let tab = fields[i];
        for (let j = 0; j < tab.groups.length; j++) {
          let group = tab.groups[j];
          for (let k = 0; k < group.fields.length; k++) {
            let field = group.fields[k];

            if (field.type === "radioGroup") {
              if (values[field.name] === "") {
                values[field.name] = null;
              } else if (values[field.name] === "true") {
                values[field.name] = true;
              } else if (values[field.name] === "false") {
                values[field.name] = false;
              }
            }

            if (field.type !== "radio") {
              const valueComplete = values[field.name];

              if (
                field.type === "inputCurrency" &&
                (valueComplete === 0 ||
                  valueComplete === "" ||
                  valueComplete === null ||
                  valueComplete === undefined)
              ) {
                isEnough = false;
                if (field.verify_complete) {
                  isComplete = false;
                }
              } else if (
                valueComplete === "" ||
                valueComplete === null ||
                valueComplete === undefined
              ) {
                isEnough = false;
                if (
                  field.name === "ano_remodelacion" ||
                  field.name === "tipo_remodelacion"
                ) {
                  if (remodeladoFlag && field.verify_complete) {
                    isComplete = false;
                  }
                } else if (field.name === "tipo_garaje") {
                  if (garageFlag && field.verify_complete) {
                    isComplete = false;
                  }
                } else if (field.verify_complete) {
                  if (field.name !== "remodelado") {
                    isComplete = false;
                  }
                }
              }
            }
          }
        }
      }
    }

    setIsStatusCompletado(isComplete);
    if (props.role === ROLE.CALLCENTER || props.role === ROLE.VENTANERO) {
      if (isEnough && values["estado"] !== "duplicado") {
        values["estado"] = STATUS.SUFICIENTE;
      } else if (isComplete && values["estado"] !== "duplicado") {
        values["estado"] = STATUS.COMPLETO;
      } else if (values["estado"] === STATUS.COMPLETO && !isComplete) {
        displayError(errorTypes.INVALID_CLIENT_STATUS_ERROR);
        return false;
      }
    }

    if (props.role === ROLE.VENTANERO) {
      if (values["fuente_id"] === "28" && values["url_publicacion"] === "") {
        return false;
      }
    }

    return values;
  };

  const getFlagNeighborhood = (values) => {
    let flag = { flag_barrio: false };
    if (props.role === ROLE.VENTANERO || props.role === ROLE.ADMIN) {
      const currentVal = values["barriocomun"];
      if (props.flagBarrio !== "") {
        if (currentVal !== props.flagBarrio) {
          flag = { flag_barrio: true };
        }
      }
    }
    return flag;
  };

  const checkBooleanValue = (value) => {
    if (value === true || value === false) {
      return value;
    }

    if (value) {
      let temp = Number(value);
      switch (temp) {
        case 1: return true;
        case 0: return false;
        default: return null;
      }
    } else {
      return null;
    }
  };

  const sendDataAPI = (values, stopRedirect) => {
    var apikey = process.env.REACT_APP_FORMS_API_KEY;
    if (props.role === ROLE.ROTACION) {
      apikey = process.env.REACT_APP_FORMS_API_KEY_V2;
    }
    const rootUrl =
      process.env.REACT_APP_FORM_API_URL_V2 +
      process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH;
    const rootUrlV2 = process.env.REACT_APP_FORM_API_URL_V3;
    const endpointUrlPost =
      process.env.REACT_APP_ENDPOINT_FORM_API_POST_PROPERTY;
    const endpointUrlTurnover =
      process.env.REACT_APP_ENDPOINT_FORM_API_PUT_TURNOVER;
    const urlPutTurnover = rootUrlV2 + endpointUrlTurnover;
    const estadoValues = getState(values);

    // URL POST VENTANERO
    const postVentanero =
      process.env.REACT_APP_ENDPOINT_FORM_API_POST_PROPERTY_VENTANERO;
    if (props?.role === ROLE.VENTANERO) {
      apikey =
        process.env.REACT_APP_ENDPOINT_FORM_API_POST_PROPERTY_VENTANERO_API_KEY;
    }

    // URL PUT COORDINADOR
    const putCoordinador =
      process.env.REACT_APP_ENDPOINT_FORM_API_PUT_PROPERTY_COORDINADOR;

    //URL POST COORDINADOR LOTE - num_formulario = 1
    const postLoteCoordinador =
      process.env.REACT_APP_ENDPOINT_FORM_API_POST_LOTE_COORDINADOR;

    //URL PUT CALLCENTER
    const putCallcenter =
      process.env.REACT_APP_ENDPOINT_FORM_API_PUT_PROPERTY_CALLCENTER;

    //URL PUT ADMIN
    const putAdmin = process.env.REACT_APP_ENDPOINT_FORM_API_PUT_PROPERTY_ADMIN;

    if (!estadoValues) return;

    setIsLoading(true);
    const updateData = props.location.state;
    const data_fuente_id = updateData.apartment
      ? updateData.apartment.fuente_id
      : props.role === ROLE.VENTANERO
      ? values["fuente_id"]
      : 1;
    const fetchData = async () => {
      // Forms: Standard Forms
      if (numFormulario === 0) {
        const body = Object.assign(
          {},
          { entrada: "sendform", pais: "CO" },
          estadoValues,
          getUserBody(),
          getImagesForm(),
          getFlagNeighborhood(values),
          { nid: props?.location?.state?.apartment?.nid },
          {
            fuente: updateData.apartment
              ? updateData.apartment.fuente
              : "Ventana",
          },
          {
            fuente_id: data_fuente_id,
          },
          { num_upz: props.num_upz },
          props.role !== ROLE.VENTANERO
            ? props.role === ROLE.ROTACION
              ? { id: updateData.apartment.negocio_id }
              : { id: updateData.apartment.inmueble_id }
            : {}
        );

        body["country"] = "CO";
        body["ask_price"]
          ? (body["ask_price"] = parseInt(body["ask_price"]))
          : delete body["ask_price"];
        body["area"]
          ? (body["area"] = parseInt(body["area"]))
          : delete body["area"];
        body["land_area"]
          ? (body["land_area"] = parseInt(body["land_area"]))
          : delete body["land_area"];
        body["area_balcon"]
          ? (body["area_balcon"] = parseInt(body["area_balcon"]))
          : delete body["area_balcon"];
        body["area_terraza"]
          ? (body["area_terraza"] = parseInt(body["area_terraza"]))
          : delete body["area_terraza"];
        body["num_piso"]
          ? (body["num_piso"] = parseInt(body["num_piso"]))
          : delete body["num_piso"];
        body["num_habitaciones"]
          ? (body["num_habitaciones"] = parseInt(body["num_habitaciones"]))
          : delete body["num_habitaciones"];
        body["num_ascensores"]
          ? (body["num_ascensores"] = parseInt(body["num_ascensores"]))
          : delete body["num_ascensores"];
        body["num_duchas"]
          ? (body["num_duchas"] = parseInt(body["num_duchas"]))
          : delete body["num_duchas"];
        body["tipo_inmueble_id"]
          ? (body["tipo_inmueble_id"] = parseInt(body["tipo_inmueble_id"]))
          : delete body["tipo_inmueble_id"];
        body["banos"]
          ? (body["banos"] = parseInt(body["banos"]))
          : delete body["banos"];
        body["estrato"]
          ? (body["estrato"] = parseInt(body["estrato"]))
          : delete body["estrato"];
        body["garajes"]
          ? (body["garajes"] = parseInt(body["garajes"]))
          : delete body["garajes"];
        body["banos_obra_gris"]
          ? (body["banos_obra_gris"] = parseInt(body["banos_obra_gris"]))
          : delete body["banos_obra_gris"];
        body["obra_gris"]
          ? (body["obra_gris"] = parseInt(body["obra_gris"]))
          : delete body["obra_gris"];
        body["piso_sala"] || delete body["piso_sala"];
        body["piso_habitaciones"] || delete body["piso_habitaciones"];
        notFound
          ? (body["not_found_lot"] = notFound)
          : delete body["not_found_lot"];

        // Validation fields per roles
        switch (body.role) {
          case 'callcenter':
            body["inmobiliaria"] = checkBooleanValue(body["inmobiliaria"]);
            body["num_apartamento"] = body["num_apartamento"] ? String(body["num_apartamento"]) : "";
            body["hipotecado"] = checkBooleanValue(body["hipotecado"]);
            body["zonas_humedas"] = checkBooleanValue(body["zonas_humedas"]);
            body["zona_verde"] = checkBooleanValue(body["zona_verde"]);
            body["zona_ninos"] = checkBooleanValue(body["zona_ninos"]);
            body["zona_bbq"] = checkBooleanValue(body["zona_bbq"]);
            body["zona_lavanderia"] = checkBooleanValue(body["zona_lavanderia"]);
            body["terraza"] = checkBooleanValue(body["terraza"]);
            body["salon_comunal"] = checkBooleanValue(body["salon_comunal"]);
            body["porteria"] = checkBooleanValue(body["porteria"]);
            body["planta_electrica"] = checkBooleanValue(body["planta_electrica"]);
            body["parqueadero_visitante"] = checkBooleanValue(body["parqueadero_visitante"]);
            body["gas"] = checkBooleanValue(body["gas"]);
            body["hall_alcobas"] = checkBooleanValue(body["hall_alcobas"]);
            body["gimnasio"] = checkBooleanValue(body["gimnasio"]);
            body["cuarto_servicio"] = checkBooleanValue(body["cuarto_servicio"]);
            body["conjunto_cerrado"] = checkBooleanValue(body["conjunto_cerrado"]);
            body["bano_servicio"] = checkBooleanValue(body["bano_servicio"]);
            body["balcon"] = checkBooleanValue(body["balcon"]);
            body["garaje_cubierto"] = checkBooleanValue(body["garaje_cubierto"]);
            break;

            default:
              break;
        }


        let urlRequest = rootUrl + endpointUrlPost;

        switch (body.role) {
          case "ventanero":
            urlRequest = postVentanero;
            removeEmptyFields(body, ["num_apartamento"]);
            break;
          case "coordinador_cos":
          case "coordinador":
            urlRequest = putCoordinador;
            apikey = process.env.REACT_APP_FORMS_API_KEY_V2;
            break;
          case "callcenter":
            urlRequest = process.env.REACT_APP_FORM_API_URL_V3 + 'put_callcenter_co';
            apikey = process.env.REACT_APP_FORMS_API_KEY_V2;
            setIsUpdate(true);
            break;
          case "admin":
            urlRequest = rootUrl + putAdmin;
            break;
          case ROLE.ROTACION:
            urlRequest = urlPutTurnover;
            break;
          default:
            break;
        }
        if (body.role === ROLE.VENTANERO && stopRedirect) setIsLoading(false);
        if (body.role === ROLE.COORDINADOR && stopRedirect) setIsLoading(true);
        axios({
          url: urlRequest,
          method: isUpdate ? "put" : "post",
          headers: {
            "x-api-key": apikey,
            "Content-Type": "application/json",
          },
          data: body,
        })
          .then((response) => {
            const redirectOnRole = [ROLE.CALLCENTER, ROLE.ADMIN, ROLE.ROTACION];

            if (response.data.success && !stopRedirect) {
              goToIndex();
            }
            if (
              handleSearchText(response?.data?.message) &&
              response?.data?.status === 1
            ) {
              handleShowMessageDuplicate();
              setOpenSnack(true);
            }
            if (response.data.hasLot !== undefined) {
              !response.data.hasLot ? setOpenModalMap(true) : goToIndex();
            } else if (
              !response.data.hasLot &&
              ROLE.COORDINADOR === body.role &&
              !response.data.status
            ) {
              goToIndex();
            }
            removeNotFound();
            if (response.data.idconjunto_exists == 0) {
              displayError(errorTypes.NO_CONJUNTO_ID_ERROR);
              if (!stopRedirect) goToIndex();
            } else {
              if (!stopRedirect) goToIndex();
            }

            if (redirectOnRole.includes(body?.role)) {
              goToIndex();
            }
          })
          .catch((error) => {
            setFormCache({ reqFailed: true, body });
            if (body.role === ROLE.VENTANERO) {
              displayAddressError(body.direccion, body.telefono);
            } else {
              displayError(errorTypes.UNEXPECTED_ERROR);
            }
          })
          .finally(() => setIsLoading(false));
      } else {
        // Build: Body for every num_formulario >= 1
        const userBody = getUserBody();

        // Build: urlRequest
        let urlRequest = rootUrl + endpointUrlPost;

        // Build: Complete URL
        switch (userBody.role) {
          case "coordinador":
            if (numFormulario === 1) {
              urlRequest = rootUrl + postLoteCoordinador;

              // Check: values for coordinador_lote (Both values empty, and deletes ca.)
              if (values.ap === "" && values.ca === "") {
                values.ap = 1;
                delete values.ca;
              } else if (values.ca === "") {
                delete values.ca;
              } else if (values.ap === "") {
                delete values.ap;
              }
              if (values.validateButton === false || values.validateButton) {
                setIsLoading(false);
                goToIndex();
              }
            }
            break;
          default:
            goToIndex();
            break;
        }

        // Build: Main request
        const body = Object.assign({}, values, { pais: "CO" });

        // Validating: Fields
        body["latitud_conjunto"]
          ? (body["latitud_conjunto"] = parseFloat(body["latitud_conjunto"]))
          : delete body["latitud_conjunto"];
        body["longitud_conjunto"]
          ? (body["longitud_conjunto"] = parseFloat(body["longitud_conjunto"]))
          : delete body["longitud_conjunto"];
        body["ap"] ? (body["ap"] = parseInt(body["ap"])) : delete body["ap"];
        body["ca"] ? (body["ca"] = parseInt(body["ca"])) : delete body["ca"];

        // Sending: Request
        await axios({
          url: urlRequest,
          method: isUpdate ? "put" : "post",
          headers: {
            "x-api-key": apikey,
            "Content-Type": "application/json",
          },
          data: body,
        })
          .then((response) => {
            goToIndex();
          })
          .catch((error) => {
            displayError(errorTypes.UNEXPECTED_ERROR);
          })
          .finally(function() {
            setIsLoading(false);
            goToIndex();
          });
      }
    };
    clearImages();
    fetchData();
  };

  const sendDataApiDuplicate = (values) => {
    const apikey = process.env.REACT_APP_FORMS_API_KEY;
    const rootUrl =
      process.env.REACT_APP_FORM_API_URL_V2 +
      process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH;
    const endpointUrl =
      process.env.REACT_APP_ENDPOINT_FORM_API_POST_PROPERTY_DUPLICATE;
    const url = rootUrl + endpointUrl;
    const updateData = props.location.state;
    setIsLoading(true);
    const fetchData = async () => {
      const body = Object.assign(
        {},
        { entrada: "sendform" },
        getUserBody(),
        values,
        { fuente: "Ventana" },
        { fuente_id: 1 },
        { id: updateData.apartment.inmueble_id }
      );
      await axios({
        url: url,
        method: "post",
        headers: {
          "x-api-key": apikey,
          "Content-Type": "application/json",
        },
        data: body,
      })
        .then((response) => {
          if (response.data.is_duplicated) {
            setModalOpen(true);
          }
        })
        .catch((error) => {
          displayError(errorTypes.UNEXPECTED_ERROR);
        })
        .finally(function() {
          setIsLoading(false);
        });
    };
    fetchData();
  };

  const goToIndex = () => {
    setTimeout(() => {
      props.history.push("/dashboard");
    }, 3500);
  };

  const reSendFailedPost = () => {
    const body = formCache.body;
    setFormCache({ reqFailed: false, body });

    if (numFormulario !== 0 || formCache.body.role !== "ventanero") return;

    const apikey =
      process.env.REACT_APP_ENDPOINT_FORM_API_POST_PROPERTY_VENTANERO_API_KEY;
    const urlRequest =
      process.env.REACT_APP_ENDPOINT_FORM_API_POST_PROPERTY_VENTANERO;

    axios({
      url: urlRequest,
      method: isUpdate ? "put" : "post",
      headers: {
        "x-api-key": apikey,
        "Content-Type": "application/json",
      },
      data: body,
    })
      .then((res) => {
        setFormCache({ reqFailed: false, body: {} });
      })
      .catch((err) => {
        setFormCache({ reqFailed: true, body });
        displayAddressError(body.direccion, body.telefono);
      });
  };
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const handleSearchText = (text = "") => {
    return text.includes("duplicado");
  };
  const handleShowMessageDuplicate = () => {
    let notification = {
      title: "Duplicado",
      message: snackMessage,
      type: "info",
      insert: "top",
      container: "top-left",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3500,
        onScreen: false,
      },
    };
    store.addNotification({
      ...notification,
      container: "top-left",
    });
  };

  return (
    <FormsContainerWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={10000}
        onClose={handleCloseSnack}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
            id="snackbar-close-icon-drop-img"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <ReactNotification />
      <ModalResponse isOpen={modalOpen} />
      <HeaderSmall role={props.role} />
      <BlockUi tag="div" blocking={isLoading} className="tabs-container">
        {!isUpdate ? (
          <>
            {numFormulario >= 1 ? (
              <FormStreet
                title="Creación de nuevo lote"
                fields={fields}
                handleSubmit={handleSubmit}
                role={props.role}
                images={images}
                user={props.user}
                estadoold_id={stateOldId}
                numFormulario={numFormulario}
              />
            ) : (
              <FormStreetVentanero
                reqFailed={formCache.reqFailed}
                reSendPost={reSendFailedPost}
                validated={validated}
                fields={fields}
                history={props.history}
                handleSubmit={handleSubmit}
                role={props.role}
                images={images}
              />
            )}
          </>
        ) : (
          <FormStreet
            title="Actualización de un inmueble"
            validated={validated}
            fields={fields}
            handleSubmit={handleSubmit}
            role={props.role}
            images={images}
            user={props.user}
            estadoold_id={stateOldId}
            numFormulario={numFormulario}
            nid={getNid()}
            openModalMap={openModalMap}
            setOpenModalMap={setOpenModalMap}
          />
        )}
      </BlockUi>
    </FormsContainerWrapper>
  );
};

const mapStateToProps = (state) => ({
  role: state.formData.role,
  user: state.formData.email,
  num_upz: state.formData.num_upz,
  flagBarrio: state.formData.flagBarrio,
  images: state.ventanerosImages,
  notFound: state.notFoundReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setImages: (image) => dispatch(setImagesAction(image)),
  clearImages: () => dispatch(clearImagesAction()),
  removeNotFound: () => dispatch(removeNotFoundAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormsContainer));
