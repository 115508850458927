import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import {
  purple,
  brightTurquoise000,
  electricPurple600,
  scarpaGray000,
  scarpaGray100,
  scarpaGray400,
  disabledBackground,
  scarpaGray200,
  scarpaGray300,
  scarpaGray600,
  white,
  size,
  error,
  nightBlue500,
  muliBoldFamily,
} from "../../StylesConstants";

export const ModalZonesWrapper = styled.section`
  display: grid;
  .file-name-container {
    display: grid;
    grid-template: 1fr / 80px 500px;
    align-items: center;
    .div-container {
      display: grid;
      grid-template: 1fr/ 400px 100px;
    }
  }
  .photo {
    width: 50px;
    height: 96px;
    margin-bottom: 3px;
    border-radius: 10px;
  }
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    outline: none;
  }
  .MuiIcon-colorPrimary {
    color: ${purple};
  }
  .MuiSvgIcon-colorPrimary {
    color: ${purple};
  }
`;

export const StyledIconButton = withStyles({
  root: {
    marginBottom: "16px",
    boxShadow: `5px 5px 6px ${scarpaGray100}`,
  },
})(IconButton);

export const ToolWrapper = styled.div`
  width: 100%;
  .active {
    background: ${brightTurquoise000};
  }
  .wrapper {
    padding: 16px 0px;
    .filters {
      margin: 19px 0px;
      grid-area: filters;
      display: grid;
      grid-template-columns: repeat(6, auto);
      width: fit-content;
      column-gap: 40px;
      .container {
        span {
          font-size: 13px;
        }
      }
      .container-check {
        span {
          font-size: 13px;
        }
        .btn-primary {
          border: none;
          border-color: none;
        }
      }
    }
    .filters-zona {
      display: grid;
      grid-template-columns: repeat(5, auto);
      row-gap: 10px;
      column-gap: 30px;
      margin: 19px 0px;
      .container {
        span {
          font-size: 13px;
        }
      }
      .container-check {
        span {
          font-size: 13px;
        }
        .btn-primary {
          border: none;
          border-color: none;
        }
      }
      .radio-container {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        column-gap: 20px;
        height: 0;
        margin-top: 8px;
        .title {
          font-size: 13px;
          font-weight: 600;
          color: ${scarpaGray600};
          margin-bottom: 0px;
        }
        .switch-toggle {
          column-gap: 20px;
          align-items: center;
          li {
            height: auto;
          }
        }
        label {
          font-size: 13px;
        }
      }
    }

    .manual-comparable {
      display: grid;
      grid-template-columns: repeat(4, auto);
      column-gap: 16px;
      align-items: center;
      justify-items: end;
      position: absolute;
      right: 16px;
      top: 20px;
      font-size: 13px;
      .container span {
        font-weight: 600;
        font-size: 13px;
        color: ${scarpaGray600};
      }

      .sales {
        text-align: center;
        color: ${scarpaGray600};
        border-right: 1px solid ${scarpaGray300};
        .title-sales {
          font-weight: 600;
        }
        .value-sales {
          padding: 16px 24px 16px 16px;
        }
        .donut-icon {
          font-size: 18px;
          margin-right: 6px;
        }
      }

      .coefficient {
        text-align: center;
        color: ${scarpaGray600};
        border-left: 1px solid ${scarpaGray300};
        border-right: 1px solid ${scarpaGray300};
        .title-coefficient {
          font-weight: 600;
          margin-left: 24px;
        }
        .value-coefficient {
          padding: 16px 24px 16px 16px;
        }
      }

      #download-button {
        border: 1px solid ${scarpaGray200};
        width: 209px;
        height: 40px;
        background: ${white};
        justify-content: center;
        align-items: center;
        color: ${scarpaGray600};

        span {
          font-size: 13px;
          margin-left: 4px;
          color: ${scarpaGray600};
        }
        &:hover {
          color: ${scarpaGray000};
          background-color: ${electricPurple600};
          margin: 0px;
          span {
            color: ${scarpaGray000};
            vertical-align: middle;
          }
        }
        .download-icon {
          font-size: 18px;
        }
      }
    }
  }
`;

export const InputWrapper = styled.div`
  margin: 0;

  input:-webkit-autofill {
    box-shadow: 0 0 0 30px white inset !important;
  }

  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px ${brightTurquoise000} inset !important;
  }

  input:-webkit-autofill:disabled {
    -webkit-text-fill-color: ${scarpaGray100} !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: ${scarpaGray600} !important;
  }

  .form-control:focus-within {
    background: #e6fef9;
    border: 2px solid #00f2c3;
    box-sizing: border-box;
    border-radius: 8px;
    input {
      background: #e6fef9;
    }
  }

  .form-control {
    border: 1px solid ${disabledBackground};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 6px 16px;
    height: 56px;
    color: ${scarpaGray600};
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    box-shadow: 5px 5px 6px ${scarpaGray300};

    input {
      border: none;
      outline: none;
      height: 28px;
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: ${scarpaGray600};
      ::placeholder {
        color: ${scarpaGray300};
      }
    }

    &.disabled {
      background: ${scarpaGray100};
      border: 1px solid ${disabledBackground};
      box-sizing: border-box;
      border-radius: 8px;
      color: ${scarpaGray400};

      input {
        background: ${scarpaGray100};
        color: ${scarpaGray400};
      }
    }

    &.no-label {
      input {
        height: 44px;
      }
    }
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background: #ffeeee;
    border: 1px solid #e51817;
    box-sizing: border-box;
    border-radius: 8px;
    input {
      background: #ffeeee;
    }
  }

  .invalid-feedback {
    color: ${error};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }

  .input-label {
    font-size: 16px;
    line-height: 19px;
    color: ${scarpaGray600};
  }

  @media screen and (min-width: ${size.tablet}) {
  }
`;

export const ZoneFormWrapper = styled.section`
  margin-bottom: 1rem;
  .div-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
    column-gap: 1rem;
  }

  .id, .status, .is-active, .old {
    width: 6rem;
  }

  .min, .max, .ask-price-top {
    width: 10rem;
  }

  .title {
    font-family: ${muliBoldFamily};
    font-size: 12px;
    padding-left: 8px;
    color: ${nightBlue500};
  }

  // hide arrows input type number safari, chrome
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // hide arrows input type number mozilla
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
