import React, { useEffect, useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import SelectedImage from "../DropImagesArea/SelectedImage";
import { GalleryWrapper } from "./style";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const GalleryContainer = (props) => {
  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState(props.selected);
  useEffect(() => {
    setImages(props.imgs);
  }, [JSON.stringify(props.imgs)]);

  return (
    <GalleryWrapper>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images &&
          images.map((photo, index) => (
            <SelectedImage
              selected={selected}
              key={index}
              margin={"2px"}
              index={index}
              photo={photo}
              left={0}
              top={0}
              handleSelect={props.handleSelect}
              images={images}
            />
          ))}
      </Masonry>
    </GalleryWrapper>
  );
};

export default GalleryContainer;
