import React from "react";
import { Add } from "@material-ui/icons";
import { Animated } from "react-animated-css";
import ZonesForm from "./zonesForm";
import { StyledIconButton } from "./style";

const CreateZone = (props) => {
  return (
    <div>
      <StyledIconButton
        data-id="btn-add-create-zone"
        aria-label="add"
        onClick={props.handleIsVisible}
      >
        <Add color={"primary"} />
      </StyledIconButton>
      <Animated
        isVisible={props.isVisible}
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={2000}
        animationOutDuration={1000}
      >
        {props.isVisible && (
          <div>
            <ZonesForm
              rowEdit={props.rowEdit}
              isEdit={props.isEdit}
              handleIsVisible={props.handleIsVisible}
            />
          </div>
        )}
      </Animated>
    </div>
  );
};

export default CreateZone;
