import Axios from "axios";

export const getApartmentsRoleRotation = (data) => {
  const rootUrl = process.env.REACT_APP_FORM_API_URL_V3;
  const endpointUrlListTurnover =
    process.env.REACT_APP_ENDPOINT_FORM_API_GET_LIST_TURNOVER_NEW;
  const url = rootUrl + endpointUrlListTurnover;
  var apiKey = process.env.REACT_APP_FORMS_API_KEY_V2;
  return Axios.post(url, data, {
    headers: { "x-api-key": apiKey, "Content-Type": "application/json" },
  });
};
