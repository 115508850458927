import React, { useState } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import CurrencyInput from "./currencyInput";
import TextInput from "./textInput";
import IconButton from "@material-ui/core/IconButton";
import { StyledIconButton, ZoneFormWrapper } from "./style";
import CheckIcon from "@material-ui/icons/Check";
import { fetchDataAxios } from "../../utils/fetchRequest";
import { Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";

const apikey = process.env.REACT_APP_FORMS_API_KEY;
const rootUrl =
  process.env.REACT_APP_FORM_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH;
const endpointPostUrl =
  process.env.REACT_APP_ENDPOINT_FORM_API_POST_VALIDAR_ZONA_MEDIANA;
const urlPost = rootUrl + endpointPostUrl;

const ZonesForm = (props) => {
  const [rangoMin] = useState("precio_bajo");
  const [rangoMax] = useState("precio_alto");
  const [askPriceTop] = useState("ask_price_top");
  const [snackMessage, setSnackMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [validButton, setValidButton] = useState(true);
  const saveData = (validateForm, values) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        setSnackMessage(`Todos los campos son obligatorios`);
        setOpenSnack(true);
      } else {
        const postDataZonas = async (data) => {
          try {
            const responseZonas = fetchDataAxios(urlPost, {
              method: "POST",
              headers: {
                "x-api-key": apikey,
                "Content-Type": "application/json",
              },
              data: data,
            });
            const responseDataZonas = await responseZonas;
            setSnackMessage(
              `La zona se ${props.isEdit ? "actualizó" : "creó"} correctamente`
            );
            setOpenSnack(true);
            props.handleIsVisible();
          } catch (e) {
            console.log(e);
            setSnackMessage("verifica la zona mediana id");
            setOpenSnack(true);
          }
        };

        if (props.isEdit) {
          const send = {
            id: values.id,
            validar_columna: JSON.stringify({
              precio_bajo: values.precio_bajo,
              precio_alto: values.precio_alto,
              ask_price_top: values.ask_price_top,
              ano_antiguedad: values.ano_antiguedad,
            }),
            activo: values.activo,
            validar_zona_mediana_estado_id:
              values.validar_zona_mediana_estado_id,
            type: "edit",
            agente: props.email,
          };
          postDataZonas(send);
        } else {
          const send = {
            id: values.id,
            validar_columna: JSON.stringify({
              buckets: [1.1, 1.4, 5.1, 5.4, 10.1, 10.4],
              precio_bajo: values.precio_bajo,
              precio_alto: values.precio_alto,
              ask_price_top: values.ask_price_top,
              ano_antiguedad: values.ano_antiguedad,
            }),
            activo: values.activo,
            validar_zona_mediana_estado_id:
              values.validar_zona_mediana_estado_id,
            type: "create",
            agente: props.email,
          };
          postDataZonas(send);
        }
      }
    });
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const stringSchema = Yup.object().shape({
    id: Yup.number()
      .required("Requerido.")
      .min(4, "Por favor ingrese un número valido"),
    precio_bajo: Yup.string().required("Requerido!"),
    precio_alto: Yup.string().required("Requerido!"),
    ask_price_top: Yup.string().required("Requerido!"),
    ano_antiguedad: Yup.number().required("Requerido!"),
    activo: Yup.number().required("Requerido!"),
    validar_zona_mediana_estado_id: Yup.number().required("Requerido!"),
  });
  const validateChange = (validateForm, values) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) setValidButton(false);
    });
    setValidButton(true);
  };

  return (
    <ZoneFormWrapper>
      <Formik
        enableReinitialize
        validationSchema={stringSchema}
        initialValues={
          props.rowEdit
            ? props.rowEdit
            : {
                id: "",
                precio_bajo: "",
                precio_alto: "",
                ask_price_top: "",
                ano_antiguedad: "",
                activo: "",
                validar_zona_mediana_estado_id: "",
              }
        }
      >
        {({
          values,
          errors,
          setFieldValue,
          touched,
          validateForm,
          setTouched,
        }) => (
          <Form id="formFilter">
            <div className="div-container">
              <div className="id">
                <span className="title">Id</span>
                <TextInput
                  name="id"
                  errors={errors}
                  touched={touched}
                  id="id-zone"
                  placeholder="id"
                  type="number"
                  className="input-filter"
                  values={values}
                  disabled={props.isEdit}
                />
              </div>
              <div className="min">
                <span className="title">Rango Mínimo</span>
                <CurrencyInput
                  name="precio_bajo"
                  errors={errors}
                  touched={touched}
                  id="precio_bajo-zone"
                  placeholder="$20,000,000"
                  type="text"
                  className="input-filter"
                  value={values[rangoMin]}
                  onValueChange={(val) =>
                    setFieldValue([rangoMin], val.floatValue)
                  }
                />
              </div>
              <div className="max">
                <span className="title">Rango Máximo</span>
                <CurrencyInput
                  name="precio_alto"
                  errors={errors}
                  touched={touched}
                  id="precio_alto-zone"
                  placeholder="$400,000,000"
                  type="text"
                  className="input-filter"
                  value={values[rangoMax]}
                  onValueChange={(val) =>
                    setFieldValue([rangoMax], val.floatValue)
                  }
                />
              </div>
              <div className="ask-price-top">
                <span className="title">Ask Price Top</span>
                <CurrencyInput
                  name="ask_price_top"
                  errors={errors}
                  touched={touched}
                  id="ask_price_top-zone"
                  placeholder="$450,000,000"
                  type="text"
                  className="input-filter"
                  value={
                    !touched.ask_price_top
                      ? values[rangoMax] + 50000000
                      : values[askPriceTop]
                  }
                  onValueChange={(val) => {
                    setFieldValue([askPriceTop], val.floatValue);
                  }}
                  onClick={() =>
                    setTouched({ ...touched, ask_price_top: true })
                  }
                />
              </div>
              <div className="old">
                <span className="title">Antigüedad</span>
                <TextInput
                  name="ano_antiguedad"
                  errors={errors}
                  touched={touched}
                  id="ano_antiguedad-zone"
                  placeholder="25"
                  type="number"
                  className="input-filter"
                  values={values}
                />
              </div>
              <div className="is-active">
                <span className="title">Activo</span>
                <TextInput
                  name="activo"
                  errors={errors}
                  touched={touched}
                  id="activo-zone"
                  placeholder="1"
                  type="number"
                  className="input-filter"
                  values={values}
                />
              </div>
              <div className="status">
                <span className="title">Estado</span>
                <TextInput
                  name="validar_zona_mediana_estado_id"
                  errors={errors}
                  touched={touched}
                  id="validar_zona_mediana_estado_id"
                  placeholder="2"
                  type="number"
                  className="input-filter"
                  values={values}
                />
              </div>
              <div className="buttons">
                <StyledIconButton
                  data-id="btn-check-zone-form"
                  aria-label="check"
                  onClick={() => saveData(validateForm, values)}
                >
                  <CheckIcon color={"primary"} />
                </StyledIconButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton
            data-id="btn-close-snackbar-zoneform"
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </ZoneFormWrapper>
  );
};

const mapStateToProps = (state) => ({
  email: state.formData.email,
});

export default connect(mapStateToProps)(ZonesForm);
