import styled from "styled-components";
import {
  black,
  error,
  white,
  purple,
  size,
  light_purple
} from "../../../StylesConstants";

export const InputWrapper = styled.div`
  margin: 0;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input:-webkit-autofill:disabled {
    -webkit-box-shadow: 0 0 0 30px rgba(170, 167, 252, 0.2) inset !important;
    -webkit-text-fill-color: ${light_purple} !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: ${black} !important;
  }

  .form-control {
    border: 2px solid #dddddd;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 15px;
    height: 56px;
    font-size: 16px;
    line-height: 19px;
    color: ${black};

    &:disabled {
      background: rgba(170, 167, 252, 0.2);
      color: ${light_purple};
      border: 2px solid ${light_purple};
    }
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-image: none;
    background-color: rgba(255, 0, 0, 0.1);
    color: ${error};
  }

  .invalid-feedback {
    margin-left: 32px;
    color: ${error};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }

  .form-control:focus {
    color: ${black};
    background-color: ${white};
    border-color: ${purple};
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(87, 1, 179, 0.2);
  }

  .input-label {
    font-size: 16px;
    line-height: 19px;
    color: ${black};
  }

  @media screen and (min-width: ${size.tablet}) {
    margin: 0 15px;

    .form-control {
      padding: 15px 19px;
    }
  }
`;
