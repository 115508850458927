import React, { useState } from "react";
import Button from "../Button/button";
import { PhotoPickerWrapper } from "./style";
import { Formik, Form } from "formik";
import TextInput from "../TextInput/textInput";
import * as Yup from "yup";
import imageCompression from "browser-image-compression";

const PhotoPicker = (props) => {
  const [state, setState] = useState({
    selectedFile: null,
    imagePreviewUrl: null,
    error: false,
    disabled: true,
  });

  const photoSchema = Yup.object().shape({
    description: Yup.string().required(
      "Por favor escriba una descripción para la imagen"
    ),
  });

  async function fileChangedHandler(event) {
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/gif"
    ) {
      var options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(
          event.target.files[0],
          options
        );
        const newState = {
          selectedFile: compressedFile,
          error: false,
          isNext: true,
          disabled: false,
        };
        setState(newState);

        let reader = new FileReader();

        reader.onloadend = () => {
          setState({ ...newState, imagePreviewUrl: reader.result });
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.log(error);
      }
    } else {
      setState({
        error: true,
        disabled: true,
      });
    }
  }

  const submitPhoto = (description) => {
    var fd = new FormData();
    fd.append("file", state.selectedFile);
    const newImg = {
      imagePreviewUrl: state.imagePreviewUrl,
      selectedFile: state.selectedFile,
      description: description,
      isNext: true,
    };
    props.newImgHandle(newImg);
  };

  const returnBack = () => {
    props.newImgHandle({ isNext: false });
  };

  return (
    <PhotoPickerWrapper>
      <Formik
        initialValues={{
          photo: "",
          description: "",
        }}
        validationSchema={photoSchema}
        onSubmit={(values) => {
          if (state.selectedFile && values.description) {
            submitPhoto(values.description);
          } else {
            setState({
              error: true,
            });
          }
        }}
      >
        {(props) => (
          <Form className="form-photo">
            <div className="file-container">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.gif,.svg"
                onChange={fileChangedHandler}
                name="photo"
                data-id="input-form-photo"
              />
              {state.error && <span class="error">Formato no valido</span>}
            </div>
            <div className="description-photo">
              <TextInput
                name="description"
                errors={props.errors}
                touched={props.touched}
                label="Descripción de la imagen"
                data-id="txt-description-photo"
              />
            </div>
            {!state.imagePreviewUrl && (
              <div
                className="previewText image-container"
                data-id="preview-img-container"
              ></div>
            )}
            {state.imagePreviewUrl && (
              <div className="image-container">
                <img
                  src={state.imagePreviewUrl}
                  alt="icon"
                  className="preview-img"
                />
              </div>
            )}
            <div>
              <Button
                id="next"
                typeClass={state.disabled ? "disabled" : "primary"}
                type="submit"
                className="button-container d-inline-block mr-2"
                disabled={state.error}
                label="Cargar"
                data-id="photo-btn-load"
              />
              <Button
                id="return"
                typeClass="secondary"
                type="button"
                className="button-container d-inline-block"
                onClick={returnBack}
                label="Volver"
                data-id="photo-btn-retturn"
              />
            </div>
          </Form>
        )}
      </Formik>
    </PhotoPickerWrapper>
  );
};

export default PhotoPicker;
