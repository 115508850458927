export const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "id",
    format: (value) => (value ? value : "N/A"),
  },
  {
    id: "rangoMin",
    numeric: true,
    disablePadding: false,
    label: "Rango Minimo",
    format: (value) =>
      value !== null ? "$ " + new Intl.NumberFormat().format(value) : "0",
  },
  {
    id: "rangoMax",
    numeric: true,
    disablePadding: false,
    label: "Rango Maximo",
    format: (value) =>
      value !== null ? "$ " + new Intl.NumberFormat().format(value) : "0",
  },
  {
    id: "ask_price_top",
    numeric: true,
    disablePadding: false,
    label: "Ask Price Top",
    format: (value) =>
      value !== null ? "$ " + new Intl.NumberFormat().format(value) : "0",
  },
  {
    id: "antiguedad",
    numeric: true,
    disablePadding: false,
    label: "Antigüedad",
    format: (value) => (value ? value : "N/A"),
  },
  {
    id: "activo",
    numeric: true,
    disablePadding: false,
    label: "Activo",
    format: (value) => (value ? value : "N/A"),
  },
  {
    id: "estado",
    numeric: true,
    disablePadding: false,
    label: "Estado",
    format: (value) => (value ? value : "N/A"),
  },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    label: "Editar",
    format: (value) => (value ? value : "N/A"),
  },
];
