import React from "react";
import { CheckboxWrapper, CheckboxContainer } from "./style";

// Input feedback
const InputFeedback = ({ error }) => {
  return error ? <div className={"input-feedback"}>{error}</div> : null;
};

const Checkbox = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <CheckboxWrapper>
      <CheckboxContainer>
        <label
          className={`container ${value ? "checked" : ""}  ${
            errors[name] ? "errorCheckbox" : ""
          }`}
          htmlFor={id}
        >
          <span className={`label-name  ${errors[name] ? "error-label" : ""}`}>
            {label}
          </span>
          <input
            name={name}
            id={id}
            type="checkbox"
            value={value}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            className=""
          />
          <span className="checkmark"></span>
        </label>
      </CheckboxContainer>
      {touched[name] && <InputFeedback error={errors[name]} />}
    </CheckboxWrapper>
  );
};

export default Checkbox;
