import React, { useEffect, useState } from "react";
// Enhacer
import ReactNotification from "react-notifications-component";
import axios from "axios";
import { connect } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
// Enhacer tools
import { createValidationData } from "../validationForms";
import { updateData } from "../../../redux/actions/formData";
import { resetSelect } from "../../../redux/actions/resetSelect";
// components
import { store } from "react-notifications-component";
import BlockUi from "react-block-ui";
import { ROLE } from "../../const/constants";
// styles
import "react-notifications-component/dist/theme.css";
import { Tabs, Tab } from "react-bootstrap";
import Button from "../../sharedComponents/Button/button";
import Checkbox from "../../sharedComponents/Checkbox/checkbox";
import TextInput from "../../sharedComponents/TextInput/textInput";
import SelectComponent from "../../sharedComponents/Select/select";
import DatePickerForm from "../../sharedComponents/DatePicker/datePicker";
import { createYupSchema } from "../../sharedComponents/FormValidation/yupSchemaCreator";
import CurrencyInput from "../../sharedComponents/CurrencyInput/currencyInput";
import AddressInput from "../../sharedComponents/AddressInput/addressInput";
import CollapsibleButton from "../../sharedComponents/CollapsibleButton/collapsibleButton";
import {
  FormWrapper,
  FieldsContainer,
  FieldsGroup,
  FormNotification,
  SourceContainer,
} from "../style";
// assets
import ImagesApartment from "../../ImagesApartment/imagesApartment";
import {
  RadioButton,
  RadioButtonGroup,
} from "../../sharedComponents/RadioButton/radioButton";

/**
 * @file Manages the form for create new lead with role "ventanero".
 */
const FormStreetVentanero = ({
  reSendPost,
  reqFailed,
  fields: fieldsProps,
  ...props
}) => {
  const [fields, setFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [images, setImages] = useState([]);
  const [validationAvSchema, setValidationAvSchema] = useState({});
  const [showOnAvailableApt, setShowOnAvailableApt] = useState(false);
  const [apiConjuntoData, setApiConjuntoData] = useState({
    isEdited: false,
    showData: false,
    barrio: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [stopRedirect, setStopRedirect] = useState(true);
  const showAlways = true;

  useEffect(() => {
    const createInitialValues = () => {
      let fieldsTabs = [];
      fieldsProps.forEach((tab) => {
        tab.groups.forEach((group, index) => {
          fieldsTabs = [...fieldsTabs, ...group.fields];
        });
      });
      const schemaValidation = createValidationData(fieldsTabs);
      if (fieldsTabs.length > 0) {
        setInitialValues(schemaValidation.initialValues);
        setValidationSchema(
          schemaValidation.validationYupJson.validationArrayJson.reduce(
            createYupSchema,
            {}
          )
        );
        setValidationAvSchema(
          schemaValidation.validationYupJson.validationAvJson.reduce(
            createYupSchema,
            {}
          )
        );
      }
    };

    // Add field tipo_inmueble_id to group ubicacion
    if (
      fieldsProps.length > 0 &&
      fieldsProps[0].groups[0].fields.findIndex(
        (item) =>  item !== undefined && item.name === "tipo_inmueble_id"
      ) === -1
    ) {
      fieldsProps[0].groups[0].fields.push(fieldsProps[0].groups[1].fields[6]);
    }

    // Remove tipo_imnueble_id from info aptos
    if (fieldsProps.length > 0 && fieldsProps[0].groups[1]) {
      let index = fieldsProps[0].groups[1].fields.findIndex(
        (item) => item.name === "tipo_inmueble_id"
      );
      if (index !== -1) {
        fieldsProps[0].groups[1].fields.splice(index, 1);
      }
    }

    createInitialValues();
    setFields(fieldsProps);
    handleChangeAvailable("no");
  }, [fieldsProps]);

  useEffect(() => {
    if (apiConjuntoData.showData) {
      apiConjuntoData.setFieldValue(
        "nombre_upz",
        apiConjuntoData.UPZ ? apiConjuntoData.UPZ : ""
      );
      apiConjuntoData.setFieldValue(
        "barriocomun",
        apiConjuntoData.barrio ? apiConjuntoData.barrio : ""
      );
      apiConjuntoData.setFieldValue(
        "conjunto_edificio",
        apiConjuntoData.edificio ? apiConjuntoData.edificio : ""
      );
    }
    return () => {
      setIsLoading(false);
    };
  }, [apiConjuntoData.barrio]);

  useEffect(() => {
    setImages(props.images);
  }, [props.images]);

  const availableOptions = [
    { label: "Sí", value: "yes" },
    { label: "No", value: "no" },
  ];

  const handleChangeAvailable = (value) => {
    if (value === "no") {
      setShowOnAvailableApt(false);
    } else {
      setShowOnAvailableApt(true);
    }
  };

  const flagUpdateNeighborhood = (value) => {
    props.updateData({
      flagBarrio: value,
    });
  };

  const apiConjuntoDatEmpty = (setFieldValue) => {
    setApiConjuntoData({
      showData: true,
      UPZ: "",
      barrio: "",
      edificio: "",
      findData: false,
      setFieldValue: setFieldValue,
    });
    flagUpdateNeighborhood("");
  };

  const getApiConjuntosData = async (
    address,
    setFieldValue,
    ciudad,
    openAddress
  ) => {
    const apikey = process.env.REACT_APP_GEOREFERENCIACION_API_KEY;
    const rootUrl =
      process.env.REACT_APP_FORM_API_URL_V2 +
      process.env.REACT_APP_ENDPOINT_GEOREFERENCIACION_API_BASE_PATH;
    const endpointUrl =
      process.env.REACT_APP_ENDPOINT_GEOREFERENCIACION_API_GEOREFERNCIACION;
    const url = rootUrl + endpointUrl;
    setIsLoading(true);
    await axios({
      url: url,
      method: "post",
      headers: {
        "x-api-key": apikey,
        "Content-Type": "application/json",
      },
      data: `{"ciudad": "${ciudad}" ,"direccion": "${address}"}`,
    })
      .then((response) => {
        if (response.data.idconjunto) {
          props.updateData({ num_upz: response.data.cod_upz });
          setApiConjuntoData({
            ...apiConjuntoData,
            showData: true,
            findData: true,
            UPZ: response.data.upz,
            barrio: response.data.barriocomun,
            edificio: response.data.proyecto,
            setFieldValue: setFieldValue,
          });
          flagUpdateNeighborhood(response.data.barriocomun);
        } else {
          apiConjuntoDatEmpty(setFieldValue);
        }
      })
      .catch((error) => {
        apiConjuntoDatEmpty(setFieldValue);
      })
      .finally(function() {
        setIsLoading(false);
      });
  };

  const fixAddress = () => {
    setApiConjuntoData({ showData: false });
  };

  const getAvailableApt = (setFieldValue, setFieldTouched) => {
    return (
      <div className="form-group" key="available">
        <Field
          default={
            props.isUpdate
              ? { value: "true", label: "Si" }
              : { value: "false", label: "No" }
          }
          options={availableOptions}
          component={SelectComponent}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          name="available"
          id="available"
          handleChange={handleChangeAvailable}
          label="¿ Apartamentos disponibles?"
          data-id="available-apt-ventanero"
        />
      </div>
    );
  };

  /** Construye los campos  que trae el back automaticamente **/
  const getFields = (
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    fieldsTab,
    valuesFormik
  ) => {
    if (!fieldsTab) return;
    return fieldsTab.map((field) => {
      if (field) {
        if (
          field.name === "direccion" ||
          field.name === "ciudad" ||
          field.name === "url_publicacion" ||
          field.name === "fuente_id" ||
          apiConjuntoData.showData
        ) {
          if (
            field.type === "input" ||
            field.type === "inputNumber" ||
            field.type === "inputEmail"
          ) {
            const required = props.role === ROLE.VENTANERO ? (valuesFormik["fuente_id"] === "28" ? 1 : 0) : field.requerido
            return (
              (field.show_available_apt ? showOnAvailableApt : showAlways) && (
                <div key={field.name}>
                  {
                    <TextInput
                      name={field.name}
                      type={
                        field.type === "inputNumber"
                          ? "number"
                          : field.type === "inputEmail"
                          ? "email"
                          : "text"
                      }
                      errors={errors}
                      touched={touched}
                      id={field.name}
                      label={field.label}
                      disabled={field.disabled}
                      className=""
                      placeholder={field.label}
                      required={required}
                      data-id={`${field.name}-textinput-ventanero`}
                    />
                  }
                  {field.name === "conjunto_edificio" &&
                    getAvailableApt(setFieldValue, setFieldTouched)}
                </div>
              )
            );
          } else if (field.type === "inputAddress") {
            return (
              <AddressInput
                name={field.name}
                label={field.label}
                setFieldValue={setFieldValue}
                getApiConjuntosData={getApiConjuntosData}
                fields={valuesFormik}
                apiConjuntoData={apiConjuntoData}
                fixAddress={fixAddress}
                key={field.name}
                maxLengthInput={field?.max_lenght}
                data-id={`${field.name}-address-ventanero`}
              />
            );
          } else if (field.type === "inputCurrency") {
            return (
              <div className="form-group" key={field.name}>
                <Field
                  name={field.name}
                  label={field.label}
                  default={field.default}
                  maxLength={field.max_lenght}
                  component={CurrencyInput}
                  errors={errors}
                  touched={touched}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  required={field.requerido}
                  data-id={`${field.name}-currency-input-ventanero`}
                />
              </div>
            );
          } else if (field.type === "select") {
            return (
              (field.show_available_apt ? showOnAvailableApt : showAlways) && (
                <div className="form-group" key={field.name}>
                  <Field
                    default={
                      field.default === "" || field.default === null
                        ? { value: "", label: "Seleccione" }
                        : {
                            value: field.default,
                            label:
                              field.options[
                                field.select_values.indexOf(field.default)
                              ],
                          }
                    }
                    options={field.options.map((option, index) => {
                      return {
                        label: option,
                        value: field.select_values[index],
                      };
                    })}
                    component={SelectComponent}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    name={field.name}
                    id={field.name}
                    label={field.label}
                    required={field.requerido}
                    disabled={
                      field.name == "ciudad" && apiConjuntoData.showData
                        ? apiConjuntoData.showData
                        : false
                    }
                    data-id={`${field.name}-input-ventanero`}
                  />
                </div>
              )
            );
          } else if (field.type === "date") {
            return (
              <DatePickerForm
                name={field.name}
                label={field.label}
                value={field.default}
                data-id={`${field.name}-datepicker-ventanero`}
              />
            );
          } else if (field.type === "radioGroup") {
            // Role callcenter
            if (
              props.role === "callcenter" &&
              field.name === "remodelado" &&
              field.disabled
            ) {
              return false;
            }
            if (props.role === ROLE.ADMIN && field.name === "remodelado") {
              return false;
            }

            return (
              <div className="form-group" key={field.name}>
                <RadioButtonGroup
                  id={field.name}
                  label={field.label}
                  value={valuesFormik[field.name]}
                  error={errors[field.name]}
                  touched={touched[field.name]}
                  setFieldValue={setFieldValue}
                  default={field.default}
                >
                  {field.options.map((option, index) => (
                    <div
                      key={`${index}${field.name}`}
                      className={`radio-container${
                        field.columns === 1 ? " radio-container-col-one" : ""
                      } ${field.name === "fuente_id" ? "radio-container-fuente" : ""}`}
                    >
                      <Field
                        component={RadioButton}
                        name={field.name}
                        id={`toggle-${field.name}-${option}`}
                        label={option}
                        value={
                          field.select_values[index] === "None"
                            ? ""
                            : field.select_values[index]
                        }
                        default={field.default}
                        values={valuesFormik}
                        error={errors[field.name]}
                        touched={touched[field.name]}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  ))}
                </RadioButtonGroup>
              </div>
            );
          } else {
            return (
              (field.show_available_apt ? showOnAvailableApt : showAlways) && (
                <div className="form-group" key={field.name}>
                  <Field
                    type="checkbox"
                    component={Checkbox}
                    name={field.name}
                    id={field.name}
                    label={field.label}
                    data-id={`${field.name}-check-ventanero`}
                  />
                </div>
              )
            );
          }
        }
      }
    });
  };

  const getValidateAndSubmitCallback = (validateForm, submitForm, values) => {
    validateForm().then((errors) => {
      if (values["fuente_id"] === "28" && values["url_publicacion"] === "") {
        errors = {...errors, url_publicacion: 'Campo requerido'}
      }

      if (Object.keys(errors).length > 0) {
        let notification = {
          title: "No se han llenado todos los campos!",
          message:
            "Por favor revisar las fotos o todos los campos obligatorios e intente de nuevo",
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false,
          },
        };

        store.addNotification({
          ...notification,
          container: "top-left",
        });
      }
    });
  };

  return (
    <FormWrapper>
      <BlockUi tag="div" blocking={isLoading} className="tabs-container">
        <h1 className="main-title">Creación de nuevo inmueble</h1>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={
            props.isUpdate
              ? Yup.object().shape(validationSchema)
              : !showOnAvailableApt
              ? Yup.object().shape(validationAvSchema)
              : Yup.object().shape(validationSchema)
          }
          onSubmit={async (values, actions) => {
            props.handleSubmit(
              {
                ...values,
                available_apt: showOnAvailableApt,
              },
              stopRedirect
            );
            actions.setSubmitting(false);
            props.resetSelect(["num_piso"]);
            if (images.length > 0) {
              setTimeout(() => {
                actions.resetForm({
                  values: {
                    ...values,
                    telefono: "",
                    telefono_2: "",
                    torre: "",
                    num_piso: "",
                    num_apartamento: "",
                    nombre_o_inmobiliaria: "",
                  },
                });
                window.scrollTo(0, 0);
              }, 300);
            }
          }}
        >
          {(props) => (
            <Form>
              {fields[0] ? (
                <Tabs
                  defaultActiveKey={fields[0].name}
                  data-id={`tabs-${fields[0].name}`}
                >
                  {fields.map((tab, i) => (
                    <Tab eventKey={tab.name} key={i} title={tab.label}>
                      {tab.groups.map((group, indexGroup) => {
                        if (
                          (group.name === "ubicacion" || group.name === "fuente") ||
                          (apiConjuntoData.showData && showOnAvailableApt)
                        ) {
                          return (
                            <FieldsGroup key={indexGroup}>
                              <CollapsibleButton
                                id={indexGroup}
                                label={group.label}
                                collapsed={
                                  indexGroup === 0 || indexGroup === 1
                                    ? false
                                    : true
                                }
                                data-id={`${group.label}-${indexGroup}-collapse-btn-ventanero`}
                              />
                              <div
                                id={`collapse-${indexGroup}`}
                                className={`collapse ${
                                  indexGroup === 0 || indexGroup === 1
                                    ? "show"
                                    : ""
                                }`}
                              >
                                <FieldsContainer
                                  className={`column-${group.col}`}
                                  data-id={`${group.col}-field-container`}
                                >
                                  {getFields(
                                    props.errors,
                                    props.touched,
                                    props.setFieldValue,
                                    props.setFieldTouched,
                                    group.fields,
                                    props.values
                                  )}
                                </FieldsContainer>
                              </div>
                            </FieldsGroup>
                          );
                        }
                      })}

                      {apiConjuntoData.showData && (
                        <div className="form-group buttonContainer">
                          {reqFailed && (
                            <>
                              <Button
                                type="button"
                                typeClass="secondary"
                                label="Re-intentar registro fallido"
                                className="w-100 button-send"
                                onClick={reSendPost}
                                id="retry-btn-ventanero"
                              />
                              <br />
                            </>
                          )}
                          <Button
                            typeClass="primary"
                            type="submit"
                            label="Enviar y registrar otro"
                            className="w-100 button-send"
                            onClick={() => {
                              getValidateAndSubmitCallback(
                                props.validateForm,
                                props.submitForm,
                                props.values,
                              );
                              setStopRedirect(true);
                            }}
                            id="save-and-add-btn-ventanero"
                          />
                          <br />
                          <Button
                            id="send"
                            typeClass="primary"
                            type="submit"
                            className="w-100 button-send"
                            label="Enviar y cerrar"
                            onClick={() => {
                              getValidateAndSubmitCallback(
                                props.validateForm,
                                props.submitForm,
                                props.values,
                              );
                              setStopRedirect(false);
                            }}
                            data-id="save-btn-ventanero"
                          />
                        </div>
                      )}
                    </Tab>
                  ))}
                  <Tab eventKey="photo" title="Fotos">
                    <CollapsibleButton
                      id="photos"
                      label="Fotos"
                      data-id="photos-collapse-btn-ventanero"
                    />
                    <div
                      id="collapse-photos"
                      className="collapse show"
                      data-id="collapse-photos-container"
                    >
                      <ImagesApartment images={images} />
                    </div>
                  </Tab>
                </Tabs>
              ) : null}
            </Form>
          )}
        </Formik>
      </BlockUi>
      <FormNotification>
        <ReactNotification />
      </FormNotification>
    </FormWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateData: (data) => dispatch(updateData(data)),
  resetSelect: (payload) => dispatch(resetSelect(payload)),
});

export default connect(null, mapDispatchToProps)(FormStreetVentanero);
