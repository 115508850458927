import React, { useState, useEffect } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { DropImagesAreaWrapper } from "./style";
import ModalFilesName from "../ModalFilesName/modalFilesName";
import GalleryContainer from "../Gallery/galleryContainer";
import Viewer from "../Viewer/viewer";
import {
  addImageAction,
  removeImageAction,
} from "../../redux/actions/ventanerosImages.actions";
import axios from "axios";
import { Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const apikey = process.env.REACT_APP_FORMS_API_KEY;
const rootUrl =
  process.env.REACT_APP_FORM_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH;
const endpointUrl =
  process.env.REACT_APP_ENDPOINT_FORM_API_DELETE_PROPERTY_IMAGES;
const url = rootUrl + endpointUrl;

const DropImagesAreaContainer = (props) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newImages, setNewImages] = useState(false);
  const [files, setFiles] = useState([]);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [imagesToShow, setImagesToShow] = useState([]);

  const [snackMessage, setSnackMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  useEffect(() => {
    props.images.forEach((image) => {
      image["src"] = image.img;
      image["width"] = 300;
      image["height"] = 300;
    });
    setImagesToShow(props.images);
  }, []);

  useEffect(() => {
    if (imagesToDelete.length > 0) {
      setSelectAll(false);
    }
    if (newImages) {
      setNewImages(false);
    }
  }, [JSON.stringify(imagesToShow)]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
  };
  const handleSelect = (img, select, index) => {
    let deleteImages = imagesToDelete;
    if (!select) {
      deleteImages.push({
        src: img.src,
        index: index,
        type: img.type,
        id: img.id,
      });
    } else {
      const ind = deleteImages
        .map((e) => {
          return e.src;
        })
        .indexOf(img.src);
      deleteImages.splice(ind, 1);
    }
    setImagesToDelete(deleteImages);
  };

  const openLightboxButton = (index) => {
    if (imagesToShow.length > 0) {
      setCurrentImage(index);
      setViewerIsOpen(true);
    } else {
      setSnackMessage("No hay imagenes para mostrar");
      setOpenSnack(true);
    }
  };
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSave = async (files) => {
    let images = imagesToShow;
    for (let index = 0; index < files.length; index++) {
      const imagePreviewUrl = await processFile(files[index]);
      props.addImage({
        img: imagePreviewUrl,
        type: "new",
        extension: files[index].type.split("/")[1],
        description: files[index].newName,
      });
      images.push({
        src: imagePreviewUrl,
        width: 300,
        height: 300,
        type: "new",
      });
    }
    setNewImages(true);
    setImagesToShow(images);
    setOpen(false);
  };
  const handleName = (items) => {
    setOpenModal(true);
    setFiles(items);
  };
  const handleNameDone = (filesWithNewName) => {
    for (let index = 0; index < files.length; index++) {
      if (files[index].name === filesWithNewName[index].name) {
        files[index]["newName"] = filesWithNewName[index].newName;
      }
    }
  };
  const deleteImages = () => {
    let imagesToDel = [...imagesToShow];
    let images = [...imagesToShow];
    let imagesToErase = imagesToDel.filter((img) => {
      for (let index = 0; index < imagesToDelete.length; index++) {
        if (imagesToDelete[index].src === img.src) {
          const ind = images
            .map((e) => {
              return e.src;
            })
            .indexOf(img.src);
          images.splice(ind, 1);
        }
      }
    });
    setImagesToShow(images);
    imagesToDelete.forEach((img) => {
      if (img.type === "update") {
        deleteImageService(img.id);
      }
    });
    setImagesToDelete([]);
    setSelectAll(false);
  };
  const deleteImageService = (id) => {
    const body = { id: id };
    axios({
      url: url,
      method: "post",
      headers: {
        "x-api-key": apikey,
        "Content-Type": "application/json",
      },
      data: body,
    })
      .then((response) => {
        setSnackMessage("Las imagenes se borraron correctamente");
        setOpenSnack(true);
      })
      .catch((error) => {
        console.log("error image");
      })
      .finally(function() {
        console.log("imagen borrada");
      });
  };

  async function processFile(file) {
    try {
      let contentBuffer = await readFileAsync(file);
      return contentBuffer;
    } catch (err) {
      console.log(err);
    }
  }

  async function readFileAsync(file) {
    return await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  return (
    <DropImagesAreaWrapper>
      <div className="drop-area-buttons">
        <Button
          id="btn-add-img"
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Agregar Imagen
        </Button>
        <Button
          id="btn-show-img"
          variant="contained"
          color="primary"
          onClick={() => openLightboxButton(0)}
        >
          Ver Imagenes
        </Button>
        <Button
          id="btn-delete-img"
          variant="contained"
          color="primary"
          onClick={deleteImages}
        >
          Borrar imagenes seleccionadas
        </Button>
      </div>
      <div className="drop-gallery">
        <GalleryContainer
          id="gallery-img-container"
          imgs={imagesToShow}
          handleSelect={handleSelect}
          viewerIsOpen={viewerIsOpen}
          selected={selectAll}
        />
        <Viewer
          id="viewer-img-box"
          imgs={imagesToShow}
          viewerIsOpen={viewerIsOpen}
          currentImage={currentImage}
          closeLightbox={closeLightbox}
        />
      </div>
      <DropzoneDialog
        id="dropzone-dialog-img"
        acceptedFiles={["image/*"]}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          handleSave(files);
          setOpen(false);
        }}
        showPreviews={false}
        showFileNamesInPreview={false}
        showPreviewsInDropzone={true}
        filesLimit={20}
        onDrop={(e) => {
          handleName(e);
        }}
        cancelButtonText={"Cancelar"}
        submitButtonText={"Subir imagenes"}
      />
      {openModal && (
        <ModalFilesName
          id="modal-img-file-name"
          open={openModal}
          files={files}
          handleCloseModal={handleCloseModal}
          handleNameDone={handleNameDone}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
            id="snackbar-close-icon-drop-img"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </DropImagesAreaWrapper>
  );
};

const mapStateToProps = (state) => ({
  imagesRedux: state.ventanerosImages,
});

const mapDispatchToProps = (dispatch) => ({
  addImage: (image) => dispatch(addImageAction(image)),
  removeImage: (image) => dispatch(removeImageAction(image)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropImagesAreaContainer);
