export function createValidationData(fields) {

  let initialValuesObj = {};
  let validationArrayJson = [];
  let validationAvJson = [];
  fields.forEach((field) => {
    if (field) {
      initialValuesObj = Object.assign(
        {},
        {
          [field.name]: field.default,
        },
        initialValuesObj
      );
      if (field.type !== "radio") {
        const validation = getValidationJsonField(field);
        validationArrayJson.push(validation);
        if (!field.show_available_apt) {
          validationAvJson.push(validation);
        }
      }
    }
  });

  const validationSchema = {
    initialValues: initialValuesObj,
    validationYupJson: {
      validationAvJson,
      validationArrayJson,
    },
  };

  return validationSchema;
}

export function getValidationJsonField(field) {

  return {
    id: field.name,
    validationType:
      field.type === "inputCurrency" || field.type === "inputNumber"
        ? "number"
        : "string",
    validations: [
      {
        type: field.requerido ? "required" : null,
        params: ["Campo requerido"],
      },
      {
        type: field.type === "inputCurrency" && field.requerido && field.min === null ? "test" : null,
        params: [
          "test-currency",
          `El campo es requerido con un valor mayor a 0`,
          function(value) {
            if (
              value === undefined ||
              value === 0 ||
              value === "" ||
              value === "$0"
            ) {
              return false;
            }
            return true;
          },
        ],
      },
      {
        type:
          (field.type === "inputCurrency" || field.type === "inputNumber") && field.min === null
            ? "min"
            : null,
        params: [0, `El campo debe ser mayor a cero`],
      },
      {
        type:
          (field.type === "inputCurrency" || field.type === "inputNumber") && field.min !== null
            ? "min"
            : null,
        params: [field.min, `El campo debe ser mayor a ${field.min}`],
      },
      {
        type:
          (field.type === "inputCurrency" || field.type === "inputNumber") && field.max !== null
            ? "max"
            : null,
        params: [field.max, `El campo debe ser menor a ${field.max}`],
      },
      {
        type:
          field.min_lenght &&
          (field.type === "inputCurrency" || field.type === "inputNumber")
            ? "test"
            : null,
        params: [
          "number-min",
          `El campo debe tener mínimo ${field.min_lenght} caracter(es)`,
          function(value) {
            if (value) {
              if (value.toString().length < field.min_lenght) {
                return false;
              }
            }

            return true;
          },
        ],
      },
      {
        type:
          field.max_lenght &&
          (field.type === "inputCurrency" || field.type === "inputNumber")
            ? "test"
            : null,
        params: [
          "number-max",
          `El campo debe tener máximo ${field.max_lenght} caracter(es)`,
          function(value) {
            if (value) {
              if (value.toString().length > field.max_lenght) {
                return false;
              }
            }
            return true;
          },
        ],
      },
      {
        type:
          field.min_lenght &&
          field.type !== "inputCurrency" &&
          field.type !== "inputNumber"
            ? "min"
            : null,
        params: [
          field.min_lenght,
          `El campo debe tener mínimo ${field.min_lenght} caracter(es)`,
        ],
      },
      {
        type:
          field.max_lenght &&
          field.type !== "inputCurrency" &&
          field.type !== "inputNumber"
            ? "max"
            : null,
        params: [
          field.max_lenght,
          `El campo debe tener máximo ${field.max_lenght} caracter(es)`,
        ],
      },
      {
        type: field.type === "inputEmail" ? "email" : null,
        params: [`Email invalido`],
      },
    ],
  };
}
