export const notFoundAction = {
    SET_NOT_FOUND: "SET_NOT_FOUND",
    REMOVE_NOT_FOUND: "REMOVE_NOT_FOUND",
  };
  
  export const setNotFoundAction = (notFound) => ({
    type: notFoundAction.SET_NOT_FOUND,
    payload: notFound,
  });
  
  export const removeNotFoundAction = () => ({
    type: notFoundAction.REMOVE_NOT_FOUND,
  });
  