import styled from "styled-components";
import {
  size,
  black,
  purple,
  white,
  successTurquoise,
  error,
} from "../../StylesConstants";

export const Title = styled.div`
  margin: 0 15px;
  display: grid;
  grid-template-columns: 3fr 50px;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: ${black};
  }

  .button-mobile {
    display: grid;
    background-color: ${purple};
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: ${white};
    align-self: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    justify-self: center;

    span {
      margin: auto;
      text-align: center;
    }
  }
`;

export const ListApartmentsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-items: center;

  .container-habi-form {
    display: grid;
    grid-template-areas:
      "button button"
      "content content"
      "paginator paginator";
    width: 90%;
  }

  .new-button {
    grid-area: button;
    justify-self: end;
    margin: 15px;
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    grid-column-gap: 10px;
  }

  .title {
    margin: 15px 15px;
    grid-area: title;
    color: #2d2d2d;
  }

  .filters {
    grid-area: filters;
  }

  .table-container {
    grid-area: content;
    margin: 15px;
  }

  .update {
    button {
      background: none;
      border: none;
      width: 100%;
    }
  }

  .paginator-container {
    grid-area: paginator;
  }

  .pill-green {
    width: 51px;
    height: 28px;
    background: ${successTurquoise};
    border-radius: 14px;
    color: ${white};
    position: absolute;
    text-align: center;
    font-weight: 600;
  }

  .pill-red {
    width: 51px;
    height: 28px;
    background: ${error};
    border-radius: 14px;
    color: ${white};
    position: absolute;
    text-align: center;
    font-weight: 600;
  }

  @media screen and (min-width: ${size.desktop}) {
    .container-habi-form {
      display: grid;
      grid-template-areas:
        "title button"
        "content content"
        "paginator paginator";
      width: 90%;
    }

    .title {
      margin: 15px 15px;
      grid-area: title;
      color: #2d2d2d;
    }
  }

  @media screen and (max-width: 767px) {
    .table-striped tbody tr:nth-of-type(odd) {
      background-color: #ffffff;
    }
    .table td,
    .table th {
      border-top: none;
    }
  }
`;
