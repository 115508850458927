import styled from "styled-components";
import {
  white,
  scarpaGray600,
  electricPurple600,
  neutralPrimary50,
  plainlightGray,
  montserratBoldFamily,
  archiaRegular,
  nightBlue400,
  electricPurple500,
  hintPerano100,
  muliBoldFamily,
  scarpaGray000,
} from "../../StylesConstants";

export const LoginWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  width: 100vw;
  height: 100vh;
  background-color: ${scarpaGray000};
  align-items: center;

  .decoration {
    display: grid;
    grid-template-columns: 200px 200px;
    height: 100vh;
    position: absolute;
    width: 100vw;
    justify-content: space-between;
    img:nth-child(2n) {
      align-self: end;
    }
  }

  .content {
    grid-column: 5 / span 4;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    .login-container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      row-gap: ${({ chooseRole }) => (chooseRole ? "1rem" : "3rem")};
      border-radius: 1.25rem;
      background-color: ${white};
      z-index: 2;
      width: 24rem;
      height: 38rem;
      box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
        0px 4px 8px rgba(48, 49, 51, 0.1);

      .title {
        color: ${electricPurple600};
        font: 2.25rem ${montserratBoldFamily};
        margin: 0;
        line-height: 2rem;
        letter-spacing: 0.02em;
      }

      .logo-img {
        width: 3.5rem;
      }

      .role-buttons {
        .question {
          color: ${nightBlue400};
          font-family: ${archiaRegular};
          font-size: 16px;
          line-height: 24px;
        }
        .role {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          align-content: center;
          column-gap: 8px;
          cursor: pointer;
          border: 1px solid ${electricPurple500};
          box-sizing: border-box;
          border-radius: 8px;
          color: ${electricPurple500};
          padding: 8px 12px;
          margin-bottom: 16px;
          &:hover {
            background-color: ${hintPerano100};
          }
          span {
            font-family: ${muliBoldFamily};
            font-size: 1rem;
          }
        }
      }
    }
  }

  .habi-link {
    font: 15px ${archiaRegular};
    text-decoration: none;
    color: ${neutralPrimary50};
    margin-bottom: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    :hover {
      text-decoration: underline;
    }
    position: absolute;
    bottom: 0;
    margin-left: -3.375rem;
  }

  .google-button-container {
    border-radius: 0.5rem !important;
    width: 16rem;
    height: 3rem;
  }

  .google-button button {
    display: inline-flex !important;
    justify-content: center !important;
    align-content: center !important;
    border: 1px solid ${plainlightGray} !important;
    border-radius: 0.5rem !important;
    box-shadow: none !important;
    font-size: 1rem !important;
    color: ${scarpaGray600} !important;
    width: 16rem;
    height: 3rem;
  }
`;
