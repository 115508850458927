import React, { useState, useEffect } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const MapMobile = (props) => {
  const [lat, setLat] = useState(props.centerMap.lat);
  const [lng, setLng] = useState(props.centerMap.lng);
  const [width, setWidth] = useState("80vw");
  const [height, setHeight] = useState("60vh");
  const [center, setCenter] = useState();
  useEffect(() => {
    if (props.isMobile) {
      setWidth("90vw");
      setHeight("40vh");
    }
    setCenter({ lat: lat, lng: lng });
  }, []);
  const onMarkerDragEnd = (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setLat(lat);
    setLng(lng);
    setCenter({ lat: lat, lng: lng });

    const data = {
      latitud: lat,
      longitud: lng,
    };
    props.getAddressPin(lat, lng);
  };

  const containerStyle = {
    position: "relative",
    width: width,
    height: height,
  };
  var icon = {
    url:
      "https://cms-images-storages.s3.us-east-2.amazonaws.com/marker-habi-4x.png",
    scaledSize: new props.google.maps.Size(72, 72),
  };
  return (
    <Map
      google={props.google}
      containerStyle={containerStyle}
      zoom={17}
      center={center}
      zoomControl={false}
      fullscreenControl={false}
      streetViewControl={false}
      mapTypeControl={false}
    >
      <Marker
        title="¿es esta la ubicación correcta?"
        id={1}
        position={{ lat: lat, lng: lng }}
        draggable={true}
        onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
        icon={icon}
      />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
})(MapMobile);
