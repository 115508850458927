import styled from "styled-components";
import { size, coral, purple } from "../../StylesConstants";

export const ImagesWrapper = styled.div`
  .button-container {
    button {
      margin-bottom: 30px;
      width: 100%;
    }
  }

  .image-not-delete {
    opacity: 0.5;
  }

  .btn-delete {
    width: 100%;
  }

  .icon-trash {
    color: white;
  }

  .image-delete {
    img {
      border: 4px solid ${purple};
      box-sizing: border-box;
      border-radius: 40px;
    }
  }

  .img-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    img {
      object-fit: cover;
      width: 140px;
      height: 140px;
      border-radius: 40px;
      justify-self: center;
    }

    .description {
      text-align: center;
      font-style: italic;
    }
  }

  .gallery-img {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
    color: ${coral};
    align-self: center;
    cursor: pointer;
    justify-self: center;
  }

  @media screen and (min-width: ${size.tablet}) {
    .MuiSvgIcon-root {
      font-size: 3rem;
    }

    .button-container {
      .btn-add-square {
        margin-bottom: 30px;
        width: 100%;
        height: 100%;
        border: 2px dashed #b8b8b8;
        box-sizing: border-box;
        border-radius: 32px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        align-items: center;
        text-align: center;
        color: #b8b8b8;
      }
    }

    .gallery-img {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
    }

    .img-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
      max-height: 250px;

      img {
        object-fit: cover;
        width: 180px;
        height: 180px;
      }
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .btn-delete {
      width: auto;
    }

    .text-title {
      font-size: 2.5rem;
    }

    .MuiSvgIcon-root {
      font-size: 3rem;
      align-self: auto;
      justify-self: auto;
    }

    .gallery-img {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 30px;
    }

    .img-container {
      grid-gap: 10px;
      max-height: 300px;

      img {
        object-fit: cover;
        width: 200px;
        height: 200px;
      }
    }
  }
`;
