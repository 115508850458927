import React, { Fragment, useState } from "react";
import { LoginWrapper } from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateData } from "../../redux/actions/formData";
import logo from "../../static/assets/logo/habi.svg";
import GoogleLogin from "react-google-login";
import PortraitIcon from "@material-ui/icons/Portrait";
import loginDecorationStart from "../../static/assets/img/login/login-decoration-start.svg";
import loginDecorationEnd from "../../static/assets/img/login/login-decoration-end.svg";
import { availableRoles } from "./login.utils";

const LogIn = (props) => {
  const [chooseRole, setChooseRole] = useState(true);
  const [roleName, setRoleName] = useState("");

  const handleClick = (role) => {
    if (role === "new_rotacion") {
      window.location.href = process.env.REACT_APP_NEW_ROTATION;
      return;
    }
    setChooseRole(false);
    setRoleName(role);
  };

  const onRejectLogin = (response) => {
    props.history.push("/");
  };

  const onSuccessLogin = (response) => {
    const {
      googleId,
      email,
      givenName,
      familyName,
      imageUrl,
    } = response.profileObj;
    const habiDomains = ["habi.co", "tuhabi.mx"];

    if (googleId) {
      //Check email domain
      const domain = email.split("@")[1];
      let external = 1;
      if (habiDomains.find((item) => item === domain)) {
        external = 0;
      }

      const data = { userId: googleId, external, country: "CO" };
      const apikey = process.env.REACT_APP_FORMS_API_KEY_V2;
      const rootUrl = process.env.REACT_APP_FORM_API_URL_V3;
      const endpointUrl =
        process.env.REACT_APP_ENDPOINT_API_POST_VALIDATE_COGNITO_USER_ROLE;
      const url = rootUrl + endpointUrl;
      fetch(url, {
        method: "POST",
        headers: {
          "x-api-key": apikey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success && response.role.includes(roleName)) {
            props.updateData({
              role: roleName,
              email: email,
              name: givenName,
              lastName: familyName,
              imageProfile: imageUrl,
              authenticated: true,
              userId: googleId,
            });
            props.history.push("/dashboard");
          } else {
            setChooseRole(true);
          }
        });
    }
  };

  return (
    <Fragment>
      <LoginWrapper chooseRole={chooseRole}>
        <div className="decoration">
          <img src={loginDecorationStart} alt="Habi decoration" />
          <img src={loginDecorationEnd} alt="Habi decoration" />
        </div>
        <div className="content">
          <div className="login-container">
            <img src={logo} className="logo-img" alt="logo-img" />
            <p className="title">Hermes</p>
            {chooseRole && (
              <div className="role-buttons">
                <p className="question">¿Con cuál rol deseas ingresar?</p>
                {Object.keys(availableRoles).map((item, idx) => (
                  <div
                    key={`role-${idx}`}
                    className="role"
                    onClick={() => handleClick(availableRoles[item])}
                  >
                    <PortraitIcon />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            )}
            {!chooseRole && (
              <div className="pass-button">
                <GoogleLogin
                  clientId="983955624663-b5s9sj7i87aqbqpj8t390vhmh143cjad.apps.googleusercontent.com"
                  buttonText="Iniciar Sesión"
                  onSuccess={onSuccessLogin}
                  onFailure={onRejectLogin}
                  cookiePolicy={"single_host_origin"}
                />
              </div>
            )}
          </div>
        </div>
      </LoginWrapper>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  role: state.formData.role,
  name: state.formData.name,
  lastName: state.formData.lastName,
  email: state.formData.email,
  imageProfile: state.formData.imageProfile,
});
const mapDispatchToProps = (dispatch) => ({
  updateData: (data) => dispatch(updateData(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));
