export const VentanerosImagesActionTypes = {
  ADD_IMAGE: "ADD_IMAGE",
  SET_IMAGES: "SET_IMAGES",
  REMOVE_IMAGE: "REMOVE_IMAGE",
  CLEAR_IMAGES: "CLEAR_IMAGES",
};

export const setImagesAction = (payload) => ({
  type: VentanerosImagesActionTypes.SET_IMAGES,
  payload,
});

export const addImageAction = (payload) => ({
  type: VentanerosImagesActionTypes.ADD_IMAGE,
  payload,
});

export const removeImageAction = (payload) => ({
  type: VentanerosImagesActionTypes.REMOVE_IMAGE,
  payload,
});

export const clearImagesAction = () => ({
  type: VentanerosImagesActionTypes.CLEAR_IMAGES,
});
