import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { PaginatorWrapper } from "./style";
import { isMobile } from "react-device-detect";

const Paginator = (props) => {
  const [state, setState] = useState({
    currentPage: 1,
    totalItemsCount: 100,
    itemsCountPerPage: 10,
  });

  useEffect(() => {
    setState(props.paginator);
  }, [props.paginator]);

  const handlePageChange = (pageNumber) => {
    setState({ ...state, currentPage: pageNumber });
    props.handlePageChange(pageNumber);
  };

  return (
    <PaginatorWrapper className="text-center" id="paginator">
      <Pagination
        activePage={state.currentPage}
        itemsCountPerPage={state.itemsCountPerPage}
        totalItemsCount={state.totalItemsCount}
        pageRangeDisplayed={isMobile ? 3 : 5}
        onChange={handlePageChange}
      />
    </PaginatorWrapper>
  );
};

export default Paginator;
