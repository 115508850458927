import styled from "styled-components";
import {
  error,
  grey,
  size,
  light_purple,
  purple
} from "../../../StylesConstants";

export const SelectWrapper = styled.div`
  margin: 0;

  .input-feedback {
    color: ${error};
    padding-left: 32px;
  }

  .select-input-hidden {
    opacity: 0;
    width: 1px;
    height: 1px;
    option {
      display: none;
    }
  }

  .form-control {
    padding: 15px;
  }

  .input-feedback {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: ${error};
  }

  .select-mobile {
    border: 2px solid ${grey};
    box-sizing: border-box;
    border-radius: 100px;
    width: 100%;
    padding: 15px 32px;
    height: 56px;
    background-image: linear-gradient(45deg, transparent 50%, ${purple} 50%),
      linear-gradient(135deg, ${purple} 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;

    &:disabled {
      font-size: 16px;
      line-height: 19px;
      background: rgba(170, 167, 252, 0.2);
      border: 2px solid ${light_purple};
      box-sizing: border-box;
      border-radius: 100px;
      color: ${light_purple};
    }

    &.error-select {
      font-size: 16px;
      line-height: 19px;
      background: rgba(255, 0, 0, 0.1);
      border: 2px solid ${error};
      box-sizing: border-box;
      border-radius: 100px;
      color: ${error};
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    margin: 0 15px;
  }
`;
