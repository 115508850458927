import styled from "styled-components";
import {Fragment} from 'react'
import {
  size,
  black,
  purple,
  light_purple,
  coral,
  white,
} from "../../StylesConstants";

export const FormWrapper = styled.div`
  .top-container {
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    .buttons-save {
      display: grid;
      grid-template: 1fr / 1fr 1fr 1fr;
      #cancel {
        justify-self: end;
      }
    }
    .button-validate {
      background-color: ${coral};
      color: ${white};
    }
  }
`;

export const FormNotification = styled.div`
  position: fixed;
  top: 0;
  left: calc(50% - 150px);
  width: auto;
  height: auto;
`;

export const FormStreetNotification = styled.div`
  position: absolute;
  top: 0;
  height: 40;
  width: 150; left: 0;
`;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
`;

export const FormsContainerWrapper = styled.div`
  .nav-tabs {
    margin-bottom: 30px;
  }

  .tabs-container {
    margin: 15px;
  }

  .buttonContainer {
    margin-top: 32px;
    width: 100%;
  }

  .button-send {
    button {
      width: 100%;
    }
  }

  .main-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: ${black};
    margin-bottom: 16px;
  }

  .nav-tabs {
    border: none;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${purple};
    border: none;
    border-bottom: 2px solid ${purple};
  }

  .nav-tabs .nav-link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${black};
  }

  .nav-tabs .nav-link:focus {
    border-bottom: 2px solid ${purple};
  }

  .nav-tabs .nav-link:hover {
    border-bottom: 2px solid ${purple};
  }

  @media screen and (min-width: ${size.tablet}) {
    .buttonContainer {
      width: fit-content;
      margin: 16px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .tabs-container {
      margin: 40px;
    }

    .main-title {
      font-size: 32px;
      color: ${black};
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .main-title {
      font-size: 48px;
      line-height: 58px;
      color: ${black};
      margin-bottom: 42px;
    }

    .nav-tabs .nav-link {
      font-size: 22px;
      line-height: 32px;
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
      font-size: 22px;
      line-height: 32px;
    }

    .nav-tabs .nav-link:hover {
      border-bottom: 2px solid ${light_purple};
    }
  }
`;

export const FieldsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin-top: 2%;
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin-bottom: 1rem;

  .checkbox {
    align-self: center;
  }

  @media screen and (min-width: ${size.desktop}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    width: 60%;
    margin: 0 auto;

    &.column-2 {
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
      width: 100%;
    }

    &.column-3 {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 15px;
      width: 100%;
    }

    &.column-4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 15px;
      width: 100%;
    }
    .checkbox-form-group {
      align-self: center;
      margin-top: 5%;
    }
  }
`;

export const CallWrapper = styled.div`
  width: 248px;
  height: fit-content;
  background: #e9ecef;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin: 60px;
  justify-content: center;

  .phone-call {
    padding: 16px;
  }
  .button-call {
    border: none;
    color: #ffffff;
    background-color: #7c01ff;
    margin: 16px;
  }

  .button-hangUp {
    border: 2px solid #dddddd;
    box-sizing: border-box;
    color: #2d2d2d;
    background-color: #ffffff;
    margin: 16px;
  }
  .response-call {
    padding: 16px;
  }
  .habi-phone-keyboard {
    .rc-phone-keyboard-keyboard-col-circle {
      border: 3px solid ${purple} !important;
    }
    .rc-phone-keyboard {
      color: ${purple} !important;
    }
  }
`;

export const HabiKeyboardWrapper = styled.section`
  display: grid;
  grid-template: 1fr 1fr 1fr 1fr / 1fr 1fr 1fr;
  grid-gap: 20px;
  .MuiButtonBase-root {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    font-size: 30px;
  }
  .MuiButton-outlined {
    border: 1px solid ${purple};
  }
  .MuiButton-root {
    color: ${purple};
    outline: none;
  }
  .MuiButton-root:hover {
    background-color: #d7d5ff;
  }
`;

export const SourceContainer = styled.div`
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 400;
  font-size: 16px;
  .source-label {
    margin-bottom: 5px;
  }
  button {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
  }
  width: 100%;
`;