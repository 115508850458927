import { VentanerosImagesActionTypes } from "../actions/ventanerosImages.actions";

const INITIAL_STATE = [];

const ventanerosImages = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case VentanerosImagesActionTypes.SET_IMAGES: {
      return [...payload];
    }
    case VentanerosImagesActionTypes.ADD_IMAGE: {
      return [payload, ...state];
    }
    case VentanerosImagesActionTypes.REMOVE_IMAGE: {
      return state.filter((image, i) => i !== payload);
    }
    case VentanerosImagesActionTypes.CLEAR_IMAGES: {
      return [];
    }
    default:
      return state;
  }
};

export default ventanerosImages;
