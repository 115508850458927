import styled from "styled-components";
import { size } from "../../StylesConstants";

export const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 15px;

  .btn-container {
    align-self: center;
    margin: 0 15px;
  }
  .input-filter {
    label {
      margin-left: 10%;
    }
  }

  .search-btn {
    background: #7c01ff;
    border-radius: 100px;
    color: white;
    border: none;
    padding: 10px 32px;
    height: 45px;
    width: 100%;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: center;
    justify-self: auto;
  }

  .clean-btn {
    border: 2px solid #dddddd;
    box-sizing: border-box;
    background-color: white;
    border-radius: 100px;
    padding: 10px 32px;
    height: 45px;
    width: 100%;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: center;
    justify-self: auto;
    margin-bottom: 15px;
  }

  .react-datepicker__input-container {
    input {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .search-btn {
      width: 45%;
      display: inline-block;
    }

    .clean-btn {
      width: 45%;
      margin: 0 15px;
      display: inline-block;
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    &.role-rotation {
      grid-template-columns: 1fr 305px;
      .input-filter-right {
        max-width: 300px;
        justify-self: end;
      }
    }
  }
`;
export const SectionFilters = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;

  @media screen and (min-width: ${size.tablet}) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (min-width: ${size.desktop}) {
    grid-template-columns: repeat(6, 1fr);
  }
`;
export const ActionFilters = styled.section`
  display: inline-flex;
  justify-content: center;
`;

export const FiltersContainer = styled.section`
  @media screen and (min-width: ${size.desktop}) {
    position: absolute;
    background-color: white;
    top: 12%;
    width: 98%;
    left: 1%;
    border-radius: 80px;
    padding: 16px 8px;
  }
`;
