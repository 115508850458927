import styled from "styled-components";
import { size } from "../../../StylesConstants";

export const PhotoPickerWrapper = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;

  .button-container {
    button {
      margin: 15px auto;
    }
  }

  .form-photo {
    margin: auto;
    max-width: 500px;
  }

  .description-photo {
    margin-top: 30px;
  }

  .file-container {
    padding: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    justify-self: center;
    border: 2px dashed #b8b8b8;
    box-sizing: border-box;
    border-radius: 32px;

    input {
      width: 100%;
    }
  }

  .image-container {
    font-size: 16px;
    margin: 15px 0px;
  }

  .preview-img {
    width: 200px;
  }

  @media screen and (min-width: ${size.desktop}) {
    .text-title {
      font-size: 2.5rem;
    }

    .file-container {
      padding: 50px;
      margin: 0;
      width: 100%;
    }

    .preview-img {
      width: 500px;
    }
  }
`;
