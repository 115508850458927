import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { CallWrapper, HabiKeyboardWrapper } from "./style";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import useKeypress from "../Hooks/useKeypress";
const Device = require("twilio-client").Device;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "auto",
  };
}
const myRe = /^[0-9]$/;
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "auto",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
    overflow: "auto",
    borderRadius: "20px",
  },
}));

const FormCall = ({ telefono, user, updateButtonSave, nid, role }) => {
  const classes = useStyles();
  const [callStatus, setCallStatus] = useState("Cargando");
  const [disabledCallButton, setDisabledCallButton] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const apikey = process.env.REACT_APP_HABI_TWILIO_API_KEY;
  const rootUrl =
    process.env.REACT_APP_FORM_API_URL_V2 +
    process.env.REACT_APP_ENDPOINT_HABI_TWILIO_API_BASE_PATH;
  const endpointUrl = process.env.REACT_APP_ENDPOINT_HABI_TWILIO_API_GET_TOKEN;
  const url = rootUrl + endpointUrl;

  useEffect(() => {
    Device.destroy();
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let token = data.token;
        Device.setup(data.token, { debug: true });
      });
  }, []);

  useEffect(() => {
    function onKeyup(e) {
      const isNumber = myRe.exec(e.key);
      if (isNumber !== null && isNumber.length > 0) sendDigits(`w${e.key}`);
    }
    window.addEventListener("keyup", onKeyup);
    return () => window.removeEventListener("keyup", onKeyup);
  }, [openModal]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const callCustomer = (phoneNumber, user, nid) => {
    let number = validatePhone(phoneNumber);
    if (number !== null) {
      const params = { to_phone: number, agent: user, nid: nid, role: role };
      Device.connect(params);
    } else {
      setCallStatus("Telefono Dañado");
    }
  };

  const hangUp = () => {
    Device.disconnectAll();
  };

  const openIVR = () => {
    setOpenModal(true);
    setOpen("open");
  };

  const validatePhone = (phoneNumber) => {
    let specialChars = "!@#$^&%*()=-[]/{}|:<>?,.-_ ";
    for (var i = 0; i < specialChars.length; i++) {
      phoneNumber = phoneNumber
        .toString()
        .replace(new RegExp("\\" + specialChars[i], "gi"), "");
    }
    phoneNumber = phoneNumber.trim();
    if (phoneNumber !== "/^[0-9+]{10,13}$/") {
      let firstCharacter = phoneNumber.startsWith("3");
      if (firstCharacter && phoneNumber.length === 10) {
        phoneNumber = "+57" + phoneNumber;
      } else {
        let firstCharacter = phoneNumber.startsWith("7");
        if (firstCharacter && phoneNumber.length === 11) {
          phoneNumber = "+5" + phoneNumber;
        } else {
          let firstCharacter = phoneNumber.startsWith("5");
          if (firstCharacter && phoneNumber.length === 12) {
            phoneNumber = "+" + phoneNumber;
          }
        }
      }
      return phoneNumber;
    } else {
      return null;
    }
  };

  /* Callback to let us know Twilio Client is ready */
  Device.on("ready", function(device) {
    setDisabledCallButton(false);
    setCallStatus("Ready");
  });

  Device.on("error", function(error) {
    setDisabledCallButton(true);
    updateButtonSave(false);
    setCallStatus("ERROR: " + error.message);
  });

  Device.on("disconnect", function(connection) {
    updateButtonSave(false);
    setDisabledCallButton(false);
    // Disable the hangup button and enable the call buttons
    setCallStatus("Llamada finalizada!");
  });

  Device.on("connect", function(connection) {
    updateButtonSave(true);
    setDisabledCallButton(true);
    setCallStatus("En llamada con " + connection.message.to_phone);
  });

  const sendDigits = (number) => {
    if (openModal) {
      const TwilConn = Device.activeConnection();
      TwilConn.sendDigits(number);
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <HabiKeyboardWrapper>
        <Button
          data-id="btn-call-1"
          variant="outlined"
          onClick={() => sendDigits("w1")}
        >
          1
        </Button>
        <Button
          data-id="btn-call-2"
          variant="outlined"
          onClick={() => sendDigits("w2")}
        >
          2
        </Button>
        <Button
          data-id="btn-call-3"
          variant="outlined"
          onClick={() => sendDigits("w3")}
        >
          3
        </Button>
        <Button
          data-id="btn-call-4"
          variant="outlined"
          onClick={() => sendDigits("w4")}
        >
          4
        </Button>
        <Button
          data-id="btn-call-5"
          variant="outlined"
          onClick={() => sendDigits("w5")}
        >
          5
        </Button>
        <Button
          data-id="btn-call-6"
          variant="outlined"
          onClick={() => sendDigits("w6")}
        >
          6
        </Button>
        <Button
          data-id="btn-call-7"
          variant="outlined"
          onClick={() => sendDigits("w7")}
        >
          7
        </Button>
        <Button
          data-id="btn-call-8"
          variant="outlined"
          onClick={() => sendDigits("w8")}
        >
          8
        </Button>
        <Button
          data-id="btn-call-9"
          variant="outlined"
          onClick={() => sendDigits("w9")}
        >
          9
        </Button>
        <Button
          data-id="btn-call-asterisk"
          variant="outlined"
          onClick={() => sendDigits("w*")}
        >
          *
        </Button>
        <Button
          data-id="btn-call-0"
          variant="outlined"
          onClick={() => sendDigits("w0")}
        >
          0
        </Button>
        <Button
          data-id="btn-call-pound"
          variant="outlined"
          onClick={() => sendDigits("w#")}
        >
          #
        </Button>
      </HabiKeyboardWrapper>
    </div>
  );

  return (
    <CallWrapper>
      <div className="phone-call">{telefono}</div>
      <div>
        <button
          disabled={disabledCallButton}
          onClick={() => callCustomer(telefono, user, nid)}
          className="btn btn-primary btn-sm call-customer-button button-call"
          type="button"
          data-id="btn-call"
        >
          Llamar
        </button>
        <button
          className="button-hangUp"
          onClick={() => hangUp()}
          type="button"
          data-id="btn-call-hang-up"
        >
          Colgar
        </button>
        <button
          className="button-hangUp"
          onClick={() => openIVR()}
          type="button"
          data-id="btn-call-ivr"
        >
          IVR
        </button>
      </div>
      <label className="input-label response-call">{callStatus}</label>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableScrollLock={true}
        data-id="modal-call"
      >
        {body}
      </Modal>
    </CallWrapper>
  );
};

const mapStateToProps = (state) => ({
  role: state.formData.role,
});

export default connect(mapStateToProps, null)(FormCall);
