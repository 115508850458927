import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { DatePickerWrapper } from "./style";

registerLocale("es", es);

const DatePickerField = ({ name, value, onChange, className, label }) => {
  return (
    <DatePickerWrapper>
      <div className={className}>
        <label>{label}</label>
        <DatePicker
          selected={(value && new Date(value)) || null}
          onChange={val => {
            onChange(name, val);
          }}
        />
      </div>
    </DatePickerWrapper>
  );
};

export default DatePickerField;
