import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MapContainer from "../Map/mapContainer";
import { ModalMapWrapper } from "./style";
import IconButton from "@material-ui/core/IconButton";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "auto",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "auto",
    height: "90vh",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
    borderRadius: "20px",
  },
}));

const ModalMapContainer = (props) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const [location, setLocation] = useState("");
  const [modalStyle] = React.useState(getModalStyle);

  const handleCloseModal = () => {};
  const handleCloseSnack = () => {};
  useEffect(() => {
    setOpenModal(props.open);
  }, [props.open]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ModalMapWrapper>
        <MapContainer
          coordinates={props.coordinates}
          location={location}
          handleCloseModal={handleCloseModal}
          setFieldValues={props.setFieldValues}
          setOpenModalMap={props.setOpenModalMap}
        />
      </ModalMapWrapper>
    </div>
  );

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableScrollLock={true}
      >
        {body}
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnack}
            data-id="btn-snackbar-map-close"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};

export default ModalMapContainer;
