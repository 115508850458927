import styled from "styled-components";
import { purple, archiaRegular } from "../../StylesConstants";

export const DropImagesAreaWrapper = styled.section`
  .drop-area-buttons {
    display: grid;
    grid-template: 1fr/ 1fr 1fr 1fr;
    grid-column-gap: 100px;
    margin-bottom: 20px;
  }
  .MuiButton-containedPrimary {
    background-color: ${purple};
    border-radius: 50px;
    outline: none;
    max-height: 60px;
    text-transform: none;
    padding: 18px 30px;
    :hover {
      background-color: ${purple};
    }
  }
  .MuiButton-label {
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 18px;
    line-height: 21px;
  }
`;
