import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import { MapWrapper, Title, Buttons, Map } from "./style";

import Button from "../sharedComponents/Button/button";

import MapMobile from "./mapMobile";
import { isMobile } from "react-device-detect";
import { setNotFoundAction } from "../../redux/actions/coordinadorLot.actions";

const MapContainer = (props) => {
  const { setNotFound } = props;
  const [latitudeMap, setLatitudeMap] = useState("4.635280");
  const [longitudeMap, setLongitudeMap] = useState("-74.082412");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (props.coordinates.length > 0) {
      const latitudeData = props.coordinates.filter((value) => {
        return value.name === "latitud";
      });
      const longitudeData = props.coordinates.filter((value) => {
        return value.name === "longitud";
      });
      setLatitudeMap(latitudeData[0].default);
      setLongitudeMap(longitudeData[0].default);
    }
  }, [props.coordinates]);

  const getAddressPin = (lat, lng) => {
    setLatitudeMap(lat);
    setLongitudeMap(lng);
    setIsValid(true);
  };
  return (
    <MapWrapper>
      <Title>
        <h1>
          No hemos podido obtener la localización, Por favor Confirma la
          ubicación del inmueble moviendo el pin.
        </h1>
      </Title>
      <Map>
        {latitudeMap ? (
          <Fragment>
            <MapMobile
              centerMap={{ lat: latitudeMap, lng: longitudeMap }}
              getAddressPin={getAddressPin}
              isMobile={isMobile}
            />
          </Fragment>
        ) : (
          ""
        )}
      </Map>

      <Buttons>
        <Button
          data-id="btn-next-map-container"
          typeClass={"primary"}
          type="button"
          label="Ubicación correcta"
          onClick={() => {
            setNotFound(true);
            props.setFieldValues("longitud", longitudeMap);
            props.setFieldValues("latitud", latitudeMap);
            props.setOpenModalMap(false);
          }}
        />
      </Buttons>
    </MapWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setNotFound: (notFound) => dispatch(setNotFoundAction(notFound)),
});

export default connect(null, mapDispatchToProps)(MapContainer);
