import React, { useState, useEffect } from "react";
import { StatusLabelWrapper } from "./style";

const StatusLabel = ({ status, label }) => {
  const [statusColor, setStatusColor] = useState();
  const [backColor, setBackColor] = useState();
  useEffect(() => {
    switch (status) {
      case "ok_conjunto":
        setStatusColor("#2DCA73");
        setBackColor("45, 202, 115, 0.2");
        break;
      case "incompleto":
        setStatusColor("#FFB200");
        setBackColor("254,247,229,1");
        break;
      case "no_contesta":
        setStatusColor("#FF0000");
        setBackColor("255, 0, 0, 0.1");
        break;
      default:
        break;
    }
  }, [status]);
  return (
    <StatusLabelWrapper color={statusColor} back={backColor}>
      <div className="status">
        <div className="circle"></div>
        <label>{label}</label>
      </div>
    </StatusLabelWrapper>
  );
};

export default StatusLabel;
