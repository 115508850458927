export const updateData = (payload) => ({
  type: "UPDATE_DATA",
  payload,
});

export const propertySelected = (payload) => ({
  type: "SELECT_PROPPERTY",
  payload,
});

export const setOpenAddress = (payload) => ({
  type: "SET_OPEN_ADDRESS",
  payload,
});
