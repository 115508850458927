import styled from "styled-components";
import { size, black } from "../../StylesConstants";
import BGHeader from "../../static/assets/img/BG-list.png";
import BGHeaderVentanero from "../../static/assets/img/BG-ventanero.png";

export const HeaderWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template: 1fr/1fr 1fr;
  width: 100%;
  height: 64px;
  background-color: #b2e7ff;
  color: #7c01ff;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;

  &.ventanero {
    background-image: url(${BGHeaderVentanero});
    background-size: cover;
  }

  &.callcenter {
    background-image: url(${BGHeader});
    background-size: cover;
  }

  .profile {
    display: grid;
    justify-self: end;
    grid-template-columns: 1fr 50px;
    p {
      color: ${black};
      margin-top: 7%;
    }
    img {
      border-radius: 50px;
      width: 35px;
      margin-left: 15%;
    }
  }
  .items-container {
    margin-left: 15px;

    .logo {
      display: inline-block;
      width: 40px;
      vertical-align: middle;
    }
    h1 {
      margin-left: 15px;
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    margin-bottom: 2rem;
    .items-container {
      .logo {
        cursor: pointer;
        width: 30px;
      }
      h1 {
        font-size: 2rem;
        cursor: pointer;
        margin: 0px 15px;
      }
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    .items-container {
      .logo {
        width: 30px;
      }
      h1 {
        color: ${black};
        font-size: 14px;
      }
    }
  }
`;
