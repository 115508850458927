import React, { useEffect, useState } from "react";
import { InputWrapper, RadioWrapper } from "./style";

const InputFeedback = ({ error }) =>
  error ? <div className="error">{error}</div> : null;

// Radio input
export const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  ...props
}) => {
  const [selected, setSelected] = useState(props.default);

  useEffect(() => {
    if (props.values[name] !== undefined) {
      setSelected(props.values[name]);
      if (
        props.values[name] !== null &&
        props.values[name].toString() == props.value.toString()
      ) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [props.values[name]]);

  return (
    <InputWrapper
      className={`${props.error && props.touched ? "error-radio" : ""} `}
    >
      <input
        name={name}
        id={`${id}${name}`}
        type="radio"
        value={props.value}
        checked={selected}
        onChange={() => props.setFieldValue(name, props.value)}
        onBlur={onBlur}
        {...props}
      />
      <label className="radio-name" htmlFor={`${id}${name}`}>
        {label}
      </label>
    </InputWrapper>
  );
};

// Radio group
export const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  children,
  ...props
}) => {
  useEffect(() => {
    if (value === undefined) {
      props.setFieldValue(props.default);
    }
  }, [value]);

  return (
    <RadioWrapper>
      <fieldset id={id}>
        <legend>{label}</legend>
        <div className="switch-toggle">{children}</div>
        {touched && <InputFeedback error={error} />}
      </fieldset>
    </RadioWrapper>
  );
};
