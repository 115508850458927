import styled /*, { keyframes }*/ from 'styled-components';
//import { slideInLeft, slideInRight } from 'react-animations';
import { size, dark_grey, archiaBoldFamily } from '../../StylesConstants';

/*const slideInLeftAnimation = keyframes`${slideInLeft}`;
const slideInRightAnimation = keyframes`${slideInRight}`;*/

export const MapWrapper = styled.section`
  display: grid;
  grid-template: 0.2fr 1.5fr 1fr/ 1fr;
  grid-template-areas:
    'title'
    'map'
    'buttons';
  height: 80vh;

  .mapaHabi {
    position: relative !important;
    width: 100% !important;
    height: 420px !important;
  }
  row-gap: 15px;
  @media (min-width: ${size.tablet}) {
    .mapaHabi {
      position: relative !important;
      width: 100% !important;
      height: 400px !important;
    }
  }
  @media (min-width: ${size.desktop}) {
    .mapaHabi {
      position: relative !important;
      width: 100% !important;
      height: 500px !important;
      margin: 0;
    }
  }
`;

export const Title = styled.div`
  grid-area: title;
  margin-top: 10px;
  height: auto;
  position: relative;
  h1 {
    font-family: ${archiaBoldFamily};
    font-size: 24px;
    line-height: 30px;
  }
  span {
    position: initial;
    left: 28%;
    bottom: 17%;
    font-size: 15px;
    font-family: archiaBold;
  }

  @media (min-width: ${size.tablet}) {
    grid-area: title;
    margin-top: 10px;
    height: auto;
    position: relative;
    h1 {
      font-family: ${archiaBoldFamily};
      font-size: 32px;
    }
    span {
      position: absolute;
      left: 28%;
      bottom: 17%;
      font-size: 15px;
      font-family: archiaBold;
    }
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    grid-area: title;
    margin-top: 10px;
    height: auto;
    position: relative;
    h1 {
      font-family: ${archiaBoldFamily};
      font-size: 32px;
    }
    span {
      position: absolute;
      left: 40%;
      bottom: 15%;
      font-size: 25px;
      font-family: ${archiaBoldFamily};
    }
  }
  @media (min-width: 1401px) {
    grid-area: title;
    margin-top: 10px;
    height: auto;
    position: relative;
    h1 {
      font-family: ${archiaBoldFamily};
      font-size: 32px;
    }
    span {
      position: absolute;
      left: 55%;
      bottom: 10%;
      font-size: 25px;
      font-family: ${archiaBoldFamily};
    }
  }
`;

export const Map = styled.div`
  grid-area: map;
  width: 100%;
  justify-self: center;
  .help-hint {
    color: ${dark_grey};
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 10px;
    img {
      width: 20px;
      margin-bottom: 4px;
    }
  }
  @media (min-width: ${size.tablet}) {
    .help-hint {
      color: ${dark_grey};
      margin-bottom: 0;
      margin-top: 10px;
      font-size: 12px;
      img {
        width: 25px;
        margin-bottom: 4px;
      }
    }
  }
  @media (min-width: ${size.desktop}) {
    .help-hint {
      color: ${dark_grey};
      margin-bottom: 0;
      margin-top: 10px;
      font-size: 14px;
      img {
        width: 25px;
        margin-bottom: 4px;
      }
    }
  }
`;

export const Buttons = styled.div`
  grid-area: buttons;
  display: grid;
  justify-self: right;
  row-gap: 10px;
  @media (min-width: ${size.tablet}) {
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    column-gap: 30px;
  }
`;
