import { createStore, combineReducers } from "redux";
import formData from "./reducers/formData";
import resetSelect from "./reducers/resetSelect";
import ventanerosImages from "./reducers/ventanerosImages.reducer";
import notFoundReducer from "./reducers/coordinadorLot.reducer";
import infoProperties from "./reducers/infoProperties";
import { ROLE } from "../components/const/constants";

const reducers = combineReducers({
  formData,
  resetSelect,
  ventanerosImages,
  notFoundReducer,
  infoProperties,
});

const initialState = {
  formData: {
    email: "",
    role: ROLE.CALLCENTER, //callcenter o ventanero
    name: "",
    lastName: "",
    authenticated: false,
    flagBarrio: "",
    imageProfile: "",
  },
};

const store = createStore(reducers, initialState);

export default store;
