import styled from "styled-components";
import { grey, size } from "../../../StylesConstants";

export const DatePickerWrapper = styled.div`
  margin: 0;
  .react-datepicker__input-container input {
    border: 2px solid ${grey} !important;
    box-sizing: border-box !important;
    border-radius: 100px !important;
    width: 100% !important;
    padding: 15px 32px !important;
    height: 56px !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  @media screen and (min-width: ${size.tablet}) {
    margin: 0 15px;
  }
`;
