import styled from "styled-components";
import { size, purple, black, white, error } from "../../../StylesConstants";

export const InputWrapper = styled.div`
  min-height: 40px;
`;

export const RadioWrapper = styled.div`
  legend {
    font-size: 16px;
  }

  .radio-container {
    float: left;
    width: 90px;
    max-width: 90px;
    height: 40px;
  }

  .radio-container-fuente {
    float: left;
    width: 160px;
    max-width: 160px;
    height: 40px;
  }

  .not-allowed input + label {
    cursor: not-allowed !important;
  }

  .radio-container-col-one {
    float: left;
    width: 100px;
    max-width: 100px;
    height: 40px;
  }

  .radio-container:first-child {
    border-right: 1px solid #d3d2d4;

    input:checked + label {
      border-left: 2px solid ${purple};
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .error-radio {
      input:checked + label {
        border-left: 2px solid ${error};
      }
    }
  }

  .radio-container:last-child {
    border-left: 1px solid #d3d2d4;
    input:checked + label {
      border-right: 2px solid ${purple};
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .error-radio {
      input:checked + label {
        border-right: 2px solid ${error};
      }
    }
  }

  .switch-toggle {
    float: left;
    background: #fcfcfc;
    border: 1px solid #d3d2d4;
    border-radius: 8px;
  }

  .radio-container input {
    position: absolute;
    opacity: 0;
  }

  .radio-container input + label {
    float: left;
    cursor: pointer;
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 16px;
    color: ${black};
    padding-top: 8px;
  }

  .error {
    display: flex;
    width: 100%;
    padding-top: 15px;
  }

  .radio-container {
    input:checked + label {
      background: ${purple};
      color: ${white};
      font-weight: bold;
    }

    .error-radio {
      input:checked + label {
        background: ${error};
      }
    }
  }
`;
