import React from "react";
import ButtonWrapper from "./style";
import decorator from "../../../static/assets/icons/vector.svg";

const CollapsibleButton = (props) => {
  return (
    <ButtonWrapper id={`${props.id}-container`} className={props.className}>
      <h2>
        <button
          className={`btn-group ${props.collapsed ? "collapsed" : ""}`}
          type="button"
          data-toggle="collapse"
          data-target={`#collapse-${props.id}`}
          aria-expanded={props.id === 0 ? "true" : "false"}
          aria-controls={`collapse-${props.id}`}
          data-id={`collapse-btn-${props.label}`}
        >
          <div className="btn-container">
            <div className="icon-arrow"></div>
            <span>{props.label}</span>
            <img src={decorator} alt="" className="img"></img>
          </div>
        </button>
      </h2>
    </ButtonWrapper>
  );
};

export default CollapsibleButton;
