import React, { useEffect, useState } from "react";
import {
  FormWrapper,
  MainWrapper,
  FieldsContainer,
  FieldsGroup,
  FormStreetNotification,
} from "./style";
import { withRouter } from "react-router";
import Button from "../sharedComponents/Button/button";
import Checkbox from "../sharedComponents/Checkbox/checkbox";
import TextInput from "../sharedComponents/TextInput/textInput";
import SelectComponent from "../sharedComponents/Select/select";
import DatePickerForm from "../sharedComponents/DatePicker/datePicker";
import { createYupSchema } from "../sharedComponents/FormValidation/yupSchemaCreator";
import CurrencyInput from "../sharedComponents/CurrencyInput/currencyInput";
import ReactNotification from "react-notifications-component";
import { createValidationData } from "./validationForms";
import { Tabs, Tab } from "react-bootstrap";
import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";
import CollapsibleButton from "../sharedComponents/CollapsibleButton/collapsibleButton";
import FormCall from "./call";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  RadioButton,
  RadioButtonGroup,
} from "../sharedComponents/RadioButton/radioButton";
import { ROLE } from "../const/constants";
import AddressInput from "../sharedComponents/AddressInput/addressInput";
import ModalMapContainer from "../ModalMapContainer/modalMapContainer";
import DropImagesAreaContainer from "../DropImagesArea/dropImagesAreaContainer";
import { removeNotFoundAction } from "../../redux/actions/coordinadorLot.actions";
import { connect, useSelector } from "react-redux";

const FormStreet = (props) => {
  const [images, setImages] = useState([]);
  const [fields, setFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [role, setRole] = useState([]);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [disabledCancelButton, setDisabledCancelButton] = useState(false);
  const [user, setUser] = useState([]);
  const [stateOldId, setStateOldId] = useState(null);
  const [title, setTitle] = useState("");
  const [nid, setNid] = useState();
  const [validAddresss, setValidAddresss] = useState(true);
  const [coordinates, setCoordinates] = useState([]);
  const [stopRedirect, setStopRedirect] = useState(true);
  const { isOpenAddress } = useSelector((state) => state.infoProperties);
  const { propertySelected } = useSelector((state) => state.infoProperties);

  const [apiConjuntoData, setApiConjuntoData] = useState({
    showData: false,
  });

  useEffect(() => {
    return () => {
      props.removeNotFound();
    };
  }, [props.removeNotFound]);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  useEffect(() => {
    setImages(props.images);
  }, [props.images]);

  useEffect(() => {
    setRole(props.role);
  }, [props.role]);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  useEffect(() => {
    setNid(props.nid);
  }, [props.nid]);

  useEffect(() => {
    setStateOldId(props.estadoold_id);
  }, [props.estadoold_id]);

  useEffect(() => {
    const createInitialValues = () => {
      let fieldsTabs = [];
      props.fields.forEach((tab) => {
        tab.groups.forEach((group, index) => {
          fieldsTabs = [...fieldsTabs, ...group.fields];
        });
      });

      if (propertySelected?.estado === "desistio") {
        let dataUpdate = [];
        dataUpdate = fieldsTabs.filter(
          (object) =>
            object.name == "nombre_o_inmobiliaria" ||
            object.name == "tipo_inmueble_id"
        );
        if (dataUpdate[0] && dataUpdate[1]) {
          dataUpdate[0].requerido = 0;
          dataUpdate[1].requerido = 0;
        }
      }

      const schemaValidation = createValidationData(fieldsTabs);

      if (fieldsTabs.length > 0) {
        if (role === ROLE.COORDINADOR) {
          const coordinatesData = fieldsTabs.filter((value) => {
            return value.name === "latitud" || value.name === "longitud";
          });
          setCoordinates(coordinatesData);
        }
        setInitialValues(schemaValidation.initialValues);
        setValidationSchema(
          schemaValidation.validationYupJson.validationArrayJson.reduce(
            createYupSchema,
            {}
          )
        );
      }
    };
    setFields(props.fields);
    createInitialValues();
  }, [props.fields]);

  useEffect(() => {
    if (role === ROLE.COORDINADOR) {
      const createInitialValues = () => {
        let fieldsTabs = [];
        props.fields.forEach((tab) => {
          tab.groups.forEach((group, index) => {
            fieldsTabs = [...fieldsTabs, ...group.fields];
          });
        });

        const schemaValidation = createValidationData(fieldsTabs);
        if (fieldsTabs.length > 0) {
          if (role === ROLE.COORDINADOR) {
            const coordinatesData = fieldsTabs.filter((value) => {
              return value.name === "latitud" || value.name === "longitud";
            });
            setCoordinates(coordinatesData);
          }
          setInitialValues(schemaValidation.initialValues);
          setValidationSchema(
            schemaValidation.validationYupJson.validationArrayJson.reduce(
              createYupSchema,
              {}
            )
          );
        }
      };
      setFields(props.fields);
      createInitialValues();
    }
  }, [JSON.stringify(fields)]);

  const goDashboard = () => {
    props.history.push("/dashboard");
  };

  const fixAddress = () => {
    setApiConjuntoData({ showData: false });
  };

  const checkErrorsAddressForm = (isValid) => {
    setValidAddresss(isValid);
  };

  /** Se construyen los campos automaticamente **/
  const getFields = (
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    fieldsTab,
    values
  ) => {
    if (fieldsTab) {
      return fieldsTab.map((field) => {
        let enable_on_field_name = field.enable_on_field;
        let arrayValueField = [""];
        if (field.value_enable_on_field) {
          arrayValueField = field.value_enable_on_field.split(",");
        }

        return arrayValueField.map((arrayField) => {
          if (values[enable_on_field_name] === true) {
            values[enable_on_field_name] = "1";
          } else if (values[enable_on_field_name] === false) {
            values[enable_on_field_name] = "0";
          } else if (
            values[enable_on_field_name] === "undefined" ||
            !values[enable_on_field_name]
          ) {
            values[enable_on_field_name] = "";
          }
          if (values[enable_on_field_name] === arrayField) {
            if (
              field.type === "input" ||
              field.type === "inputNumber" ||
              field.type === "inputEmail"
            ) {
              if (values.tipo_inmueble_id === 2 && field.name === "land_area") {
                return (
                  <TextInput
                    key={field.name}
                    name={field.name}
                    type={
                      field.type === "inputNumber"
                        ? "number"
                        : field.type === "inputEmail"
                        ? "email"
                        : "text"
                    }
                    errors={errors}
                    touched={touched}
                    disabled={field.disabled}
                    id={`text-input-${field.name}`}
                    label={field.label}
                    placeholder={field.label}
                    required={field.requerido}
                  />
                );
              } else if (field.name !== "land_area") {
                // Role callcenter
                if (
                  role === "callcenter" &&
                  field.name === "ano_remodelacion" &&
                  field.disabled
                ) {
                  return false;
                }
                if (role === ROLE.ADMIN && field.name === "ano_remodelacion") {
                  return false;
                }

                return (
                  <TextInput
                    key={field.name}
                    name={field.name}
                    type={
                      field.type === "inputNumber"
                        ? "number"
                        : field.type === "inputEmail"
                        ? "email"
                        : "text"
                    }
                    errors={errors}
                    touched={touched}
                    disabled={field.disabled}
                    id={`text-input-${field.name}`}
                    label={field.label}
                    placeholder={field.label}
                    required={field.requerido}
                  />
                );
              }
            } else if (field.type === "inputAddress") {
              return (
                <AddressInput
                  name={field.name}
                  label={field.label}
                  setFieldValue={setFieldValue}
                  apiConjuntoData={apiConjuntoData}
                  field={field}
                  fixAddress={fixAddress}
                  hideButtons={true}
                  validAddresss={checkErrorsAddressForm}
                  maxLengthInput={field?.max_lenght}
                  estado={values.estado}
                />
              );
            } else if (field.type === "inputCurrency") {
              return (
                <div className="form-group" key={field.name}>
                  <Field
                    id="currency-form-street"
                    name={field.name}
                    label={field.label}
                    default={field.default}
                    maxLength={field.max_lenght}
                    component={CurrencyInput}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    required={field.requerido}
                  />
                </div>
              );
            } else if (field.type === "select") {
              let selected = { value: "", label: "Seleccione" };
              for (let index = 0; index < field.options.length; index++) {
                const element = field.select_values[index];
                if (element === `${field.default}`) {
                  selected = {
                    value: element,
                    label: field.options[index],
                  };
                  break;
                }
              }

              // Role callcenter
              if (
                role === "callcenter" &&
                field.name === "tipo_remodelacion" &&
                field.disabled
              ) {
                return false;
              }
              if (role === ROLE.ADMIN && field.name === "tipo_remodelacion") {
                return false;
              }

              return (
                <div className="form-group" key={field.name}>
                  <Field
                    default={selected}
                    options={field.options.map((option, index) => {
                      return {
                        label: option,
                        value: field.select_values[index],
                      };
                    })}
                    component={SelectComponent}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    name={field.name}
                    id={`${field.name}-form-street`}
                    label={field.label}
                    required={field.requerido}
                    disabled={field.disabled}
                  />
                </div>
              );
            } else if (field.type === "date") {
              return (
                <DatePickerForm
                  data-id="date-picker-form-street"
                  name={field.name}
                  label={field.label}
                  value={field.default}
                />
              );
            } else if (field.type === "radioGroup") {
              // Role callcenter
              if (
                role === "callcenter" &&
                field.name === "remodelado" &&
                field.disabled
              ) {
                return false;
              }
              if (role === ROLE.ADMIN && field.name === "remodelado") {
                return false;
              }

              return (
                <div className="form-group" key={field.name}>
                  <RadioButtonGroup
                    id={field.name}
                    label={field.label}
                    value={values[field.name]}
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    setFieldValue={setFieldValue}
                    default={field.default}
                  >
                    {field.options.map((option, index) => (
                      <div
                        key={`${index}${field.name}`}
                        className={`radio-container${
                          field.columns === 1 ? " radio-container-col-one" : ""
                        } ${field.name === "fuente_id" ? "radio-container-fuente not-allowed" : ""}`}
                      >
                        <Field
                          component={RadioButton}
                          name={field.name}
                          id={`toggle-${field.name}-${option}`}
                          label={option}
                          value={
                            field.select_values[index] === "None"
                              ? ""
                              : field.select_values[index]
                          }
                          default={field.default}
                          values={values}
                          error={errors[field.name]}
                          touched={touched[field.name]}
                          setFieldValue={setFieldValue}
                          disabled={role === ROLE.CALLCENTER && field.name === "fuente_id"}
                        />
                      </div>
                    ))}
                  </RadioButtonGroup>
                </div>
              );
            } else {
              return (
                <div
                  className="form-group checkbox-form-group"
                  key={field.name}
                >
                  <Field
                    type="checkbox"
                    component={Checkbox}
                    name={field.name}
                    id={`checkbox-${field.name}`}
                    label={field.label}
                  />
                </div>
              );
            }
          }
        });
      });
    }
  };

  const getValidateAndSubmitCallback = (validateForm, values, handleSubmit) => {
    values = {
      ...values,
      open_address_input: isOpenAddress ? 1 : 0,
    };

    if (
      values["estado"] === "completo" ||
      values["estado"] === "suficiente" ||
      values["estado"] === "direccion_revisada"
    ) {
      validateForm().then((errors) => {
        if (Object.keys(errors).length > 0 || !validAddresss) {
          let notification = {
            title: "No se han llenado todos los campos!",
            message:
              "Por favor completelos e intente de nuevo. La Dirección es obligatoria cuando el estado del cliente es 'Completo'",
            type: "danger",
            insert: "top",
            container: "top-left",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
            },
          };

          store.addNotification({
            ...notification,
            container: "top-left",
          });
        } else {
          handleSubmit(values, stopRedirect);
        }
      });
    } else {
      validateForm().then((errors) => {
        if (
          values["estado"] === "desistio" ||
          values["estado" === "no_contesta"]
        ) {
          errors["nombre_o_inmobiliaria"] = "";
          errors["tipo_inmueble_id"] = "";
        }

        let errors_different_than_required = false;
        let keys = Object.keys(errors);

        for (let index = 0; index < keys.length; index++) {
          const key_error = keys[index];
          if (
            errors[key_error] === "Campo requerido" &&
            values["estado"] !== "no_contesta" &&
            values["estado"] !== "incompleto"
          ) {
            errors_different_than_required = true;
            break;
          }
        }

        if (!errors_different_than_required && validAddresss) {
          handleSubmit(values, stopRedirect);
        } else {
          let notification = {
            title: "Campos con información erronea!",
            message: "Por favor corrijalos e intente de nuevo",
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
            },
          };

          store.addNotification({
            ...notification,
            container: "top-center",
          });
        }
      });
    }
  };

  const getStatusPossibleDuplicate = (props) => {
    if (role === ROLE.CALLCENTER && stateOldId === 44) {
      return (
        <div className="form-group buttonContainer">
          <Button
            id="sendValidate"
            typeClass="button-validate"
            type="submit"
            className="w-100 button-send"
            label="Validar"
            onClick={(e) => {
              props.setFieldValue("validateButton", true);
              props.handleSubmit(e);
            }}
          />
        </div>
      );
    }
  };

  const updateSaveDisabled = (status) => {
    setDisabledSaveButton(status);
    setDisabledCancelButton(status);
  };

  return (
    <FormWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
      >
        {(propsFormik) => (
          <Form>
            <div className="top-container">
              <h1 className="main-title">{title}</h1>
              <div className="buttons-save">
                <div id="cancel" className="form-group buttonContainer">
                  <Button
                    typeClass="secondary"
                    className="w-100 button-send"
                    label="Cancelar"
                    disabled={disabledCancelButton}
                    onClick={goDashboard}
                  />
                </div>
                <div className="form-group buttonContainer">
                  <Button
                    typeClass="primary"
                    type="submit"
                    className="w-100 button-send"
                    label="Guardar"
                    disabled={disabledSaveButton}
                    onClick={() => {
                      propsFormik.setFieldValue("validateButton", false);
                      getValidateAndSubmitCallback(
                        propsFormik.validateForm,
                        propsFormik.values,
                        props.handleSubmit
                      );
                      setStopRedirect(
                        ROLE.COORDINADOR && !props.notFound ? true : false
                      );
                    }}
                  />
                </div>
                {getStatusPossibleDuplicate(propsFormik)}
              </div>
            </div>
            {fields[0] && (
              <MainWrapper>
                <div>
                  <Tabs
                    defaultActiveKey={fields[0].name}
                    data-id={`tabs-${fields[0].name}`}
                  >
                    {fields.map((tab, i) => (
                      <Tab eventKey={tab.name} key={i} title={tab.label}>
                        {tab.groups.map((group, iGroup) => (
                          <FieldsGroup key={iGroup}>
                            <CollapsibleButton
                              id={iGroup}
                              label={group.label}
                            />
                            <div
                              id={`collapse-${iGroup}`}
                              className={`collapse ${
                                iGroup === 0 ? "show" : ""
                              }`}
                            >
                              <FieldsContainer
                                className={`column-${group.col}`}
                              >
                                {getFields(
                                  propsFormik.errors,
                                  propsFormik.touched,
                                  propsFormik.setFieldValue,
                                  propsFormik.setFieldTouched,
                                  group.fields,
                                  propsFormik.values
                                )}
                              </FieldsContainer>
                            </div>
                          </FieldsGroup>
                        ))}
                      </Tab>
                    ))}
                    {[
                      ROLE.CALLCENTER,
                      ROLE.ADMIN,
                      ROLE.VENTANERO,
                      ROLE.COORDINADOR,
                    ].includes(role) && (
                      <Tab eventKey="photo" title="Fotos">
                        <DropImagesAreaContainer images={images} />
                      </Tab>
                    )}
                  </Tabs>
                </div>
                {(role === ROLE.CALLCENTER || role === ROLE.ROTACION) && (
                  <FormCall
                    telefono={propsFormik.values.telefono}
                    user={user}
                    updateButtonSave={updateSaveDisabled}
                    nid={nid}
                  />
                )}
              </MainWrapper>
            )}
            <ModalMapContainer
              open={props.openModalMap}
              coordinates={coordinates}
              setFieldValues={propsFormik.setFieldValue}
              setOpenModalMap={props.setOpenModalMap}
            />
          </Form>
        )}
      </Formik>
      <FormStreetNotification>
        <ReactNotification />
      </FormStreetNotification>
    </FormWrapper>
  );
};

const mapStateToProps = (state) => ({
  notFound: state.notFoundReducer,
});

const mapDispatchToProps = (dispatch) => ({
  removeNotFound: () => dispatch(removeNotFoundAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormStreet));
