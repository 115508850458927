import styled from "styled-components";
import {
  purple,
  white,
  light_purple,
  error,
  black,
  size
} from "../../../StylesConstants";

export const CheckboxWrapper = styled.div`
  margin: 0;

  .input-feedback {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: ${error};
    padding-left: 32px;
  }

  @media screen and (min-width: ${size.tablet}) {
    margin: 0 15px;
  }
`;

export const CheckboxContainer = styled.div`
  /* The container */
  .container {
    height: 56px;
    display: block;
    position: relative;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-width: none;
    border: 2px solid #7c01ff;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 12px 32px 15px 32px;

    &.errorCheckbox {
      background-color: ${white};
      border: 2px solid ${error};
    }

    &.checked {
      background: rgba(170, 167, 252, 0.2);
    }
  }
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    right: 25px;
    height: 25px;
    width: 25px;
    top: 14px;
    border: 2px solid #e3e4e6;
    border-radius: 50%;
    background-color: ${white};
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: ${light_purple};
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${purple};
    border: 2px solid ${purple};
    border-radius: 50%;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .label-name {
    color: ${purple};
    font-size: 16px;
    line-height: 19px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .label-name {
    font-size: 16px;
    line-height: 19px;
    color: ${black};

    &.error-label {
      color: ${error};
    }
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
