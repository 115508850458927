import React, { useEffect, useState } from "react";
import ListApartments from "./listApartments";
import axios from "axios";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { connect } from "react-redux";
import { ROLE } from "../const/constants";
import { useDispatch } from "react-redux";
import { updateData } from "../../redux/actions/infoProperties";
import { getApartmentsRoleRotation } from "./api/listApartments";

const ListApartmentsContainer = (props) => {
  var apikeyOLD = process.env.REACT_APP_FORMS_API_KEY;
  var rootUrlOLD =
    process.env.REACT_APP_FORM_API_URL_V2 +
    process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH;
  var apikey = process.env.REACT_APP_FORMS_API_KEY_V2;
  var rootUrl = process.env.REACT_APP_FORM_API_URL_V3;

  const endpointUrlListAparments =
    process.env.REACT_APP_ENDPOINT_FORM_API_GET_LIST_APARTMENTS;
  const endpointUrlListTurnover =
    process.env.REACT_APP_ENDPOINT_FORM_API_GET_LIST_TURNOVER;
  const urlGetListAparments = rootUrl + endpointUrlListAparments;
  const urlGetListTurnover = rootUrl + endpointUrlListTurnover;
  const urlGetListCoordinator =
    rootUrlOLD + process.env.REACT_APP_ENDPOINT_FORM_API_GET_LIST_COORDINATOR;
  const INITIAL_PAGE = 1;
  const ITEMS_BY_PAGE = 10;
  const dispatch = useDispatch();

  //States
  const [listApartments, setListApartments] = useState([]);
  const [paginator, setPaginator] = useState({
    currentPage: INITIAL_PAGE,
    max_pages: 0,
  });
  const [pageData, setPageData] = useState(INITIAL_PAGE);

  const [filters, setFilters] = useState({
    address: "",
    date: "",
    user: "",
    phone: "",
    status: "",
    source_id: "",
    nid: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const hasFilter = (filters) =>
    Object.values(filters).some((val) => val === null || val !== "");
  const fetchData = async () => {
    const body = {
      role: props.role,
      userId: props.userId,
      pagination: {
        page: pageData,
        items: ITEMS_BY_PAGE,
      },
      filters: filters,
      country: "CO",
    };

    await axios({
      url:
        props.role === ROLE.ROTACION
          ? urlGetListTurnover
          : props.role === ROLE.ADMIN && hasFilter(filters)
          ? urlGetListAparments
          : props.role !== ROLE.ADMIN &&
            props.role !== ROLE.COORDINADOR &&
            props.role !== ROLE.COORDINADOR_COS
          ? urlGetListAparments
          : props.role === ROLE.COORDINADOR ||
            props.role === ROLE.COORDINADOR_COS
          ? urlGetListCoordinator
          : "",
      method: "post",
      headers: {
        "x-api-key":
          props.role === ROLE.COORDINADOR || props.role === ROLE.COORDINADOR_COS
            ? apikeyOLD
            : apikey,
        "Content-Type": "application/json",
      },
      data: body,
    })
      .then((response) => {
        dispatch(updateData(response.data));
        setListApartments(response.data.data);
        setPaginator({
          currentPage: pageData,
          itemsCountPerPage: ITEMS_BY_PAGE,
          totalItemsCount: response.data.total,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(function() {
        window.scrollTo(0, 0);
        setIsLoading(false);
      });
  };

  const getApartmentsRotation = async () => {
    await getApartmentsRoleRotation({
      role: props.role,
      userId: props.userId,
      pagination: {
        page: pageData,
        items: ITEMS_BY_PAGE,
      },
      filters: filters,
      country: "CO",
    })
      .then((response) => {
        dispatch(updateData(response.data));
        setListApartments(response.data.data);
        setPaginator({
          currentPage: response.data.pagination.page_num,
          itemsCountPerPage: response.data.pagination.page_size,
          totalItemsCount: response.data.pagination.total_length,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(function() {
        window.scrollTo(0, 0);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    const getListApartmentsData = () => {
      setIsLoading(true);
      if (props.role === ROLE.ROTACION) {
        getApartmentsRotation();
      } else {
        fetchData();
      }
    };
    getListApartmentsData();
    return () => {
      setIsLoading(false);
    };
  }, [filters, pageData]);

  const handlePageChange = (newPage) => {
    setPageData(newPage);
  };

  const handleFilters = (filtersParam) => {
    setPageData(INITIAL_PAGE);
    setFilters(filtersParam);
  };

  return (
    <BlockUi tag="div" blocking={isLoading}>
      <ListApartments
        handleFilters={handleFilters}
        listApartments={listApartments}
        handlePageChange={handlePageChange}
        paginator={paginator}
        role={props.role}
      />
    </BlockUi>
  );
};

const mapStateToProps = (state) => ({
  role: state.formData.role,
  userId: state.formData.userId,
});

export default connect(mapStateToProps)(ListApartmentsContainer);
