import React, { Fragment, useState } from "react";
// enhacer
import { useDispatch } from "react-redux";
// tools enhacer
import {
  propertySelected,
  setOpenAddress,
} from "../../redux/actions/infoProperties";
import Paginator from "../sharedComponents/Paginator/paginator";
import { ListApartmentsWrapper, Title } from "./style";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Add } from "@material-ui/icons/";
import Filters from "../Filters/filters";
import { withRouter } from "react-router";
import Button from "../sharedComponents/Button/button";
import { ROLE } from "../const/constants";
import Header from "../Header/header";
import HeaderSmall from "../HeaderSmall/header";
import StatusLabel from "../sharedComponents/StatusLabel/statusLabel";
import { isMobile } from "react-device-detect";
import AddIcon from "@material-ui/icons/Add";
import ModalZones from "../ModalZones/modalZones";

const ListApartments = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handlePageChange = (newPage) => {
    props.handlePageChange(newPage);
  };

  const handleFilters = (filters) => {
    props.handleFilters(filters);
  };

  const goToForm = () => {
    props.history.push({
      pathname: "/forms",
      state: {
        update: false,
      },
    });
  };

  const goToFormLote = () => {
    props.history.push({
      pathname: "/forms-coordinador-lote",
      state: {
        update: false,
        num_formulario: 1,
      },
    });
  };

  const upateApartment = (apartment) => {
    dispatch(propertySelected(apartment));
    dispatch(setOpenAddress(Boolean(apartment?.open_address_input)));
    props.history.push({
      pathname: "/forms",
      state: {
        update: true,
        apartment,
      },
    });
  };

  return (
    <Fragment>
      {!isMobile && <Header />}
      {isMobile && <HeaderSmall />}
      {isMobile && (
        <Title>
          <h2 className="title">
            Listado de apartamentos ({props.paginator.totalItemsCount})
          </h2>
          {props.role === ROLE.VENTANERO && isMobile && (
            <button
              data-id="btn-add-vetanero-mobile"
              type="button"
              onClick={goToForm}
              className="button-mobile"
            >
              <span>+</span>
            </button>
          )}
        </Title>
      )}
      <Filters handleSearch={handleFilters} role={props.role} />
      <ListApartmentsWrapper>
        <div className="container-habi-form">
          {!isMobile && (
            <h2 className="title">
              Número de Propiedades ({props.paginator.totalItemsCount})
            </h2>
          )}
          <div className="new-button">
            {props.role === ROLE.VENTANERO && !isMobile && (
              <Button
                data-id="btn-add-vetanero"
                typeClass="primary"
                type="button"
                onClick={goToForm}
                className="d-inline-block"
                label="Crear nuevo"
              >
                <Add />
              </Button>
            )}
            {props.role === ROLE.COORDINADOR && !isMobile && (
              <>
                <Button
                  data-id="btn-add-coordinator-mobile"
                  typeClass="primary"
                  type="button"
                  onClick={goToFormLote}
                  className="d-inline-block"
                  label="Crear lote"
                >
                  <Add />
                </Button>
                <Button
                  data-id="btn-zone-coordinator-mobile"
                  typeClass="primary"
                  type="button"
                  onClick={() => setOpen(true)}
                  className="d-inline-block"
                  label="Zonas"
                />
              </>
            )}
          </div>
          {props.role !== ROLE.ROTACION && (
            <div className="table-container">
              <Table className="table">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Fecha de creación</Th>
                    <Th>Dirección</Th>
                    <Th>Teléfono</Th>
                    <Th className="d-md-none d-xl-block">Inmobiliaria</Th>
                    <Th>Estado</Th>
                    {(props.role === ROLE.COORDINADOR ||
                      props.role === ROLE.ADMIN) && <Th> Fuente </Th>}
                    {props.role !== ROLE.VENTANERO && (
                      <Th className="text-center">Editar</Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {props.listApartments &&
                    props.listApartments.map((apartment) => (
                      <Tr key={apartment.id}>
                        <Th>{apartment.nid}</Th>
                        <Td>{apartment.fecha_creacion}</Td>
                        <Td>{apartment.direccion}</Td>
                        <Td>{apartment.telefono}</Td>
                        <Td className="d-md-none d-xl-block">
                          {apartment.nombre_o_inmobiliaria}
                        </Td>
                        <Td>
                          <StatusLabel
                            status={apartment.estado}
                            label={apartment.estado_tag}
                          ></StatusLabel>
                        </Td>
                        {(props.role === ROLE.COORDINADOR ||
                          props.role === ROLE.ADMIN) && (
                          <Td>{apartment.fuente}</Td>
                        )}
                        {props.role !== ROLE.VENTANERO && (
                          <Td className="update">
                            <button
                              data-id={`btn-${props.role}-edit`}
                              onClick={() => upateApartment(apartment)}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M23.1794 2.26558L21.6896 0.776229C20.6544 -0.258743 18.8617 -0.258743 17.8264 0.776229L15.7812 2.82093H4.39348C1.96949 2.82093 0 4.7899 0 7.21325V19.6077C0 22.031 1.96949 24 4.39348 24H16.7912C19.2152 24 21.1846 22.031 21.1846 19.6077V8.14725L23.2299 6.10255C24.2651 5.06758 24.2651 3.3258 23.1794 2.26558ZM19.3414 19.5824C19.3414 20.9961 18.1799 22.1572 16.7659 22.1572H4.39348C2.97948 22.1572 1.81799 20.9961 1.81799 19.5824V7.18801C1.81799 5.77439 2.97948 4.6132 4.39348 4.6132H13.9884L8.18096 10.4444C7.47396 11.1512 6.91846 12.0347 6.61547 12.994L5.50447 16.3008C5.30247 16.9319 5.45397 17.5882 5.90847 18.0678C6.23672 18.396 6.66597 18.5727 7.12046 18.5727C7.29721 18.5727 7.49921 18.5475 7.67596 18.4717L10.9837 17.361C11.9432 17.0329 12.8269 16.5028 13.5339 15.7959L19.3666 9.96476V19.5824H19.3414ZM9.34245 11.8832L12.0947 14.6348C11.6149 15.0891 11.0342 15.4425 10.4029 15.6697L7.29721 16.7047L8.33246 13.5998C8.53446 12.9435 8.88795 12.3629 9.34245 11.8832ZM21.8916 4.84039L13.3824 13.3474L10.6049 10.5706L19.1141 2.06363C19.2909 1.88693 19.5181 1.78596 19.7454 1.78596C19.9979 1.78596 20.2251 1.88693 20.3766 2.06363L21.8664 3.55298C22.2704 3.90639 22.2704 4.48698 21.8916 4.84039Z"
                                  fill="#B8B8B8"
                                />
                              </svg>
                            </button>
                          </Td>
                        )}
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </div>
          )}
          {props.role === ROLE.ROTACION && (
            <div className="table-container">
              <Table className="table">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Fecha de actualización</Th>
                    <Th>Días desde ultimo contacto</Th>
                    <Th className="text-center">Editar</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {props.listApartments &&
                    props.listApartments.map((apartment) => (
                      <Tr key={apartment.id}>
                        <Th>{apartment.nid}</Th>
                        <Td>{apartment.fecha_contacto}</Td>
                        <Td>
                          <span
                            className={`${
                              apartment.days_last_contact <= 15
                                ? "pill-green"
                                : "pill-red"
                            }`}
                          >
                            {apartment.days_last_contact}
                          </span>
                        </Td>
                        <Td className="update">
                          <button
                            data-id="edit-list-appartments"
                            onClick={() => upateApartment(apartment)}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M23.1794 2.26558L21.6896 0.776229C20.6544 -0.258743 18.8617 -0.258743 17.8264 0.776229L15.7812 2.82093H4.39348C1.96949 2.82093 0 4.7899 0 7.21325V19.6077C0 22.031 1.96949 24 4.39348 24H16.7912C19.2152 24 21.1846 22.031 21.1846 19.6077V8.14725L23.2299 6.10255C24.2651 5.06758 24.2651 3.3258 23.1794 2.26558ZM19.3414 19.5824C19.3414 20.9961 18.1799 22.1572 16.7659 22.1572H4.39348C2.97948 22.1572 1.81799 20.9961 1.81799 19.5824V7.18801C1.81799 5.77439 2.97948 4.6132 4.39348 4.6132H13.9884L8.18096 10.4444C7.47396 11.1512 6.91846 12.0347 6.61547 12.994L5.50447 16.3008C5.30247 16.9319 5.45397 17.5882 5.90847 18.0678C6.23672 18.396 6.66597 18.5727 7.12046 18.5727C7.29721 18.5727 7.49921 18.5475 7.67596 18.4717L10.9837 17.361C11.9432 17.0329 12.8269 16.5028 13.5339 15.7959L19.3666 9.96476V19.5824H19.3414ZM9.34245 11.8832L12.0947 14.6348C11.6149 15.0891 11.0342 15.4425 10.4029 15.6697L7.29721 16.7047L8.33246 13.5998C8.53446 12.9435 8.88795 12.3629 9.34245 11.8832ZM21.8916 4.84039L13.3824 13.3474L10.6049 10.5706L19.1141 2.06363C19.2909 1.88693 19.5181 1.78596 19.7454 1.78596C19.9979 1.78596 20.2251 1.88693 20.3766 2.06363L21.8664 3.55298C22.2704 3.90639 22.2704 4.48698 21.8916 4.84039Z"
                                fill="#B8B8B8"
                              />
                            </svg>
                          </button>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </div>
          )}

          {props.paginator.totalItemsCount >
            props.paginator.itemsCountPerPage && (
            <div className="paginator-container">
              <Paginator
                className="paginator"
                handlePageChange={handlePageChange}
                paginator={props.paginator}
              />
            </div>
          )}
        </div>
      </ListApartmentsWrapper>
      {open && (
        <ModalZones open={open} handleModalClose={() => setOpen(false)} />
      )}
    </Fragment>
  );
};

export default withRouter(ListApartments);
