/**
 *
 * @param {string} x carrera 19a # 103A - 62
 * @returns {string}
 */
export const getdirformat = (x) => {
  let result = "";
  x = x.toLowerCase();
  const regex = /[^0-9a-zA-Z]/gi;
  x = x
    .replace(regex, " ")
    .split(" ")
    .filter((item) => item);
  for (let u = 0; u < x.length; u++) {
    let i = x[u];
    i = i
      .replace(" ", "")
      .trim()
      .toLowerCase();
    const isFloat = parseFloat(i.replace(/[^0-9]/gi, " "));
    if (isFloat) {
      result = result + "+" + i;
    } else {
      if (i !== "") {
        result = result + i;
      }
    }
    try {
      if (result.replace(/[^+]/gi, "").length >= 4) {
        try {
          if ("sur" in x[u + 1]) {
            result = result + "sur";
          }

          if ("este" in x[u + 1]) {
            result = result + "este";
          }
        } catch (e) {}
        break;
      }
    } catch (e) {}
  }
  return result;
};

/**
 *
 * @param {string} x 19a # 103A - 62
 * @returns {string}
 */
export const getnewdir = (x) => {
  x = getdirformat(x)
    .split("+")
    .slice(1);
  return x.join("+");
};

/**
 *
 * @param {string} x 19a # 103A - 62
 * @returns {string} example return if diagonal = D
 */

export const prefijo = (x) => {
  let result = null;
  const m = x.search(/\d/gi);
  if (m !== null) {
    x = x.slice(0, m).trim();
  }
  const prefijos = {
    D: ["diagonal", "dg", "diag", "dg.", "diag."],
    T: [
      "transv",
      "tranv",
      "tv",
      "tr",
      "tv.",
      "tr.",
      "tranv.",
      "transv.",
      "transversal",
      "tranversal",
      "trasversal",
    ],
    C: [
      "calle",
      "cl",
      "cll",
      "cl.",
      "cll.",
      "ac",
      "a calle",
      "av calle",
      "av cll",
      "a cll",
      "avenida calle",
      "ac.",
      "cálle",
      "calla",
      "calle.",
      "cale",
    ],
    K: [
      "carrera",
      "cr",
      "cra",
      "cr.",
      "cra.",
      "av. cra.",
      "kr",
      "kr.",
      "kra.",
      "kra",
      "ak",
      "ak.",
      "av cr",
      "av carrera",
      "av cra",
      "carrara",
      "cerrera",
      "crr",
      "crr.",
      "av crr",
      "carr",
      "avenida carrera",
    ],
    A: ["avenida", "av.", "av."],
    CI: ["circular", "ci", "cq", "Circular"],
  };
  for (let key in prefijos) {
    if (prefijos[key].includes(x.toLowerCase())) {
      result = key;
      break;
    }
  }
  return result;
};

/**
 *
 * @param {string} x
 * @returns {string}
 */

export const coddir = (x) => {
  const strprefijo = prefijo(x);
  const strgetnewdir = getnewdir(x);
  if (strprefijo === null || strgetnewdir === null) {
    return null;
  } else {
    return strprefijo + "+" + strgetnewdir;
  }
};

/**
 * setup coddir for open openaddres
 * @param {string} address addres from form in address input component
 * @returns {string} return addres with format coddir
 */
export const coddirOpenAddress = (address) =>
  address
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s/, "+")
    .replace(/[!"@#\$\^&%*()=\/|:<>?,.\-_\]\} \[\{ ]/gi, "");

/**
 *
 * @param {string} tipoVia tipe of way
 */

export const getTipoVia = (tipoVia) => {
  const formatedtipoVia = {
    D: "Diagonal",
    T: "Transversal",
    C: "Calle",
    K: "Carrera",
    A: "Avenida",
    CI: "Circular",
  };
  return formatedtipoVia[tipoVia];
};
