import {notFoundAction} from "../actions/coordinadorLot.actions";

const INITIAL_STATE = false;

const notFoundReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case notFoundAction.SET_NOT_FOUND:
      return payload;
    case notFoundAction.REMOVE_NOT_FOUND:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default notFoundReducer;
