import React, { useState } from "react";
import { connect } from "react-redux";
import BlockUi from "react-block-ui";
import axios from "axios";
import { ROLE } from "../const/constants";
import DeleteIcon from "@material-ui/icons/Delete";
import { isMobile } from "react-device-detect";
import {
  addImageAction,
  removeImageAction,
} from "../../redux/actions/ventanerosImages.actions";

import Button from "../sharedComponents/Button/button";
import PhotoPicker from "../sharedComponents/PhotoPicker/photoPicker";

import { ImagesWrapper } from "./style";

const ImagesApartment = (props) => {
  const { images, removeImage } = props;

  const [showPhoto, setShowPhoto] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);

  const newImgHandle = (state) => {
    if (state.isNext) {
      props.addImage({
        img: state.imagePreviewUrl,
        type: "new",
        extension: state.selectedFile.type,
        description: state.description,
      });
    }
    setShowPhoto(false);
  };

  const currentImageDelete = (imageObj, index) => {
    setSelectedImage({ imageObj, index });
    setIsDelete(!isDelete);
  };

  const deleteImages = () => {
    const deleteImageService = async () => {
      const apikey = process.env.REACT_APP_FORMS_API_KEY;
      const rootUrl =
        process.env.REACT_APP_FORM_API_URL_V2 +
        process.env.REACT_APP_ENDPOINT_FORMS_API_BASE_PATH;
      const endpointUrl =
        process.env.REACT_APP_ENDPOINT_FORM_API_DELETE_PROPERTY_IMAGES;
      const url = rootUrl + endpointUrl;
      const body = { id: selectedImage.imageObj.id };
      await axios({
        url: url,
        method: "post",
        headers: {
          "x-api-key": apikey,
          "Content-Type": "application/json",
        },
        data: body,
      })
        .then((response) => {
          removeImage(selectedImage.index);
        })
        .catch((error) => {
          console.log("error image");
        })
        .finally(function() {
          setIsLoading(false);
        });
    };

    if (selectedImage.imageObj.type === "new") {
      removeImage(selectedImage.index);
    } else {
      setIsLoading(true);
      deleteImageService();
    }

    setIsDelete(false);
  };

  return (
    <ImagesWrapper>
      {showPhoto && <PhotoPicker newImgHandle={newImgHandle} />}
      {!showPhoto && (
        <div className="button-container">
          <BlockUi tag="div" blocking={isLoading}>
            {isMobile && props.role !== ROLE.COORDINADOR && !isDelete && (
              <Button
                data-id="btn-next-new-photo"
                typeClass="primary"
                type="button"
                onClick={() => setShowPhoto(true)}
                label="Nueva foto"
              />
            )}
            {props.role !== ROLE.COORDINADOR && isDelete && (
              <Button
                data-id="btn-next-delete-photo"
                typeClass="primary"
                className="btn-delete"
                type="button"
                onClick={deleteImages}
                label="Borrar foto"
              >
                <DeleteIcon class="icon-trash" />
              </Button>
            )}
            <div className="gallery-img">
              {!isMobile && props.role !== ROLE.COORDINADOR && !isDelete && (
                <button
                  data-id="btn-next-add-photos"
                  className="btn-add-square"
                  type="button"
                  onClick={() => setShowPhoto(true)}
                >
                  Agregar fotos
                </button>
              )}

              {images &&
                images.map((image, i) => (
                  <div
                    data-id="img-apartment-container"
                    key={i}
                    className={`img-container ${
                      !isDelete
                        ? ""
                        : selectedImage.index !== i
                        ? "image-not-delete"
                        : "image-delete"
                    }`}
                    onClick={() => {
                      currentImageDelete(image, i);
                    }}
                  >
                    <img alt={i} src={image.img} />
                    <span className="description">{image.description}</span>
                  </div>
                ))}
            </div>
          </BlockUi>
        </div>
      )}
    </ImagesWrapper>
  );
};

const mapStateToProps = (state) => ({
  imagesRedux: state.ventanerosImages,
});

const mapDispatchToProps = (dispatch) => ({
  addImage: (image) => dispatch(addImageAction(image)),
  removeImage: (image) => dispatch(removeImageAction(image)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagesApartment);
