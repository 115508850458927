import React, { useState, useEffect } from "react";
import { InputWrap } from "./style";
import NumberFormat from "react-number-format";
import { ErrorMessage } from "formik";

const CurrencyInput = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  form,
  label,
  className,
  setFieldValue,
  setFieldTouched,
  ...props
}) => {
  const [state, setState] = useState({
    value: props.default ? props.default : "",
    isValid: true,
  });

  useEffect(() => {
    const isValidField = () => {
      return errors[name] && touched[name];
    };
    setState({ ...state, isValid: isValidField() });
  }, [state.value, touched]);

  return (
    <InputWrap className={className}>
      <div className={`form-group ${state.isValid ? "errorInput" : ""}`}>
        <label>
          {label} {props.required ? "*" : ""}
        </label>
        <NumberFormat
          id={`currency-input-${name}`}
          thousandSeparator={true}
          prefix={"$"}
          value={state.value}
          maxLength={props.maxLength}
          className="form-control"
          placeholder="$000.000"
          onValueChange={(vals) => {
            setState({ value: vals.formattedValue });
            form.setFieldTouched(name, true);
            if (vals.formattedValue === "") {
              form.setFieldValue(name, "");
            } else {
              form.setFieldValue(name, vals.floatValue);
            }
          }}
        />
        {state.isValid && <div className="input-feedback">{errors[name]}</div>}
        <ErrorMessage
          name={name}
          component="div"
          className="invalid-feedback"
        />
      </div>
    </InputWrap>
  );
};

export default CurrencyInput;
