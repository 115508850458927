import React from "react";
import { withRouter } from "react-router";
import {
  Button as ButtonModal,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const ModalResponse = (props) => {
  const { className } = props;

  const goDashboard = () => {
    props.history.push("/dashboard");
  };

  return (
    <Modal isOpen={props.isOpen} className={className} backdrop={true}>
      <ModalHeader charCode="">Posible Duplicado</ModalHeader>
      <ModalBody>El registro fue eliminado por ser un duplicado.</ModalBody>
      <ModalFooter>
        <ButtonModal
          data-id="btn-modal-response-accept"
          color="primary"
          onClick={goDashboard}
        >
          Aceptar
        </ButtonModal>
      </ModalFooter>
    </Modal>
  );
};

export default withRouter(ModalResponse);
